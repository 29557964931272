import { makeAutoObservable, runInAction } from "mobx";

import messagesEnglish from "../assets/lang/en.json";

type Language = {
  id: string;
  text: string;
};

const availableLanguages: Language[] = [
  {
    id: "en",
    text: "English"
  }
];

const detectBrowserLanguage = () => {
  if (typeof window !== undefined) {
    return {
      id: "en",
      text: "English"
    };
  }
  if (!navigator.languages || navigator.languages.length === 0) {
    return availableLanguages[0];
  }

  const userPreference = navigator.languages.find((x) =>
    availableLanguages.find((y) => x.includes(y.id.toString()))
  );

  if (!userPreference) {
    return availableLanguages[0];
  }

  return (
    availableLanguages.find((x) => userPreference.includes(x.id.toString())) ??
    availableLanguages[0]
  );
};

const langKey = "venomize.language";
const browserLanguage = detectBrowserLanguage();

const setLanguageInStore = (language: Language) => {
  localStorage.setItem(langKey, language.id);
};

const getLanguageFromStore = () => {
  const lang = localStorage.getItem(langKey);

  if (!lang) {
    setLanguageInStore(browserLanguage);

    return browserLanguage;
  }

  return availableLanguages.find((x) => x.id === lang) ?? availableLanguages[0];
};

export class LanguageStore {
  constructor() {
    makeAutoObservable(this);
  }

  initialized = false;
  language: Language = getLanguageFromStore() ?? {
    id: "en",
    text: "English"
  };

  init() {
    const languageFromStore = getLanguageFromStore();
    this.setLanguage(
      languageFromStore ?? {
        id: "en",
        text: "English"
      }
    );

    runInAction(() => {
      this.initialized = true;
    });
  }

  setLanguage(language: Language) {
    if (this.language.id === language.id) {
      return;
    }

    runInAction(() => {
      this.language = language;
    });

    setLanguageInStore(language);
  }

  get availableLanguages() {
    return availableLanguages;
  }

  get messages() {
    switch (this.language.id) {
      case "en":
        return messagesEnglish;
      default:
        return messagesEnglish;
    }
  }
}
