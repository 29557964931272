export const TokenRootDeployCallbacks = {
  "ABI version": 2,
  version: "2.2",
  header: ["pubkey", "time", "expire"],
  functions: [
    {
      name: "onTokenRootDeployed",
      inputs: [
        { name: "callId", type: "uint32" },
        { name: "token_root", type: "address" }
      ],
      outputs: []
    }
  ],
  data: [],
  events: []
} as const;
