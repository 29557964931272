import SidebarAccountInfo from "@/components/sidebar/account-info";
import { useLayout } from "@/providers/BaseStoresProvider";
import { useCallback } from "react";

export const useAccountInfo = () => {
  const layout = useLayout();

  const showSidebar = useCallback(() => {
    layout.toggleSidebar(true, <SidebarAccountInfo />);
  }, [layout]);

  return {
    showAccountInfo: showSidebar
  };
};
