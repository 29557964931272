import React, { useState } from "react";

import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import ROUTES from "@/routes";
import Container from "@/components/core/container";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import Button from "@/components/core/button";
import { SpinnerDots } from "@/components/common/spinner";
import InputField from "@/components/core/input-field";
import { useTokenStore } from "@/providers/BaseStoresProvider";
import { validateMarketing as validate } from "@/utils/validate";

import { ReactComponent as System } from "@/assets/icons/marketing/system.svg";
import { ReactComponent as PR } from "@/assets/icons/marketing/pr.svg";
import { ReactComponent as Lead } from "@/assets/icons/marketing/lead.svg";
import { ReactComponent as Influencer } from "@/assets/icons/marketing/influencer.svg";
import { ReactComponent as Cycle } from "@/assets/icons/marketing/cycle.svg";
import { ReactComponent as Banner } from "@/assets/icons/marketing/banner.svg";

interface IProps {}

const createVenomTemplate = (
  params: Record<string, Record<string, string>>
) => {
  const arr: string[] = [];

  Object.entries(params).forEach(([key, val]) => {
    arr.push(
      `<div style="font-size: 18px">
        <span style="color: #c1c1c1; margin-right: 30px;">${val.name}: </span>
        <span style="font-weight: 600; font-size: 16px; color: #ffffff;">${val.value}</span>
      </div>`
    );
  });

  return `
    <h1 style="color: #fff; font-size: 20px; margin-bottom: 30px;">
      New application from ${params.toEmail.value}
    </h1>
    ${arr.join("<br />")}
    <h1 style="color: #fff; font-size: 24px; margin-top: 20px; text-align: center;">© 2023 ${
      process.env.REACT_APP_ORIGIN
    }</h1>
  `;
};

const MarketingPage: React.FC<IProps> = () => {
  const [enableValidation, setEnableValidation] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<number | undefined>(
    undefined
  );
  const intl = useIntl();
  const tokenStore = useTokenStore();

  const advOptions = [
    {
      title: intl.formatMessage({
        id: "adv.options.1",
        defaultMessage: "Ecosystem overview"
      }),
      icon: <System className="mr-4" />,
      description: intl.formatMessage({
        id: "adv.options.1",
        defaultMessage: "Get a project review on Venom main media channels"
      })
    },
    {
      title: intl.formatMessage({
        id: "adv.options.2",
        defaultMessage: "Lead generation in socials"
      }),
      icon: <Lead className="mr-4" />,
      description: intl.formatMessage({
        id: "adv.options.2",
        defaultMessage: "Get a project review on Venom main media channels"
      })
    },
    {
      title: intl.formatMessage({
        id: "adv.options.3",
        defaultMessage: "Influencer marketing"
      }),
      icon: <Influencer className="mr-4" />,
      description: intl.formatMessage({
        id: "adv.options.3",
        defaultMessage: "Get a project review on Venom main media channels"
      })
    },
    {
      title: intl.formatMessage({
        id: "adv.options.4",
        defaultMessage: "Banner ads"
      }),
      icon: <Banner className="mr-4" />,
      description: intl.formatMessage({
        id: "adv.options.4",
        defaultMessage: "Get a project review on Venom main media channels"
      })
    },
    {
      title: intl.formatMessage({
        id: "adv.options.5",
        defaultMessage: "PR support"
      }),
      icon: <PR className="mr-4" />,
      description: intl.formatMessage({
        id: "adv.options.5",
        defaultMessage: "Get a project review on Venom main media channels"
      })
    },
    {
      title: intl.formatMessage({
        id: "adv.options.6",
        defaultMessage: "Full-cycle marketing campaign"
      }),
      icon: <Cycle className="mr-4" />,
      description: intl.formatMessage({
        id: "adv.options.6",
        defaultMessage: "Get a project review on Venom main media channels"
      })
    }
  ];
  const formik = useFormik({
    initialValues: {
      toEmail: {
        value: "",
        name: "Email"
      },
      advOption: {
        value: "",
        name: "Advertisment option"
      },
      website: {
        value: "",
        name: "Website"
      },
      adFormat: {
        value: "",
        name: "Ad format"
      },
      cGoal: {
        value: "",
        name: "Campaign period"
      },
      cPeriod: {
        value: "",
        name: "Campaign goal"
      },
      tAudience: {
        value: "",
        name: "Target audience"
      },
      tRegions: {
        value: "",
        name: "Target regions"
      },
      cBudget: {
        value: "",
        name: "Campaign budget"
      },
      benchmarks: {
        value: "",
        name: "Benchmarks"
      },
      adContent: {
        value: "",
        name: "Benchmarks"
      },
      references: {
        value: "",
        name: "References"
      },
      additMaterials: {
        value: "",
        name: "Additional materials"
      },
      otherComm: {
        value: "",
        name: "Other commentaries"
      }
    },
    validateOnBlur: enableValidation,
    validateOnChange: enableValidation,
    validate,
    onSubmit: async (values: any) => {
      setLoading(true);
      setEnableValidation(true);
      const template = await (
        await fetch("/marketing_templates/email_for_venom.html")
      ).text();
      const generateTemplate = createVenomTemplate(values);
      const htmlData = template?.replace("__content", generateTemplate);
      await tokenStore.postMarketingForm(
        {
          toEmail: formik.values.toEmail.value
        },
        htmlData
      );

      setLoading(false);
    }
  });

  const handleCardClick = (index: number) => {
    // todo: short it
    if (index === activeOption) {
      setActiveOption(undefined);
      formik.setFieldValue("advOption.value", "");
      return;
    }
    setActiveOption(index);
    formik.setFieldValue("advOption.value", index + 1);
  };

  return (
    <Container className="md:w-8/12 pr-4 mt-2 pl-1">
      <div className="breadcrumbs">
        <Link
          className="breadcrumbs__link breadcrumbs__link--active"
          to={ROUTES.marketing.path}
        >
          {intl.formatMessage({
            id: "breadcrumbs.marketing",
            defaultMessage: "Marketing"
          })}
        </Link>
      </div>
      <div className="md:w-10/12">
        <div className={styles.header}>
          <span className="font-poppins font-semibold text-3xl text-white">
            {intl.formatMessage({
              id: "marketing.title",
              defaultMessage: "Token advertising"
            })}
          </span>
        </div>
        <h3 className="font-poppins font-medium text-xl text-white mt-2 mb-6">
          {intl.formatMessage({
            id: "marketing.optionsText",
            defaultMessage:
              "We offer several advertisement options available to you with our team that ran thousands of ads in all media all over the world"
          })}
        </h3>
        <div className={styles.cards}>
          {advOptions.map((_, index) => (
            <div
              className={classNames(
                styles.cardItem,
                activeOption === index ? styles.cardCheckboxActive : null
              )}
              key={index}
              onClick={() => handleCardClick(index)}
            >
              {_.icon}
              <div className={styles.cardsText}>
                <span>{_.title}</span>
                <span>{_.description}</span>
              </div>
            </div>
          ))}
          <span className="text-sm text-danger-600 mt-4">
            {formik.errors.advOption?.value ?? null}
          </span>
        </div>
        <h3 className="font-poppins font-medium text-xl text-white mt-8 mb-6">
          {intl.formatMessage({
            id: "marketing.fillForm",
            defaultMessage: "Fill the form and we will contact you"
          })}
        </h3>

        <form className="pb-20" onSubmit={formik.handleSubmit}>
          <div className="w-full flex flex-wrap justify-between mb-5">
            <div className="w-full xs:w-[48%] mb-2 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.toEmail",
                  defaultMessage: "Your email"
                })}
              </h3>
              <InputField
                id="toEmail.value"
                name="toEmail.value"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.toEmail.value}
                placeholder={intl.formatMessage({
                  id: "form.toEmail",
                  defaultMessage: "Your email address"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.toEmail?.value ?? null}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between mb-5">
            <div className="w-full xs:w-[48%] mb-2 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.website",
                  defaultMessage: "Website"
                })}
              </h3>
              <InputField
                id="website.value"
                name="website.value"
                regEx="[^a-zA-Z0-9_:.?#@!$&*+,;=_~%-/][]"
                onChange={formik.handleChange}
                value={formik.values.website.value}
                placeholder={intl.formatMessage({
                  id: "form.website",
                  defaultMessage: "Your website address"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.website?.value ?? null}
              </span>
            </div>
            <div className="w-full xs:w-[48%] mb-5 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.adFormat",
                  defaultMessage: "Token AD format"
                })}
              </h3>
              <InputField
                id="adFormat.value"
                name="adFormat.value"
                onChange={formik.handleChange}
                value={formik.values.adFormat.value}
                placeholder={intl.formatMessage({
                  id: "input.adFormat",
                  defaultMessage: "AD format"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.adFormat?.value ?? null}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between mb-5">
            <div className="w-full xs:w-[48%] mb-2 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.cPeriod",
                  defaultMessage: "Campaign period"
                })}
              </h3>
              <InputField
                id="cPeriod.value"
                name="cPeriod.value"
                onChange={formik.handleChange}
                value={formik.values.cPeriod.value}
                placeholder={intl.formatMessage({
                  id: "input.cPeriod",
                  defaultMessage: "Campaign period"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.cPeriod?.value ?? null}
              </span>
            </div>
            <div className="w-full xs:w-[48%] mb-5 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.cGoal",
                  defaultMessage: "Campaign goal"
                })}
              </h3>
              <InputField
                id="cGoal.value"
                name="cGoal.value"
                onChange={formik.handleChange}
                value={formik.values.cGoal.value}
                placeholder={intl.formatMessage({
                  id: "input.cGoal",
                  defaultMessage: "Campaign goal"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.cGoal?.value ?? null}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between mb-5">
            <div className="w-full xs:w-[48%] mb-2 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.tAudience",
                  defaultMessage: "Target audience"
                })}
              </h3>
              <InputField
                id="tAudience.value"
                name="tAudience.value"
                onChange={formik.handleChange}
                value={formik.values.tAudience.value}
                placeholder={intl.formatMessage({
                  id: "input.tAudience",
                  defaultMessage: "Target audience"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.tAudience?.value ?? null}
              </span>
            </div>
            <div className="w-full xs:w-[48%] mb-5 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.tRegions",
                  defaultMessage: "Target regions"
                })}
              </h3>
              <InputField
                id="tRegions.value"
                name="tRegions.value"
                onChange={formik.handleChange}
                value={formik.values.tRegions.value}
                placeholder={intl.formatMessage({
                  id: "input.tRegions",
                  defaultMessage: "Target regions"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.tRegions?.value ?? null}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between mb-5">
            <div className="w-full xs:w-[48%] mb-2 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.cBudget",
                  defaultMessage: "Campaign budget"
                })}
              </h3>
              <InputField
                id="cBudget.value"
                name="cBudget.value"
                onChange={formik.handleChange}
                value={formik.values.cBudget.value}
                placeholder={intl.formatMessage({
                  id: "input.cBudget",
                  defaultMessage: "Campaign budget"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.cBudget?.value ?? null}
              </span>
            </div>
            <div className="w-full xs:w-[48%] mb-5 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.benchmarks",
                  defaultMessage: "Benchmarks, if any"
                })}
              </h3>
              <InputField
                id="benchmarks.value"
                name="benchmarks.value"
                onChange={formik.handleChange}
                value={formik.values.benchmarks.value}
                placeholder={intl.formatMessage({
                  id: "input.benchmarks",
                  defaultMessage: "Benchmarks, if any"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.benchmarks?.value ?? null}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between mb-5">
            <div className="w-full xs:w-[48%] mb-2 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.adContent",
                  defaultMessage: "Ad content requirements"
                })}
              </h3>
              <InputField
                id="adContent.value"
                name="adContent.value"
                onChange={formik.handleChange}
                value={formik.values.adContent.value}
                placeholder={intl.formatMessage({
                  id: "input.adContent",
                  defaultMessage: "Ad content requirements"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.adContent?.value ?? null}
              </span>
            </div>
            <div className="w-full xs:w-[48%] mb-5 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.references",
                  defaultMessage: "References"
                })}
              </h3>
              <InputField
                id="references"
                name="references"
                onChange={formik.handleChange}
                value={formik.values.references.value}
                placeholder={intl.formatMessage({
                  id: "input.references",
                  defaultMessage: "references"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.references?.value ?? null}
              </span>
            </div>
          </div>
          <div className="w-full flex flex-wrap justify-between mb-5">
            <div className="w-full xs:w-[48%] mb-2 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.additMaterials",
                  defaultMessage: "Additional materials"
                })}
              </h3>
              <InputField
                id="additMaterials"
                name="additMaterials"
                onChange={formik.handleChange}
                value={formik.values.additMaterials.value}
                placeholder={intl.formatMessage({
                  id: "input.additMaterials",
                  defaultMessage: "Additional materials"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.additMaterials?.value ?? null}
              </span>
            </div>
            <div className="w-full xs:w-[48%] mb-5 xs:mb-0">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.otherComm",
                  defaultMessage: "Other commentaries"
                })}
              </h3>
              <InputField
                id="otherComm.value"
                name="otherComm.value"
                onChange={formik.handleChange}
                value={formik.values.otherComm.value}
                placeholder={intl.formatMessage({
                  id: "input.otherComm",
                  defaultMessage: "Other commentaries"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.otherComm?.value ?? null}
              </span>
            </div>
          </div>
          {isLoading ? (
            <SpinnerDots className={classNames(styles.spinner, "ml-auto")} />
          ) : (
            <Button
              variant="secondary"
              type="submit"
              disabledError
              className="w-6/12 ml-auto mt-10 max-w-[200px]"
            >
              {intl.formatMessage({
                id: "btn.submitForm",
                defaultMessage: "Submit form"
              })}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default observer(MarketingPage);
