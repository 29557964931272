import "./styles/index.css";
import { App } from "./App";
import ReactDOM from "react-dom/client";
import "rc-notification/assets/index.css";
import { BrowserRouter } from "react-router-dom";

export const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
ReactDOM.hydrateRoot(document.getElementById("root") as HTMLElement, <App />);
