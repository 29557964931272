export const Drop3AirdropAbi = {
  "ABI version": 2,
  version: "2.2",
  header: ["time", "expire"],
  functions: [
    {
      name: "constructor",
      inputs: [
        { name: "_refund_destination", type: "address" },
        { name: "_refund_lock_duration", type: "uint256" },
        { name: "_owner", type: "address" },
        { name: "_token_root_address", type: "address" },
        { name: "_recipients", type: "address[]" },
        { name: "_amounts", type: "uint128[]" }
      ],
      outputs: []
    },
    {
      name: "onWallet",
      inputs: [{ name: "wallet", type: "address" }],
      outputs: []
    },
    {
      name: "distributeBatch",
      inputs: [{ name: "cursor", type: "uint256" }],
      outputs: []
    },
    {
      name: "distribute",
      inputs: [],
      outputs: []
    },
    {
      name: "refund",
      inputs: [],
      outputs: []
    },
    {
      name: "onAcceptTokensTransfer",
      inputs: [
        { name: "value0", type: "address" },
        { name: "amount", type: "uint128" },
        { name: "sender", type: "address" },
        { name: "value3", type: "address" },
        { name: "value4", type: "address" },
        { name: "value5", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "upgrade",
      inputs: [{ name: "code", type: "cell" }],
      outputs: []
    },
    {
      name: "transferOwnership",
      inputs: [{ name: "newOwner", type: "address" }],
      outputs: []
    },
    {
      name: "renounceOwnership",
      inputs: [],
      outputs: []
    },
    {
      name: "owner",
      inputs: [],
      outputs: [{ name: "owner", type: "address" }]
    },
    {
      name: "name",
      inputs: [],
      outputs: [{ name: "name", type: "string" }]
    },
    {
      name: "refund_lock_duration_end",
      inputs: [],
      outputs: [{ name: "refund_lock_duration_end", type: "uint256" }]
    },
    {
      name: "totalAmount",
      inputs: [],
      outputs: [{ name: "totalAmount", type: "uint128" }]
    },
    {
      name: "creationDate",
      inputs: [],
      outputs: [{ name: "creationDate", type: "uint256" }]
    },
    {
      name: "tokenRootAddress",
      inputs: [],
      outputs: [{ name: "tokenRootAddress", type: "address" }]
    },
    {
      name: "deposit",
      inputs: [],
      outputs: [{ name: "deposit", type: "uint128" }]
    },
    {
      name: "addresses",
      inputs: [],
      outputs: [{ name: "addresses", type: "address[]" }]
    },
    {
      name: "amounts",
      inputs: [],
      outputs: [{ name: "amounts", type: "uint128[]" }]
    }
  ],
  data: [
    { key: 1, name: "nonce", type: "uint128" },
    { key: 2, name: "factory", type: "address" },
    { key: 3, name: "version", type: "uint16" },
    { key: 4, name: "name", type: "string" }
  ],
  events: [
    {
      name: "OwnershipTransferred",
      inputs: [
        { name: "previousOwner", type: "address" },
        { name: "newOwner", type: "address" }
      ],
      outputs: []
    }
  ],
  fields: [
    { name: "_pubkey", type: "uint256" },
    { name: "_timestamp", type: "uint64" },
    { name: "_constructorFlag", type: "bool" },
    { name: "owner", type: "address" },
    { name: "nonce", type: "uint128" },
    { name: "factory", type: "address" },
    { name: "version", type: "uint16" },
    { name: "name", type: "string" },
    { name: "refund_destination", type: "address" },
    { name: "refund_lock_duration_end", type: "uint256" },
    { name: "totalAmount", type: "uint128" },
    { name: "creationDate", type: "uint256" },
    { name: "tokenRootAddress", type: "address" },
    { name: "deposit", type: "uint128" },
    { name: "walletAddress", type: "address" },
    { name: "addresses", type: "address[]" },
    { name: "amounts", type: "uint128[]" }
  ]
} as const;
