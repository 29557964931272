import classNames from "classnames";
import React, { useState, useRef } from "react";
import styles from "./index.module.css";
import { useIntl } from "react-intl";
import { IoCloudUploadSharp } from "react-icons/io5";

type TDropZone = {
  className?: string;
  onChange: (csvData: any) => void;
};

const Dropzone = ({ className, onChange }: TDropZone) => {
  const [highlight, setHighlight] = useState(false);
  const [hideNotify, toggleNotify] = useState<boolean>(false);
  const [csvFile, setCsvFile] = useState<any>(null);
  const intl = useIntl();
  const fileInputRef = useRef<any>(null);

  const readFileData = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const csv = reader.result;
      onChange(csv);

      // todo: more proper way
      setTimeout(() => {
        toggleNotify(true);
      }, 1500);

      setTimeout(() => {
        setCsvFile(null);
        toggleNotify(false);
        if (fileInputRef) fileInputRef.current.value = null;
      }, 2000);
    };
    reader.readAsText(file);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setHighlight(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setHighlight(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setHighlight(false);
    const file = e.dataTransfer.files[0];
    if (file.type === "text/csv") {
      setCsvFile(file);
      readFileData(file);
    } else {
      alert("Please drop a CSV file.");
    }
  };

  const handleSelectFile = (e: any) => {
    const file = e.target.files[0];
    if (file.type === "text/csv") {
      setCsvFile(file);
      readFileData(file);
    } else {
      alert("Please select a CSV file.");
    }
  };

  return (
    <div
      className={classNames(
        styles.dropzone,
        className,
        highlight ? styles.highlight : ""
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => fileInputRef?.current?.click()}
    >
      <IoCloudUploadSharp className={styles.upload} />
      <p className="mt-14 mx-auto text-center">
        {intl.formatMessage({
          id: "upload",
          defaultMessage:
            "Drag and drop a CSV file here, or click to select a file."
        })}
      </p>
      <input
        type="file"
        accept=".csv"
        onChange={handleSelectFile}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <p
        className={classNames(
          styles.notify,
          !hideNotify && csvFile?.name ? styles.showNotify : styles.hideNotify
        )}
      >
        {intl.formatMessage({
          id: "upload.success",
          defaultMessage: "You've uploaded"
        })}{" "}
        <strong>{csvFile?.name}</strong>.
      </p>
    </div>
  );
};

export default Dropzone;
