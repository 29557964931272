import styles from "./index.module.css";
import { observer } from "mobx-react-lite";
import { useWallet, useLayout } from "@/providers/BaseStoresProvider";
import { bigNumberToStr } from "@/utils/bignumber";
import { cutString } from "@/utils/strings";
import { MdLogout } from "react-icons/md";
import { useIntl } from "react-intl";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { HiOutlineUser } from "react-icons/hi";
import CopyButton from "@/components/common/copy-button";

const SidebarAccountInfo = observer(() => {
  const intl = useIntl();
  const wallet = useWallet();
  const layout = useLayout();

  const logout = useCallback(async () => {
    await wallet.disconnect();
    layout.toggleSidebar(false);
  }, [wallet, layout]);

  const onLinkClick = useCallback(() => {
    layout.toggleSidebar(false);
  }, [layout]);

  const menuLinks = useMemo(
    () => [
      {
        url: ``,
        text: intl.formatMessage({
          id: "account_info.profile",
          defaultMessage: "Profile"
        }),
        icon: <HiOutlineUser className={styles.link_icon} />
      }
    ],
    [intl]
  );

  const userAddress = useMemo(
    () => wallet.account?.address.toString(),
    [wallet.account?.address]
  );

  if (!wallet.account || !userAddress) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.account}>
        <div className={styles.account_logo} />

        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <span className="font-bold text-secondary-400">
              {cutString(userAddress, 6, 4)}
            </span>

            <CopyButton text={userAddress} />
          </div>

          <span className="text-secondary-400">
            {bigNumberToStr(wallet.coinBalance, 1)} {wallet.coinSymbol}
          </span>
        </div>
      </div>

      <div className={styles.menu}>
        {menuLinks.map((x) => (
          <Link
            key={x.url}
            to={x.url}
            className={styles.link}
            onClick={onLinkClick}
          >
            {x.icon}
            <span>{x.text}</span>
          </Link>
        ))}
        <button className={styles.logout} onClick={logout}>
          <MdLogout className="text-2xl" />
          <span>
            {intl.formatMessage({
              id: "navbar.logout",
              defaultMessage: "Logout"
            })}
          </span>
        </button>
      </div>
    </div>
  );
});

export default SidebarAccountInfo;
