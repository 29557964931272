import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { TokenModel } from "@/models/TokenModel";

const tokenManifestSources = [
  "https://raw.githubusercontent.com/broxus/ton-assets/master/manifest.json"
];

export class StaticDataStore {
  constructor() {
    makeAutoObservable(this);
  }

  private tokenMap = new Map<string, TokenModel>();
  private defaultToken?: TokenModel;

  async init() {
    try {
      await this.fetchTokenData();
    } catch (err) {
      console.error(err);
    }
  }

  get urls() {
    return {
      wallet: "https://everwallet.net"
      //wallet: 'https://venom.foundation/wallet',
    };
  }

  getToken(address: string): TokenModel | undefined {
    const token = this.tokenMap.get(address);
    return token ? new TokenModel({ ...token }) : undefined;
  }

  getTokens(): TokenModel[] {
    return Array.from(this.tokenMap.values()).map(
      (x) => new TokenModel({ ...x })
    );
  }

  getDefaultToken(): TokenModel | undefined {
    return this.defaultToken
      ? new TokenModel({ ...this.defaultToken })
      : undefined;
  }

  private async fetchTokenData() {
    try {
      const result = await Promise.all(
        tokenManifestSources.map((url) => axios.get(url))
      );

      const tokens = result
        .flatMap((x) => x.data.tokens)
        .map((x) => new TokenModel({ ...x }));

      tokens.forEach((token) => {
        this.tokenMap.set(token.address, token);
      });

      runInAction(() => {
        this.defaultToken = tokens[0];
      });
    } catch (err) {
      console.error(err);
    }
  }
}
