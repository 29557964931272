import dayjs from "dayjs";
import { everscaleAddressPattern } from "@/utils/constants";
import { TOKEN_ACTION_TYPES } from "@/models/TokenModel";

const websiteRegex = new RegExp(
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
);

export interface VestingParams {
  address: string;
  name: string | undefined;
  token_address: string;
  vesting_start: number | null;
  vesting_end: number | null;
  vesting_amount: string | undefined;
}

const handleNameChange = (val: string) => {
  if (!val) return "Name required";
  if (val?.length > 20) return "Should be less than or equal 20 chars";
};

export const validateAirdrop = (values: any) => {
  const errors = {};

  if (handleNameChange(values.name ?? "")) {
    Object.assign(errors, {
      name: handleNameChange(values.name ?? "")
    });
  }

  if (!values.token_address) {
    Object.assign(errors, {
      token_address: "To create airdrop, you must first create and mint tokens."
    });
  }

  return errors;
};

export const validateVesting = (values: VestingParams) => {
  const errors = {};

  if (handleNameChange(values.name ?? "")) {
    Object.assign(errors, {
      name: handleNameChange(values.name ?? "")
    });
  }

  if (!values.address || !everscaleAddressPattern.test(values.address)) {
    Object.assign(errors, { address: "Required 0:... format address" });
  }

  if (!values.token_address) {
    Object.assign(errors, {
      token_address: "To create vesting, you must first create and mint tokens."
    });
  }

  if (!values.vesting_start || +values?.vesting_start < dayjs().unix()) {
    Object.assign(errors, {
      vesting_start: "Should bigger than current time, check time please"
    });
  }
  if (!values.vesting_amount || +values?.vesting_amount === 0) {
    Object.assign(errors, {
      vesting_amount: "Vesting amount should be bigger than 0"
    });
  }

  if (
    values?.vesting_end &&
    values.vesting_start &&
    +values?.vesting_end < +values?.vesting_start
  ) {
    Object.assign(errors, {
      vesting_end:
        "End time should be bigger than start time, check time please"
    });
  }

  if (!values.vesting_end) {
    Object.assign(errors, { vesting_end: "Required" });
  }

  return errors;
};

// todo types
export const validateMarketing = (values: any) => {
  const errors = {};

  if (!values.adFormat.value) {
    Object.assign(errors, {
      adFormat: {
        value: "Required"
      }
    });
  }

  if (!values.website.value) {
    Object.assign(errors, {
      website: {
        value: "Required"
      }
    });
  }

  if (!values?.website?.value?.match(websiteRegex)) {
    Object.assign(errors, {
      website: {
        value: "Should be smth like 'cool.com'"
      }
    });
  }

  if (!values.toEmail.value) {
    Object.assign(errors, {
      toEmail: {
        value: "Email Required"
      }
    });
  }

  return errors;
};

const handleDecimalsChange = (val: string) => {
  if (+val === 0) return "Should be bigger than 0";
  if (+val > 18) return "Should be less than or equal 18";
  if (!val) return "Decimals required";
};

const handleSymbolChange = (val: string) => {
  // if (val?.length > 10) return "Should be less than or equal 10 chars";
  if (!val) return "Symbol required";
};

export const validateTokenForm = (
  values: any,
  formType: TOKEN_ACTION_TYPES
) => {
  const errors = {};

  if (!values.name && formType === "create") {
    Object.assign(errors, {
      name: "Name required"
    });
  }

  if (formType === "create" && handleSymbolChange(values.symbol)) {
    Object.assign(errors, {
      symbol: handleSymbolChange(values.symbol)
    });
  }

  if (formType === "create" && handleDecimalsChange(values.decimals)) {
    Object.assign(errors, {
      decimals: handleDecimalsChange(values.decimals)
    });
  }

  if (
    (!values.supply || +values?.supply === 0) &&
    ["mint", "burn"].includes(formType)
  ) {
    Object.assign(errors, {
      supply: "Supply should be bigger than 0"
    });
  }

  if (
    !values.newAddress &&
    formType === "transfer" &&
    !everscaleAddressPattern.test(values.address)
  ) {
    Object.assign(errors, {
      newAddress: "Address required, 0: + 64 symbols format"
    });
  }

  return errors;
};
