import { usePopperTooltip } from "react-popper-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

type Props = {
  text: string;
  className?: string | undefined;
};
const CopyButton = ({ text, className }: Props) => {
  const intl = useIntl();
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible
  } = usePopperTooltip({ placement: "top" });

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (visible) {
      return;
    }

    setCopied(false);
  }, [visible]);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  return (
    <>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <button className={classNames(className)} ref={setTriggerRef}>
          <FiCopy />
        </button>
      </CopyToClipboard>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />

          {copied ? (
            <span className="text-sm">
              {intl.formatMessage({
                id: "tooltip.copied",
                defaultMessage: "Copied"
              })}
            </span>
          ) : (
            <span className="text-sm">
              {intl.formatMessage({
                id: "tooltip.copy",
                defaultMessage: "Copy"
              })}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default CopyButton;
