import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Address } from "everscale-inpage-provider";
import dayjs from "dayjs";
import classNames from "classnames";
import ROUTES from "@/routes";
import styles from "./index.module.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import {
  useVestingStore,
  useTokenStore,
  useWallet
} from "@/providers/BaseStoresProvider";

import { TokenModel } from "@/models/TokenModel";
import { cutString } from "@/utils/strings";
import { unixTsToDateStr, countDuration } from "@/utils/dates";

import Container from "@/components/core/container";
import Button from "@/components/core/button";
import BN, { convertAmount } from "@/utils/BN";
import TokenSelect from "@/components/common/token-select";
import VenTooltip from "@/components/common/tooltip";
import { Spinner } from "@/components/common/spinner";

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";

interface IProps {}

const arrStrokeColors = ["#f4ff1c", "#6AA4EE", "#ffc658", "#f4ff1c"];
const arrFillColors = [
  "rgba(191, 191, 55, 0.1)",
  "rgba(115, 140, 170, 0.1)",
  "rgba(139, 66, 132, 0.12)",
  "rgba(191, 191, 55, 0.1)"
];

const getDates = (startDate: Date, endDate: Date) => {
  const dates = [];
  let currentDate = startDate;
  const addDays = function (days: number) {
    const date = new Date(currentDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

const VestingPage: React.FC<IProps> = () => {
  const [getTokenVestings, setTokenVestings] = useState<any[]>([]);
  const [getSelectTokenVal, setTokenVal] = useState<TokenModel | null>(null);
  const [getChartData, setChartData] = useState<any[]>([]);
  const intl = useIntl();
  const walletStore = useWallet();
  const tokenStore = useTokenStore();
  const vestingStore = useVestingStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (!walletStore?.isConnected) {
      setTokenVal(null);
      return;
    }
    if (tokenStore.filteredUserTokens?.length) {
      tokenChange(tokenStore.filteredUserTokens[0]);
      return;
    }
    const reqTokens = async () => {
      await tokenStore.loadUserTokens();
      if (!tokenStore.filteredUserTokens) return;
      tokenChange(tokenStore.filteredUserTokens[0]);
    };
    reqTokens();
  }, [walletStore?.isConnected, tokenStore]);

  useEffect(() => {
    if (tokenStore.contractsFactoryAddress) vestingStore.getAllVestings();
  }, [tokenStore, tokenStore.contractsFactoryAddress, vestingStore]);

  useEffect(() => {
    const filteredVest = vestingStore.allUserVestings.filter(
      (vest) => vest.token.toString() === getSelectTokenVal?.address
    );

    setTokenVestings(filteredVest);
    buildChartData(filteredVest);
  }, [vestingStore.allUserVestings, getSelectTokenVal]);

  useEffect(() => {
    if (walletStore.account?.address) vestingStore.getAllVestings();
  }, [walletStore.account?.address, vestingStore]);

  // taking earliest and latest date among vestings and separating them by date
  // then counting how much user able to claim tokens on that date
  const buildChartData = (
    data: {
      start: string;
      end: string;
      name: string;
      amount: string;
      symbol: string;
      decimals: number;
      isTokensFilled: boolean;
      token: Address;
      user: Address;
      vesting: Address;
    }[]
  ) => {
    const theEarliestDate = Math.min(...data.map((_) => +_.start));
    let theLatestDate = Math.max(...data.map((_) => +_.end));
    theLatestDate += 86400;

    let periods: Date[] = [];

    // if less than 1 day, separate based on 5 periods
    if (theLatestDate - theEarliestDate < 86400) {
      const diff = theLatestDate - theEarliestDate;
      periods = Array.from(Array(5)).map((_, index) => {
        const date: number = theEarliestDate + ((diff / 4) * index + 1);
        return new Date(date * 1000);
      });
    } else {
      periods = getDates(
        new Date(theEarliestDate * 1000),
        new Date(theLatestDate * 1000)
      );
    }

    const countPercent = (currentVal: BN, totalVal: BN, decimals: number) => {
      return totalVal
        .minus(currentVal)
        .div(totalVal)
        .times(100)
        .toDecimalPlaces(2)
        .toString();
    };

    const valsData = data.map((vestingData, index) => {
      const chartData = periods.map((date, indexDate) => {
        if (+vestingData.start > dayjs(date).unix()) {
          return {
            amt: "0",
            [`${vestingData.name}_${index + 1}`]: "0",
            name: unixTsToDateStr(dayjs(date).unix()),
            date: indexDate
          };
        }
        if (+vestingData.end < dayjs(date).unix()) {
          return {
            amt: "100",
            [`${vestingData.name}_${index + 1}`]: "100",
            name: unixTsToDateStr(dayjs(date).unix()),
            date: indexDate
          };
        }
        const passed = +vestingData.end - +dayjs(date).unix();
        const period = +vestingData.end - +vestingData.start;

        const val = new BN(vestingData.amount).times(passed).div(period);
        const getPercentageVal = countPercent(
          val,
          new BN(vestingData.amount),
          vestingData.decimals
        );
        return {
          amt: getPercentageVal,
          [`${vestingData.name}_${index + 1}`]: getPercentageVal,
          name: unixTsToDateStr(dayjs(date).unix()),
          date: indexDate
        };
      });

      return chartData;
    });

    const mergedData = valsData
      .flat(1)
      .reduce((acc: any, item: any) => {
        if (!item) return acc;
        if (item) {
          const index = acc.findIndex((x: any) => x?.date === item?.date);
          if (index === -1) {
            acc.push(item);
          } else {
            acc[index] = { ...acc[index], ...item };
          }
        } else {
          acc.push(item);
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => a.date - b.date);

    setChartData(mergedData);

    // console.log(theEarliestDate, "---data theEarliestDate");
    // console.log(theLatestDate, "---data theLatestDate");
    // console.log(mergedData, "---data valsData2");
  };

  const tokenChange = (val: TokenModel | null) => {
    setTokenVal(val);
  };

  useEffect(() => {
    if (!walletStore.isConnected) {
      return;
    }

    const reqFactoryState = () => vestingStore.getAllVestings();

    const intervalId = setInterval(reqFactoryState, 20000);

    reqFactoryState();

    return () => {
      clearInterval(intervalId);
    };
  }, [vestingStore, walletStore.isConnected]);

  return (
    <Container className="w-10/12 pr-4 mt-2 pl-1">
      <div className="breadcrumbs">
        <Link
          className="breadcrumbs__link breadcrumbs__link--active"
          to={ROUTES.vesting.path}
        >
          {intl.formatMessage({
            id: "breadcrumbs.vesting",
            defaultMessage: "Vesting"
          })}
        </Link>
      </div>

      {tokenStore.tokensLoading ? (
        <div className="w-full flex items-center justify-center h-[50vh]">
          <Spinner className="max-w-[240px]" />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <span className="font-poppins font-semibold text-3xl text-white mr-8 mb-2">
              {intl.formatMessage({
                id: "vesting.title",
                defaultMessage: "Vesting"
              })}
            </span>
            <Button
              onClick={() =>
                navigate(`${ROUTES.vesting.path}/${ROUTES.vesting.create.path}`)
              }
              variant="primary"
              className={classNames(styles.title_bar_button, "mr-8")}
            >
              {intl.formatMessage({
                id: "vesting.createVesting",
                defaultMessage: "Create new vesting"
              })}
            </Button>
            <Button
              onClick={() =>
                navigate(`${ROUTES.vesting.path}/${ROUTES.vesting.claim.path}`)
              }
              variant="primary"
              className={styles.title_bar_button}
            >
              {intl.formatMessage({
                id: "vesting.claimVesting",
                defaultMessage: "Link to claim"
              })}
            </Button>
          </div>
          {getTokenVestings?.length === 0 && (
            <ul className="pl-4 mb-14 text-secondary-600 list-disc">
              <li className="text-base mb-2">
                {intl.formatMessage({
                  id: "vesting.noVestingText1",
                  defaultMessage:
                    "Vesting happens when tokens are held or locked for a certain duration of time by project members and early investors, and then released afterwards"
                })}
              </li>
              <li className="text-base mb-2">
                {intl.formatMessage({
                  id: "vesting.noVestingText2",
                  defaultMessage:
                    "Venomize allows you to create customizable vesting schedules and claim vested tokens by clicking on the corresponding buttons above"
                })}
              </li>
              <li className="text-base mb-2">
                {intl.formatMessage({
                  id: "vesting.noVestingText3",
                  defaultMessage:
                    "Once you create your first vesting schedule, you will see a beautiful graphic with a table that displays important data"
                })}
              </li>
            </ul>
          )}

          <div className="flex mt-2">
            <div className="font-poppins font-medium text-xl text-white">
              {intl.formatMessage({
                id: "vesting.schedule",
                defaultMessage: "Schedule"
              })}
            </div>
            <div className="max-w-[180px] w-full flex items-center ml-4">
              <div className="max-w-[180px]">
                <TokenSelect
                  type="large"
                  addNative
                  list={tokenStore.createdAndFilteredUserTokens}
                  value={getSelectTokenVal}
                  onChange={tokenChange}
                />
              </div>
            </div>
          </div>
          {tokenStore.filteredUserTokens?.length && (
            <p className="font-base text-secondary-600 mt-2 mb-8 mb-6">
              {intl.formatMessage({
                id: "vesting.vestingTableText",
                defaultMessage:
                  "Select different tokens to view the cumulative graph with vesting schedules for each one"
              })}
            </p>
          )}
          <div className="text-base text-white/50 mt-4 mb-12">
            {!tokenStore.isFactoryInitialized ||
            getTokenVestings.length === 0 ? (
              intl.formatMessage({
                id: "vesting.table.noVestings",
                defaultMessage:
                  "To view the data, you must first create a vesting"
              })
            ) : (
              <div className="recharts-wrap">
                <ResponsiveContainer width="100%" height={350}>
                  <AreaChart
                    width={500}
                    height={350}
                    data={getChartData}
                    syncId="LineChart1"
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0
                    }}
                  >
                    <CartesianGrid
                      stroke="rgba(255, 255, 255, .1)"
                      strokeDasharray="3 3"
                    />
                    <XAxis
                      stroke="rgba(255, 255, 255, .5)"
                      strokeOpacity="0"
                      dataKey="name"
                      tickMargin={10}
                    />
                    <YAxis
                      tickFormatter={(tick) => {
                        return `${tick} %`;
                      }}
                      ticks={[0, 25, 50, 75, 100]}
                      strokeOpacity="0"
                      stroke="rgba(255, 255, 255, 0.5)"
                    />
                    <Legend />
                    <Tooltip />
                    {getTokenVestings?.length &&
                      getTokenVestings.map((chartItem, index) => (
                        <Area
                          key={index}
                          type="monotone"
                          dataKey={`${chartItem.name}_${index + 1}`}
                          fill={arrFillColors[(index % 3) + 1]}
                          stroke={arrStrokeColors[(index % 3) + 1]}
                        />
                      ))}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
          <h3 className="font-poppins font-medium text-xl text-white mt-8">
            {intl.formatMessage({
              id: "vesting.myVestings",
              defaultMessage: "My vestings"
            })}
          </h3>
          {getTokenVestings?.length > 0 && (
            <p className="font-base text-secondary-600 mt-2 mb-8 mb-6">
              {intl.formatMessage({
                id: "vesting.clickOnVesting",
                defaultMessage:
                  "Click on the vesting name to go to the vesting page with a detailed description of it"
              })}
            </p>
          )}
          {!getTokenVestings?.length ? (
            <div className="text-base text-white/50 mt-4 mb-12">
              {intl.formatMessage({
                id: "vesting.table.noVestings",
                defaultMessage:
                  "To view the data, you must first create a vesting"
              })}
            </div>
          ) : (
            <div className="table-bordered--overflow">
              <table className="table-bordered">
                <thead className="text-2xl">
                  <tr>
                    <th>
                      <VenTooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "vesting.nameTooltip",
                              defaultMessage:
                                "The way the creator of the vesting schedule named it"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "vesting.table.name",
                              defaultMessage: "NAME"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </VenTooltip>
                    </th>
                    <th>
                      <VenTooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "vesting.nameTooltip2",
                              defaultMessage:
                                "Cliff is the period between when the creator creates the vesting schedule and when the first batch of vested tokens is unlocked"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "vesting.table.cliff",
                              defaultMessage: "CLIFF"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </VenTooltip>
                    </th>
                    <th>
                      <VenTooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "vesting.nameTooltip3",
                              defaultMessage:
                                "The day vested tokens start to unlock"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "vesting.table.start",
                              defaultMessage: "START DATE"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </VenTooltip>
                    </th>
                    <th>
                      <VenTooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "vesting.nameTooltip4",
                              defaultMessage:
                                "The day on which the full amount of vested tokens becomes available"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "vesting.table.end",
                              defaultMessage: "END DATE"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </VenTooltip>
                    </th>
                    <th>
                      <VenTooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "vesting.nameTooltip5",
                              defaultMessage:
                                "The number of tokens that are subject to a vesting schedule"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "vesting.table.vested",
                              defaultMessage: "VESTED AMOUNT"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </VenTooltip>
                    </th>
                  </tr>
                </thead>
                <tbody className="table-bordered__body">
                  {getTokenVestings?.length &&
                    getTokenVestings.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() =>
                          navigate(
                            `${ROUTES.vesting.path}/${item.vesting.toString()}`
                          )
                        }
                      >
                        <td>
                          {item?.name?.length > 10 ? (
                            <VenTooltip
                              config={{ placement: "top-start" }}
                              content={item.name}
                            >
                              <span>
                                {item.name?.length > 10
                                  ? cutString(item.name.toString(), 5, 4)
                                  : item.name}
                              </span>
                            </VenTooltip>
                          ) : (
                            <span>{item?.name}</span>
                          )}
                        </td>
                        <td>
                          {countDuration(item.createdAt, item.start)?.val}{" "}
                          {countDuration(item.createdAt, item.start)?.name}
                        </td>
                        <td>
                          {unixTsToDateStr(item.start, "DD.MM.YYYY HH:mm")}
                        </td>
                        <td>{unixTsToDateStr(item.end, "DD.MM.YYYY HH:mm")}</td>
                        <td>
                          <div className="flex">
                            <span className="mr-1">
                              {convertAmount(item.amount, item.decimals)}{" "}
                            </span>

                            {item?.symbol?.length > 10 ? (
                              <div className="text-ellipsis overflow-hidden max-w-[150px]">
                                <VenTooltip
                                  config={{ placement: "top-start" }}
                                  content={item?.symbol}
                                >
                                  <span>
                                    {cutString(item?.symbol.toString(), 5, 4)}
                                  </span>
                                </VenTooltip>
                              </div>
                            ) : (
                              <span>{item?.symbol}</span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default observer(VestingPage);
