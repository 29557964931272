import { Api } from "@/api";
import { observer, useLocalObservable } from "mobx-react-lite";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { WalletStore } from "@/stores/WalletStore";
import { LayoutStore } from "@/stores/LayoutStore";
import { StaticDataStore } from "@/stores/StaticDataStore";
import { NotificationStore } from "@/stores/NotificationStore";
import { TokenStore } from "@/stores/TokenStore";
import { VestingStore } from "@/stores/VestingStore";
import { AirdropStore } from "@/stores/AirdropStore";
import { useIntl } from "react-intl";

const api = new Api({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

type BaseStores = {
  wallet: WalletStore;
  layout: LayoutStore;
  tokenStore: TokenStore;
  vestingStore: VestingStore;
  airdropStore: AirdropStore;
  staticData: StaticDataStore;
  noficiations: NotificationStore;
  api: Api<unknown>;
};

const baseStoreContext = createContext<BaseStores>({} as BaseStores);

export const ProvideBaseStores = observer(({ children }: any) => {
  const intl = useIntl();
  const [initialized, setInitialized] = useState(false);
  const noficiations = useLocalObservable(() => new NotificationStore());
  const walletStore = useLocalObservable(() => new WalletStore());
  const layoutStore = useLocalObservable(() => new LayoutStore());
  const tokenStore = useLocalObservable(
    () => new TokenStore(walletStore, intl, noficiations)
  );
  const airdropStore = useLocalObservable(
    () => new AirdropStore(walletStore, tokenStore, intl, noficiations)
  );
  const vestingStore = useLocalObservable(
    () => new VestingStore(walletStore, tokenStore, intl, noficiations)
  );
  const staticDataStore = useLocalObservable(() => new StaticDataStore());

  const stores = useMemo(
    () => ({
      wallet: walletStore,
      layout: layoutStore,
      staticData: staticDataStore,
      tokenStore,
      airdropStore,
      vestingStore,
      noficiations,
      api: api
    }),
    [
      noficiations,
      walletStore,
      layoutStore,
      staticDataStore,
      tokenStore,
      airdropStore,
      vestingStore
    ]
  );

  useEffect(() => {
    const init = async () => {
      await Promise.all([staticDataStore.init(), walletStore.init()]);

      setInitialized(true);
    };

    init();
  }, [walletStore, staticDataStore]);

  useEffect(() => {
    if (!walletStore?.isConnected) {
      tokenStore.resetStore();
      vestingStore.resetStore();
      airdropStore.resetStore();
      return;
    }
    const reqFactoryState = async () => {
      tokenStore.setFactoryInitialized(await tokenStore.getContractsFactory());
    };
    reqFactoryState();
  }, [
    tokenStore,
    walletStore,
    walletStore.isConnected,
    vestingStore,
    airdropStore
  ]);

  if (!initialized) {
    return null;
  }

  return (
    <baseStoreContext.Provider value={stores}>
      {children}
    </baseStoreContext.Provider>
  );
});

export const useTokenStore = () => {
  const { tokenStore } = useContext(baseStoreContext);

  return tokenStore;
};

export const useVestingStore = () => {
  const { vestingStore } = useContext(baseStoreContext);

  return vestingStore;
};

export const useAirdropStore = () => {
  const { airdropStore } = useContext(baseStoreContext);

  return airdropStore;
};

export const useNotifyStore = () => {
  const { noficiations } = useContext(baseStoreContext);

  return noficiations;
};

export const useWallet = () => {
  const { wallet } = useContext(baseStoreContext);

  return wallet;
};

export const useLayout = () => {
  const { layout } = useContext(baseStoreContext);

  return layout;
};

export const useStaticData = () => {
  const { staticData } = useContext(baseStoreContext);

  return staticData;
};

export const useApi = () => {
  const { api } = useContext(baseStoreContext);

  return api;
};
