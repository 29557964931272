export const LinearVestAbi = {
  "ABI version": 2,
  version: "2.2",
  header: ["time", "expire"],
  functions: [
    {
      name: "constructor",
      inputs: [
        { name: "_name", type: "string" },
        { name: "_user", type: "address" },
        { name: "_creator", type: "address" },
        { name: "_token", type: "address" },
        { name: "_wever_token", type: "address" },
        { name: "_wever_vault", type: "address" },
        { name: "_vestingAmount", type: "uint128" },
        { name: "_vestingStart", type: "uint32" },
        { name: "_vestingEnd", type: "uint32" }
      ],
      outputs: []
    },
    {
      name: "receiveTokenWalletAddress",
      inputs: [{ name: "wallet", type: "address" }],
      outputs: []
    },
    {
      name: "receiveWEverTokenWalletAddress",
      inputs: [{ name: "wallet", type: "address" }],
      outputs: []
    },
    {
      name: "buildWeverTransferPayload",
      inputs: [{ name: "sender", type: "address" }],
      outputs: [{ name: "payload", type: "cell" }]
    },
    {
      name: "dummy",
      inputs: [{ name: "value0", type: "address" }],
      outputs: []
    },
    {
      name: "getDetails",
      inputs: [],
      outputs: [
        { name: "_user", type: "address" },
        { name: "_creator", type: "address" },
        { name: "_token", type: "address" },
        { name: "_vestingAmount", type: "uint128" },
        { name: "_vestingStart", type: "uint32" },
        { name: "_vestingEnd", type: "uint32" },
        { name: "_lastClaimTime", type: "uint32" },
        { name: "_tokenBalance", type: "uint128" },
        { name: "_weverTokenBalance", type: "uint128" },
        { name: "_tokenWallet", type: "address" },
        { name: "_filled", type: "bool" },
        { name: "_vested", type: "bool" },
        { name: "_nonce", type: "uint128" },
        { name: "_factory", type: "address" },
        { name: "_version", type: "uint16" }
      ]
    },
    {
      name: "onAcceptTokensMint",
      inputs: [
        { name: "value0", type: "address" },
        { name: "amount", type: "uint128" },
        { name: "remainingGasTo", type: "address" },
        { name: "payload", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "onAcceptTokensTransfer",
      inputs: [
        { name: "value0", type: "address" },
        { name: "amount", type: "uint128" },
        { name: "sender", type: "address" },
        { name: "value3", type: "address" },
        { name: "remainingGasTo", type: "address" },
        { name: "value5", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "pendingVested",
      inputs: [],
      outputs: [{ name: "tokens_to_claim", type: "uint128" }]
    },
    {
      name: "claim",
      inputs: [],
      outputs: []
    },
    {
      name: "onAcceptTokensBurn",
      inputs: [
        { name: "amount", type: "uint128" },
        { name: "value1", type: "address" },
        { name: "value2", type: "address" },
        { name: "_user", type: "address" },
        { name: "payload", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "upgrade",
      inputs: [
        { name: "new_code", type: "cell" },
        { name: "new_version", type: "uint16" },
        { name: "send_gas_to", type: "address" }
      ],
      outputs: []
    },
    {
      name: "name",
      inputs: [],
      outputs: [{ name: "name", type: "string" }]
    },
    {
      name: "createdAt",
      inputs: [],
      outputs: [{ name: "createdAt", type: "uint32" }]
    },
    {
      name: "weverRootAddress",
      inputs: [],
      outputs: [{ name: "weverRootAddress", type: "address" }]
    },
    {
      name: "weverVault",
      inputs: [],
      outputs: [{ name: "weverVault", type: "address" }]
    }
  ],
  data: [
    { key: 1, name: "nonce", type: "uint128" },
    { key: 2, name: "factory", type: "address" },
    { key: 3, name: "version", type: "uint16" }
  ],
  events: [
    {
      name: "Deposit",
      inputs: [
        { name: "sender", type: "address" },
        { name: "amount", type: "uint128" }
      ],
      outputs: []
    },
    {
      name: "BadDeposit",
      inputs: [
        { name: "sender", type: "address" },
        { name: "amount", type: "uint128" }
      ],
      outputs: []
    },
    {
      name: "Claim",
      inputs: [
        { name: "amount", type: "uint128" },
        { name: "remaining_amount", type: "uint128" }
      ],
      outputs: []
    },
    {
      name: "Vested",
      inputs: [],
      outputs: []
    }
  ],
  fields: [
    { name: "_pubkey", type: "uint256" },
    { name: "_timestamp", type: "uint64" },
    { name: "_constructorFlag", type: "bool" },
    { name: "nonce", type: "uint128" },
    { name: "factory", type: "address" },
    { name: "version", type: "uint16" },
    { name: "name", type: "string" },
    { name: "createdAt", type: "uint32" },
    { name: "user", type: "address" },
    { name: "creator", type: "address" },
    { name: "token", type: "address" },
    { name: "weverRootAddress", type: "address" },
    { name: "weverVault", type: "address" },
    { name: "vestingAmount", type: "uint128" },
    { name: "vestingStart", type: "uint32" },
    { name: "vestingEnd", type: "uint32" },
    { name: "lastClaimTime", type: "uint32" },
    { name: "tokenBalance", type: "uint128" },
    { name: "weverTokenBalance", type: "uint128" },
    { name: "weverTokenWallet", type: "address" },
    { name: "tokenWallet", type: "address" },
    { name: "filled", type: "bool" },
    { name: "vested", type: "bool" }
  ]
} as const;
