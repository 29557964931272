import { useLocation } from "react-router-dom";
import classNames from "classnames";
import ROUTES from "@/routes";
import styles from "./index.module.css";
import AccountInfo from "../account-info";
import { ReactComponent as Logo } from "@/assets/logo.svg";
import { ReactComponent as LinkIcon } from "@/assets/icons/link.svg";
import { ReactComponent as ExternalIcon } from "@/assets/icons/link_external.svg";
import useWindowSize from "@/hooks/useWindowSize";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useWallet } from "@/providers/BaseStoresProvider";

const isRoutesEquals = (a: string, b: string) => {
  return b.replaceAll("", "").includes(a.replaceAll("", ""));
};

interface IProps {
  showNavbar: boolean;
  onClose?: () => void;
}

const Navbar: React.FC<IProps> = observer(({ showNavbar, onClose }) => {
  const intl = useIntl();
  const location = useLocation();
  const { width } = useWindowSize();
  const wallet = useWallet();

  const externalLink = useMemo(
    () => [
      {
        url: "https://venomize.gitbook.io/",
        text: intl.formatMessage({
          id: "navbar.faq",
          defaultMessage: "FAQ"
        })
      }
    ],
    [intl]
  );

  const links = useMemo(
    () => [
      {
        url: "/tokens",
        text: intl.formatMessage({
          id: "navbar.main",
          defaultMessage: "Tokens"
        })
      },
      {
        url: "/vesting",
        text: intl.formatMessage({
          id: "navbar.vesting",
          defaultMessage: "Vesting"
        })
      },
      {
        url: "/airdrop",
        text: intl.formatMessage({
          id: "navbar.airdrop",
          defaultMessage: "Airdrop"
        })
      },
      {
        url: "/marketing",
        text: intl.formatMessage({
          id: "navbar.marketing",
          defaultMessage: "Marketing"
        })
      }
    ],
    [intl]
  );

  return (
    <div
      className={classNames(
        styles.navbar,
        showNavbar ? styles.showNavbar : styles.hideNavbar
      )}
    >
      {width && width < 1024 ? (
        <div onClick={() => onClose && onClose()} className={styles.overlay} />
      ) : null}
      <div className={styles.brand_container}>
        <Link to={ROUTES.tokens.path} className={styles.logo}>
          <Logo />
        </Link>
        {wallet.isConnected && (
          <div className="mb-8 z-20">
            <AccountInfo
              showFullBtn
              hideAccount={width && width > 1024 ? true : false}
            />
          </div>
        )}

        <div className={styles.circle} />

        {links.map((link) => (
          <Link
            key={link.url}
            to={link.url}
            className={classNames(
              styles.page_link,
              isRoutesEquals(link.url, location.pathname)
                ? styles.page_link_active
                : null
            )}
          >
            <LinkIcon />
            {link.text}
          </Link>
        ))}

        <div className="mt-8">
          {externalLink.map((link, key) => (
            <a
              key={key}
              target="_blank"
              href={link.url}
              className={classNames(
                styles.page_link,
                isRoutesEquals(link.url, location.pathname)
                  ? styles.page_link_active
                  : null
              )}
              rel="noreferrer"
            >
              <LinkIcon />
              <span>{link.text}</span>
              <ExternalIcon className={styles.external} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Navbar;
