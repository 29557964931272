import "./index.css";

import { useIntl } from "react-intl";
import VenomizeWhite from "@/assets/icons/venomize-white.svg";
import classNames from "classnames";

interface IProps {
  className?: string;
  noContainer?: boolean;
}

const Footer: React.FC<IProps> = ({ className, noContainer }) => {
  const intl = useIntl();

  return (
    <footer className={classNames("footer", className)}>
      <div className={!noContainer ? "container" : "footer__no-container"}>
        <div className="footer__wrap">
          <div className="footer-logo">
            <img src={VenomizeWhite} alt="Venomize" />
          </div>
          <div className="footer__contacts">
            <div className="footer-contact">
              <div className="footer-contact__title">
                {intl.formatMessage({
                  id: "socials.contact",
                  defaultMessage: "Contact us"
                })}
              </div>
              <div className="footer-contact__links">
                <a href="mailto:hello@venomize.com">
                  {intl.formatMessage({
                    id: "socials.email",
                    defaultMessage: "hello.venomize@gmail.com"
                  })}
                </a>
              </div>
            </div>
            <div className="footer-contact">
              <div className="footer-contact__title">
                {intl.formatMessage({
                  id: "socials.follow",
                  defaultMessage: "Follow us"
                })}
              </div>
              <div className="footer-contact__links">
                <a
                  href="https://t.me/venomize"
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage({
                    id: "socials.telegramChannel",
                    defaultMessage: "Telegram Channel"
                  })}
                </a>
                <a
                  href="https://t.me/venomize_chat"
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage({
                    id: "socials.telegramGroup",
                    defaultMessage: "Telegram group"
                  })}
                </a>
                <a
                  href="https://twitter.com/venomize_io"
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage({
                    id: "socials.twitter",
                    defaultMessage: "Twitter"
                  })}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
