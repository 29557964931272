var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { isIOS, isMobile } from "react-device-detect";
import { createRoot } from "react-dom/client";
import { Modal } from "./components";
import { getPromiseRaw, ProviderController } from "./controllers";
import { EventController } from "./controllers/EventController";
import { toggleExtensionWindow, } from "./helpers/backdrop";
import { CLOSE_EVENT, CONNECT_EVENT, ERROR_EVENT, EXTENSION_AUTH_EVENT, EXTENSION_WINDOW_CLOSED_EVENT, SELECT_EVENT, } from "./helpers/events";
import * as allProviders from "./providers";
import { getThemeConfig, themesList } from "./themes";
export var libName = "VenomConnect";
export var VENOM_CONNECT_MODAL_ID = "VENOM_CONNECT_MODAL_ID";
var oldRoot = undefined;
var _getDefaultVenomNetworkNameById = function (networkId) {
    switch (networkId) {
        case 1000:
            return "Venom Testnet";
        case 1:
        default:
            return "Venom Mainnet";
    }
};
var getDefaultVenomNetworkNameById = function (networkId) {
    var _a;
    if (Array.isArray(networkId)) {
        var names = networkId.map(function (id) {
            return _getDefaultVenomNetworkNameById(id);
        });
        return (_a = Array.from(new Set(names))) === null || _a === void 0 ? void 0 : _a[0];
    }
    return _getDefaultVenomNetworkNameById(networkId);
};
var defaultOptions = {
    theme: themesList.default.name,
    providersOptions: {},
    checkNetworkId: 1,
    checkNetworkName: "Venom Mainnet",
};
var VenomConnect = /** @class */ (function () {
    // private pagePosition: number | null = null;
    function VenomConnect(options) {
        var _this = this;
        var _a;
        this.show = false;
        this.eventController = new EventController();
        // работа с логином
        // покажем попап со способами подключения (для мобил - сразу выбор аккаунта)
        // как использовать в случае если уже залогинен - непонятно
        /**
         * This function causes the pop-up to be displayed with the available connection methods: through the extension, links to mobile applications.
         *
         * @return Promise of ProviderRpcClient or error/string
         */
        this.connect = function () {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var connectorIdList, authList;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.updateState({
                                wrongNetwork: undefined,
                            });
                            this.on(CONNECT_EVENT, function (provider) { return resolve(provider); });
                            this.on(ERROR_EVENT, function (error) { return reject(error); });
                            this.on(CLOSE_EVENT, function () { return reject("Pop-up closed"); });
                            connectorIdList = Object.keys(allProviders.connectors);
                            return [4 /*yield*/, this.checkAuth(connectorIdList)];
                        case 1:
                            authList = _a.sent();
                            if (!(!authList || !authList.length)) return [3 /*break*/, 7];
                            if (!this.checkIsWalletBrowser().isVenomWalletBrowser) return [3 /*break*/, 3];
                            return [4 /*yield*/, this.connectTo("venomwallet", "extension")];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 7];
                        case 3:
                            if (!this.checkIsWalletBrowser().isEverWalletBrowser) return [3 /*break*/, 5];
                            return [4 /*yield*/, this.connectTo("everwallet", "extension")];
                        case 4:
                            _a.sent();
                            return [3 /*break*/, 7];
                        case 5: return [4 /*yield*/, this._toggleModal()];
                        case 6:
                            _a.sent();
                            _a.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            }); });
        };
        /**
         * This function allows you to get a specific provider **bypassing the selection pop-up** `connect(walletId, connectorTypeId)`.
         *
         * @return Promise of ProviderRpcClient or error/string
         */
        this.connectTo = function (id, connectorId) {
            return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var provider, walletWayToConnect;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.on(CONNECT_EVENT, function (provider) { return resolve(provider); });
                            this.on(ERROR_EVENT, function (error) { return reject(error); });
                            this.on(CLOSE_EVENT, function () { return reject("Pop-up closed"); });
                            provider = this.providerController.getProvider(id);
                            if (!provider) {
                                return [2 /*return*/, reject(new Error("Cannot connect to provider (".concat(id, "), check provider options")))];
                            }
                            walletWayToConnect = provider.walletWaysToConnect.find(function (walletWayToConnect) { return walletWayToConnect.id === connectorId; }) || provider.walletWaysToConnect[0];
                            return [4 /*yield*/, this.providerController.connectTo(provider.id, walletWayToConnect.id, walletWayToConnect.connector)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); });
        };
        /**
         * return
         *
         * {
         *
         *  show (boolean) for pop-up
         *
         *  themeConfig {...}
         *
         * }
         */
        this.getInfo = function () {
            var show = _this.show;
            var themeConfig = _this.themeConfig;
            // const options = this.options;
            return {
                show: show,
                themeConfig: themeConfig,
                // options,
            };
        };
        /**
         * This function checks authorization in the available connection methods (extensions) and **returns** the corresponding **instance** of the wallet provider.
         *
         * @return Promise of auth ProviderRpcClient or false/undefined
         */
        this.checkAuth = function (providerIdList) {
            if (providerIdList === void 0) { providerIdList = Object.keys(allProviders.providers); }
            return __awaiter(_this, void 0, void 0, function () {
                var fallback, providers, authList, filteredAuthList, auth, authProvider;
                var _this = this;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            fallback = undefined;
                            providers = providerIdList === null || providerIdList === void 0 ? void 0 : providerIdList.map(function (id) { return __awaiter(_this, void 0, void 0, function () {
                                var provider, promises, providerList, _a;
                                var _this = this;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            provider = this.providerController.getProvider(id);
                                            promises = provider === null || provider === void 0 ? void 0 : provider.walletWaysToConnect.filter(function (_a) {
                                                var type = _a.type;
                                                return type === "extension";
                                            }).map(function (_a) {
                                                var authConnector = _a.authConnector, connectorId = _a.id, type = _a.type;
                                                return __awaiter(_this, void 0, void 0, function () {
                                                    var provider, _b;
                                                    return __generator(this, function (_c) {
                                                        switch (_c.label) {
                                                            case 0:
                                                                _b = authConnector;
                                                                if (!_b) return [3 /*break*/, 2];
                                                                return [4 /*yield*/, this.providerController.getAuthTo(id, connectorId, authConnector)];
                                                            case 1:
                                                                _b = (_c.sent());
                                                                _c.label = 2;
                                                            case 2:
                                                                provider = _b;
                                                                if (!(provider === null || provider === void 0 ? void 0 : provider.auth)) {
                                                                    if (id === "venomwallet") {
                                                                        fallback = provider === null || provider === void 0 ? void 0 : provider.fallback;
                                                                    }
                                                                    return [2 /*return*/, null];
                                                                }
                                                                return [2 /*return*/, {
                                                                        connectorId: connectorId,
                                                                        connectorType: type,
                                                                        provider: provider === null || provider === void 0 ? void 0 : provider.auth,
                                                                    }];
                                                        }
                                                    });
                                                });
                                            }).filter(function (promise) { return !!promise; });
                                            _a = promises;
                                            if (!_a) return [3 /*break*/, 2];
                                            return [4 /*yield*/, Promise.all(promises)];
                                        case 1:
                                            _a = (_b.sent());
                                            _b.label = 2;
                                        case 2:
                                            providerList = _a;
                                            return [2 /*return*/, {
                                                    id: id,
                                                    walletWaysToConnect: providerList === null || providerList === void 0 ? void 0 : providerList.filter(function (item) { return !!(item === null || item === void 0 ? void 0 : item.provider); }),
                                                }];
                                    }
                                });
                            }); });
                            return [4 /*yield*/, Promise.all(providers)];
                        case 1:
                            authList = _d.sent();
                            filteredAuthList = authList === null || authList === void 0 ? void 0 : authList.filter(function (_a) {
                                var walletWaysToConnect = _a.walletWaysToConnect;
                                return !!(walletWaysToConnect === null || walletWaysToConnect === void 0 ? void 0 : walletWaysToConnect.length);
                            });
                            auth = (filteredAuthList === null || filteredAuthList === void 0 ? void 0 : filteredAuthList.length) ? filteredAuthList : false;
                            authProvider = (auth && ((_c = (_b = (_a = auth === null || auth === void 0 ? void 0 : auth[0]) === null || _a === void 0 ? void 0 : _a.walletWaysToConnect) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.provider)) || fallback;
                            this.eventController.trigger(CONNECT_EVENT, authProvider);
                            return [2 /*return*/, authProvider];
                    }
                });
            });
        };
        // --------------- PRIVATE METHODS --------------- //
        this.checkIsWalletBrowser = function () {
            var ids = _this.options.map(function (_a) {
                var id = _a.id;
                return id;
            });
            var isVenomWalletBrowser = !!(navigator &&
                navigator.userAgent.includes("VenomWalletBrowser") &&
                ids.includes("venomwallet"));
            var isEverWalletBrowser = !!(navigator &&
                navigator.userAgent.includes("EverWalletBrowser") &&
                ids.includes("everwallet"));
            return {
                isVenomWalletBrowser: isVenomWalletBrowser,
                isEverWalletBrowser: isEverWalletBrowser,
                isOneOfWalletBrowsers: isVenomWalletBrowser || isEverWalletBrowser,
            };
        };
        this.onError = function (error) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.show) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._toggleModal()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.eventController.trigger(ERROR_EVENT, error);
                        return [2 /*return*/];
                }
            });
        }); };
        this.onProviderSelect = function (providerId) {
            _this.eventController.trigger(SELECT_EVENT, providerId);
        };
        this.onConnect = function (provider) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.show) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._toggleModal()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.eventController.trigger(CONNECT_EVENT, provider);
                        return [2 /*return*/];
                }
            });
        }); };
        this.onClose = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.show) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._toggleModal()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.eventController.trigger(CLOSE_EVENT);
                        return [2 /*return*/];
                }
            });
        }); };
        this._toggleModal = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // const body = document.body;
                    // if (body) {
                    //   if (this.show) {
                    //     body.style.overflow = "initial";
                    //     window.scrollTo(0, this.pagePosition || 0);
                    //     this.pagePosition = null;
                    //   } else {
                    //     this.pagePosition = window.scrollY || 0;
                    //     body.style.overflow = "hidden";
                    //   }
                    // }
                    return [4 /*yield*/, this.updateState({ show: !this.show })];
                    case 1:
                        // const body = document.body;
                        // if (body) {
                        //   if (this.show) {
                        //     body.style.overflow = "initial";
                        //     window.scrollTo(0, this.pagePosition || 0);
                        //     this.pagePosition = null;
                        //   } else {
                        //     this.pagePosition = window.scrollY || 0;
                        //     body.style.overflow = "hidden";
                        //   }
                        // }
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.updateState = function (state) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Object.keys(state).forEach(function (key) {
                            // @ts-ignore
                            _this[key] = state[key];
                        });
                        return [4 /*yield*/, window.updateVenomModal(state)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var theme = options.theme || defaultOptions.theme;
        this.themeConfig = getThemeConfig(theme);
        var checkNetworkId = options.checkNetworkId || defaultOptions.checkNetworkId;
        this.checkNetworkId = checkNetworkId;
        var checkNetworkName = options.checkNetworkName ||
            getDefaultVenomNetworkNameById(checkNetworkId);
        this.checkNetworkName = checkNetworkName;
        this.providerController = new ProviderController({
            providersOptions: Object.fromEntries((_a = Object.entries(options.providersOptions)) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                var _b, _c, _d;
                var key = _a[0], value = _a[1];
                var defaultAnyProviderOptions = (_b = defaultOptions.providersOptions) === null || _b === void 0 ? void 0 : _b[key];
                var defaultCurrentProviderOptions = (_c = allProviders.providers) === null || _c === void 0 ? void 0 : _c[key];
                var defaultProviderOptions = __assign(__assign({}, (defaultAnyProviderOptions || {})), (defaultCurrentProviderOptions || {}));
                var providerOptions = {
                    // wallet: {
                    //   ...{
                    //     name: "your wallet",
                    //   },
                    //   ...defaultProviderOptions?.wallet,
                    //   ...value.wallet,
                    //   logo: !!value.wallet?.logo
                    //     ? value.wallet.logo
                    //     : defaultProviderOptions?.wallet?.logo || undefined,
                    // } as WalletDisplay,
                    links: value.links,
                    walletWaysToConnect: ((_d = value.walletWaysToConnect) === null || _d === void 0 ? void 0 : _d.length)
                        ? value.walletWaysToConnect ||
                            (defaultProviderOptions === null || defaultProviderOptions === void 0 ? void 0 : defaultProviderOptions.walletWaysToConnect) ||
                            []
                        : defaultProviderOptions === null || defaultProviderOptions === void 0 ? void 0 : defaultProviderOptions.walletWaysToConnect,
                    defaultWalletWaysToConnect: value.defaultWalletWaysToConnect || [],
                };
                return [
                    key,
                    __assign(__assign({}, defaultProviderOptions), providerOptions),
                ];
            })),
            checkNetworkId: checkNetworkId,
            checkNetworkName: checkNetworkName,
        });
        this.providerController.on(CONNECT_EVENT, function (provider) {
            return _this.onConnect(provider);
        });
        this.providerController.on(ERROR_EVENT, function (error) { return _this.onError(error); });
        this.providerController.on(SELECT_EVENT, this.onProviderSelect);
        this.providerController.on(EXTENSION_AUTH_EVENT, function (_provider) {
            return _this.eventController.trigger(EXTENSION_AUTH_EVENT, _provider);
        });
        this.providerController.on(EXTENSION_WINDOW_CLOSED_EVENT, function () {
            return _this.eventController.trigger(EXTENSION_WINDOW_CLOSED_EVENT);
        });
        this.options = this.providerController.getOptions();
        this.renderModal();
    }
    // --------------- PUBLIC METHODS --------------- //
    VenomConnect.prototype.toggleModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._toggleModal()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Toggle the backdrop when performing an action in the extension window
     *
     * static method (via window)
     */
    VenomConnect.toggleExtensionWindow = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, toggleExtensionWindow(params)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * You can use this function to interactively switch themes in runtime.
     */
    VenomConnect.prototype.updateTheme = function (theme) {
        return __awaiter(this, void 0, void 0, function () {
            var themeConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        themeConfig = getThemeConfig(theme);
                        return [4 /*yield*/, this.updateState({ themeConfig: themeConfig })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * **Subscribing** to internal library events. `on(event, callback)`
     *
     * Returns the corresponding `off` function with no accepted parameters.
     */
    VenomConnect.prototype.on = function (event, callback) {
        var _this = this;
        this.eventController.on({
            event: event,
            callback: callback,
        });
        return function () {
            return _this.eventController.off({
                event: event,
                callback: callback,
            });
        };
    };
    /**
     * **Unsubscribe** from internal library events. `on(event, callback)`
     */
    VenomConnect.prototype.off = function (event, callback) {
        this.eventController.off({
            event: event,
            callback: callback,
        });
    };
    /**
     * The function of getting a standalone provider by its ID. `getStandalone("venomwallet")` or `getStandalone()` By default, the ID is **venomwallet**.
     */
    VenomConnect.prototype.getStandalone = function (walletId) {
        if (walletId === void 0) { walletId = "venomwallet"; }
        return this.providerController.getStandalone(walletId);
    };
    Object.defineProperty(VenomConnect.prototype, "currentProvider", {
        /**
         * Returns the current provider (ProviderRpcClient) or _null_.
         */
        get: function () {
            return this.providerController.currentProvider;
        },
        enumerable: false,
        configurable: true
    });
    VenomConnect.prototype.disconnect = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, ((_c = (_b = (_a = this.currentProvider) === null || _a === void 0 ? void 0 : _a._api) === null || _b === void 0 ? void 0 : _b.disconnect) === null || _c === void 0 ? void 0 : _c.call(_b))];
                    case 1:
                        _d.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _d.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    VenomConnect.prototype.renderModal = function () {
        var _this = this;
        var oldContainer = document.getElementById(VENOM_CONNECT_MODAL_ID);
        if (!oldContainer) {
            var el = document.createElement("div");
            el.id = VENOM_CONNECT_MODAL_ID;
            document.body.appendChild(el);
        }
        var container = oldContainer || document.getElementById(VENOM_CONNECT_MODAL_ID);
        var root = oldRoot || (oldRoot = createRoot(container));
        var optionsIds = Array.from(new Set(this.options.map(function (_a) {
            var id = _a.id;
            return id;
        })));
        var filteredOptions = this.options.filter(function (_a) {
            var id = _a.id;
            var index = optionsIds.findIndex(function (optionsId) { return optionsId === id; });
            if (~index) {
                optionsIds[index] = null;
                return true;
            }
            else {
                return false;
            }
        });
        var supportedOptions = filteredOptions.filter(function (_a) {
            var walletWaysToConnect = _a.walletWaysToConnect;
            var booleanArray = walletWaysToConnect.reduce(function (r, _a) {
                var type = _a.type;
                var result;
                if (isMobile) {
                    if (isIOS) {
                        result = type === "ios";
                    }
                    else {
                        result = type === "android";
                    }
                }
                else {
                    result = type !== "ios" && type !== "android";
                }
                r.push(result);
                return r;
            }, []);
            return booleanArray.includes(true);
        });
        var injectedLinkOptions = supportedOptions.map(function (supportedOption) {
            return __assign(__assign({}, supportedOption), { walletWaysToConnect: supportedOption.walletWaysToConnect.map(function (walletWayToConnect) {
                    var _a, _b, _c, _d, _e;
                    var installExtensionLinkRaw = (_a = walletWayToConnect.options) === null || _a === void 0 ? void 0 : _a["installExtensionLink"];
                    var deepLinkRaw = (_b = walletWayToConnect.options) === null || _b === void 0 ? void 0 : _b["deepLink"];
                    var qrRaw = (_c = walletWayToConnect.options) === null || _c === void 0 ? void 0 : _c["qr"];
                    var devisesRaw = (_d = walletWayToConnect.options) === null || _d === void 0 ? void 0 : _d["devises"];
                    var links = supportedOption.links;
                    return __assign(__assign({}, walletWayToConnect), { options: __assign(__assign({}, walletWayToConnect.options), { installExtensionLink: typeof installExtensionLinkRaw === "function"
                                ? installExtensionLinkRaw(links)
                                : installExtensionLinkRaw, deepLink: typeof deepLinkRaw === "function"
                                ? deepLinkRaw(links)
                                : deepLinkRaw, qr: typeof qrRaw === "function" ? qrRaw(links) : qrRaw, devises: (_e = devisesRaw === null || devisesRaw === void 0 ? void 0 : devisesRaw.map) === null || _e === void 0 ? void 0 : _e.call(devisesRaw, function (devise) {
                                var deviseDeepLinkRaw = devise === null || devise === void 0 ? void 0 : devise["deepLink"];
                                return __assign(__assign({}, devise), { deepLink: typeof deviseDeepLinkRaw === "function"
                                        ? deviseDeepLinkRaw(links)
                                        : deviseDeepLinkRaw });
                            }) }) });
                }) });
        });
        root.render(_jsx(Modal, { networkName: this.checkNetworkName, themeConfig: this.themeConfig, options: injectedLinkOptions, onClose: this.onClose, changeWallet: function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.disconnect()];
                        case 1:
                            _a.sent();
                            this.connect();
                            return [2 /*return*/];
                    }
                });
            }); }, disconnect: this.checkIsWalletBrowser().isOneOfWalletBrowsers
                ? function () { return _this.disconnect(); }
                : undefined }));
    };
    /**
     * The function of getting an object with promises, each of which waits for the initialization of the corresponding provider (_for example: `__venom`_) on the `window` object and is resolved by them or after several attempts is rejected.
     *
     *  You can get the promise you need by wallet ID and connection type `getPromise("venomwallet", "extension")` or you can use the default connection type ("extension") `getPromise("venomwallet")`.
     */
    VenomConnect.getPromise = function (walletId, type) {
        if (type === void 0) { type = "extension"; }
        return getPromiseRaw(window, walletId, type);
    };
    return VenomConnect;
}());
export { VenomConnect };
