import styles from "./index.module.css";
import Button from "@/components/core/button";
import { ReactComponent as NotFoundSvg } from "@/assets/images/not-found.svg";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <NotFoundSvg className={styles.image} />
      <span className={styles.title}>
        {intl.formatMessage({
          id: "layout.page_not_found",
          defaultMessage: "Page not found"
        })}
      </span>
      <span className={styles.subtitle}>
        {intl.formatMessage({
          id: "layout.page_not_found.subtitle",
          defaultMessage:
            "Something seems to have gone wrong. The page you requested does not exist"
        })}
      </span>

      <Link to={`/`}>
        <Button variant="primary">
          {intl.formatMessage({
            id: "layout.gotomain",
            defaultMessage: "Go to Main page"
          })}
        </Button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
