import classNames from "classnames";
import Button from "@/components/core/button";
import { useConnectWallet } from "@/hooks/account/useConnectWallet";
import { observer } from "mobx-react-lite";
import { useWallet } from "@/providers/BaseStoresProvider";

type Props = {
  className?: string;
  children?: any;
};
const ConnectWalletButton = observer(({ className, children }: Props) => {
  const wallet = useWallet();

  const { showConnectWallet } = useConnectWallet();

  const onClick = () => {
    if (wallet.isConnected) {
      return;
    }

    showConnectWallet();
  };

  return (
    <Button
      variant="primary"
      className={classNames(className)}
      onClick={onClick}
    >
      {children}
    </Button>
  );
});

export default ConnectWalletButton;
