import classNames from "classnames";
import dotsAnimation from "@/assets/animations/dots.json";
import circleAnimation from "@/assets/animations/circle.json";
import { Suspense, lazy } from "react";

const LazyLottieComponent = lazy(() => import("lottie-react"));

type Props = {
  className?: string;
  isDots?: boolean;
};

export const Spinner = ({ className, isDots }: Props) => {
  if (typeof window === "undefined") return <></>;
  if (!LazyLottieComponent) return <></>;

  return (
    <div className={classNames("w-full", className)}>
      <Suspense>
        <LazyLottieComponent animationData={circleAnimation} loop={true} />
      </Suspense>
    </div>
  );
};

export const SpinnerDots = ({ className }: Props) => {
  if (typeof window === "undefined") return <></>;
  if (!LazyLottieComponent) return <></>;

  return (
    <div className={classNames("w-full", className)}>
      <Suspense>
        <LazyLottieComponent animationData={dotsAnimation} loop={true} />
      </Suspense>
    </div>
  );
};
