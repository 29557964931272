import styles from "./index.module.css";
import ConnectWalletButton from "@/components/common/connect-wallet-button";
import { observer } from "mobx-react-lite";
import { useWallet, useTokenStore } from "@/providers/BaseStoresProvider";
import { bigNumberToStr } from "@/utils/bignumber";
import { cutString } from "@/utils/strings";
import { IoWalletOutline } from "react-icons/io5";
import { useIntl } from "react-intl";
import { useAccountInfo } from "@/hooks/account/useAccountInfo";
import { ReactComponent as LinkIcon } from "@/assets/icons/link.svg";
import Button from "@/components/core/button";
import classNames from "classnames";

interface IProps {
  hideAccount?: boolean;
  showFullBtn?: boolean;
}

const AccountInfo: React.FC<IProps> = observer(
  ({ hideAccount, showFullBtn }) => {
    const intl = useIntl();
    const wallet = useWallet();
    const tokenStore = useTokenStore();

    const { showAccountInfo } = useAccountInfo();

    const exit = () => {
      console.log(" exit");
      wallet.disconnect();
      tokenStore.resetStore();
    };

    if (!wallet?.account) {
      return (
        <ConnectWalletButton
          className={
            "lg:absolute z-20 top-2 lg:top-6 lg:right-6 !px-5 !py-2 !my-[2px]"
          }
        >
          <IoWalletOutline className="mr-3 text-lg" />

          <span className="hidden sm:inline">
            {intl.formatMessage({
              id: "wallet.connect_wallet",
              defaultMessage: "Connect wallet"
            })}
          </span>

          <span className="sm:hidden">
            {intl.formatMessage({
              id: "wallet.connect_wallet_mobile",
              defaultMessage: "Connect"
            })}
          </span>
        </ConnectWalletButton>
      );
    }

    // todo: verify for Ever or Venom symbol
    return (
      <div
        className={classNames(
          styles.account,
          hideAccount ? styles.hideAccount : null
        )}
        onClick={showAccountInfo}
      >
        <div className="flex flex-col items-end mr-3">
          <span className="text-white text-sm">
            {bigNumberToStr(wallet.coinBalance, 1)}{" "}
            {process.env.REACT_APP_VENOM_ACTIVE === "1"
              ? "VENOM"
              : wallet.coinSymbol}
          </span>
          <span className="text-white/50 text-sm font-bold">
            {cutString(wallet.account?.address.toString(), 6, 4)}
          </span>
        </div>
        <Button variant="primary" onClick={exit}>
          <LinkIcon className={styles.btn_exit} />
          {showFullBtn ? (
            <span className="ml-2">
              {intl.formatMessage({
                id: "wallet.diconnect",
                defaultMessage: "Disconnect"
              })}
            </span>
          ) : null}
        </Button>
      </div>
    );
  }
);

export default AccountInfo;
