import styles from "./index.module.css";
import classNames from "classnames";
import { ChangeEvent } from "react";

interface IProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  regEx?: string;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  decimals?: number;
  type?: string;
  className?: string;
  error?: string;
  icon?: JSX.Element;
}

const InputField: React.FC<IProps> = ({
  value,
  type = "text",
  regEx,
  placeholder,
  className,
  decimals,
  icon,
  disabled,
  error,
  onChange,
  onFocus,
  onBlur,
  ...props
}) => {
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let event = e;
    let val = event.currentTarget?.value;

    if (!val) {
      event.currentTarget.value = "";
      onChange && onChange(event);
      return;
    }
    // Replace comma to the dot
    val = val.replace(",", ".");

    // Replace leading zeros
    if (/^0+[^.]/.test(val)) {
      value = val.replace(/^0+/, "");
    }

    // Limit the number of decimal places to token decimals
    if (
      value &&
      !new RegExp(`^\\d+(.\\d{0,${decimals ?? 18}})?$`).test(val) &&
      type === "number"
    ) {
      return;
    }

    if (type === "number") {
      val = val.replace(/[^0-9.]/g, "");
    }

    if (type === "text") {
      const reg = regEx ? regEx : "[^a-zA-Z0-9_:.]";
      const regExp = new RegExp(reg, "gi");
      val = val.replace(regExp, "");
    }

    event.currentTarget.value = val;

    onChange && onChange(event);
  };
  return (
    <div className={classNames("w-full", className)}>
      <div
        className={classNames(
          styles.container,
          disabled ? styles.disabled : ""
        )}
      >
        {icon ?? null}

        <input
          {...props}
          className={styles.input}
          value={value}
          placeholder={placeholder}
          type={type === "email" ? type : "text"}
          onChange={changeHandler}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete="off"
          disabled={disabled}
        />
      </div>

      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default InputField;
