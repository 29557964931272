import { useState, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import ROUTES from "@/routes";
import styles from "./index.module.css";

import { useFormik } from "formik";
import {
  useTokenStore,
  useVestingStore,
  useWallet
} from "@/providers/BaseStoresProvider";

import { validateVesting as validate } from "@/utils/validate";
import { NATIVE_SYMBOL } from "@/utils/constants";
import { TokenModel } from "@/models/TokenModel";

import DateTimePicker from "@/components/core/datetime-picker";
import Container from "@/components/core/container";
import BreadCrumbs from "@/components/common/breadcrumbs";
import TokenSelect from "@/components/common/token-select";
import Checkbox from "@/components/core/checkbox";
import Button from "@/components/core/button";
import InputField from "@/components/core/input-field";
import { SpinnerDots } from "@/components/common/spinner";
import Tooltip from "@/components/common/tooltip";

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";

interface IProps {}

const ManageVestingPage: React.FC<IProps> = () => {
  const [enableValidation, setEnableValidation] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [getSelectTokenVal, setTokenVal] = useState<TokenModel | null>(null);
  const tokenStore = useTokenStore();
  const vestingStore = useVestingStore();
  const intl = useIntl();
  const navigate = useNavigate();
  const walletStore = useWallet();

  const breadLinks = [
    {
      name: intl.formatMessage({
        id: "breadcrumbs.vesting",
        defaultMessage: "Vesting"
      }),
      active: false,
      link: ROUTES.vesting.path
    },
    {
      name: intl.formatMessage({
        id: "breadcrumbs.create",
        defaultMessage: "Create new vesting"
      }),
      active: true,
      link: `${ROUTES.vesting.path}/${ROUTES.vesting.create.path}`
    }
  ];

  const formik: any = useFormik({
    initialValues: {
      address: "",
      name: undefined,
      token_address: "",
      vesting_amount: undefined,
      vesting_start: dayjs().unix() + 300,
      vesting_end: null
    },
    validateOnBlur: enableValidation,
    validateOnChange: enableValidation,
    validate,
    onSubmit: async (values: any) => {
      setLoading(true);
      try {
        const result = await vestingStore.createNewVesting({
          ...values,
          decimals: getSelectTokenVal?.decimals,
          isNative: getSelectTokenVal?.name === NATIVE_SYMBOL ? true : false
        });
        setLoading(false);
        if (!result) return;
        if (result) navigate(ROUTES.vesting.path);
      } catch (err) {
        throw err;
      }
    }
  });

  const minDate = useMemo(() => new Date(), []);

  const getValDate = (val: number | null) => {
    if (!val) return null;
    return new Date(val * 1000);
  };

  const tokenChange = (val: TokenModel | null) => {
    setTokenVal(val);
    formik.setFieldValue("token_address", val?.address);
  };

  const handleDataChange = (val: Date | null | undefined, key: string) => {
    if (!val) {
      formik.setFieldValue(key, null);
      return;
    }

    formik.setFieldValue(key, dayjs(val).unix());
  };

  const reqTokens = useCallback(() => {
    const func = async () => {
      await tokenStore.loadUserTokens();
      if (!tokenStore.filteredUserTokens) return;
    };
    func();
  }, [tokenStore]);

  useEffect(() => {
    if (tokenStore.filteredUserTokens?.length) return;
    reqTokens();
  }, [tokenStore, reqTokens]);

  const handleBack = (e: any) => {
    const event = e.target.type;
    if (event === "submit") return;
    else navigate(ROUTES.vesting.path);
  };

  return (
    <Container className="md:w-8/12 pr-4 pl-1">
      <BreadCrumbs list={breadLinks} />
      <div className="mt-12">
        <h3 className="font-poppins font-semibold text-3xl text-white">
          {intl.formatMessage({
            id: "vesting.manage.title",
            defaultMessage: "Create new vesting"
          })}
        </h3>
        <p className="font-poppins text-base text-white mb-8 mt-4">
          {intl.formatMessage({
            id: "vesting.manage.title2",
            defaultMessage:
              "Creating a vesting schedule enables you to distribute tokens according to the initial plan agreed upon by your team and partners"
          })}
        </p>
        <h3 className="font-poppins font-medium text-xl text-white mt-8">
          {intl.formatMessage({
            id: "vesting.manage.subtitle",
            defaultMessage: "General information"
          })}
        </h3>
        <p className="font-poppins text-base text-white mb-8 mt-4">
          {intl.formatMessage({
            id: "vesting.manage.subtitle2",
            defaultMessage:
              "General information You can customize and set various parameters to optimize your distribution strategy and achieve your goals more effectively"
          })}
        </p>
        <form
          className="mr-12 w-full"
          onSubmit={(event: any) => {
            setEnableValidation(true);
            event.preventDefault();
            formik.handleSubmit();
          }}
        >
          <div className="flex flex-wrap justify-between mb-5 md:w-10/12">
            <div className="w-full md:w-6/12">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.vestingName",
                  defaultMessage: "Vesting name"
                })}
              </h3>
              <InputField
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder={intl.formatMessage({
                  id: "form.name",
                  defaultMessage: "name"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.name ?? null}
              </span>
            </div>
            <p className="text-sm text-white/50 mb-4 mt-2 md:mt-7 md:w-5/12 max-w-[360px]">
              {intl.formatMessage({
                id: "tokenForm.nameExplanation",
                defaultMessage:
                  "This name will not be changed and will represent your tokens to users in the whole Venom blockchain."
              })}
            </p>
          </div>
          <div className="flex flex-wrap justify-between mb-5 md:w-10/12">
            <div className="w-full md:w-6/12">
              <div className="mb-5">
                <h3 className="text-base text-secondary-600 mb-3">
                  {intl.formatMessage({
                    id: "input.tokenName",
                    defaultMessage: "Token Name"
                  })}
                </h3>
                <div className="w-full">
                  <TokenSelect
                    addNative
                    list={tokenStore.createdAndFilteredUserTokens}
                    value={getSelectTokenVal}
                    onChange={tokenChange}
                  />
                </div>
                <span className="text-sm text-danger-600">
                  {formik.errors.token_address ?? null}
                </span>
              </div>
            </div>
            <p className="text-sm text-white/50 mb-4 mt-2 md:mt-7 md:w-5/12 max-w-[360px]">
              {intl.formatMessage({
                id: "tokenForm.selectNameText",
                defaultMessage: "Select the token from your list of tokens"
              })}
            </p>
          </div>
          <div className="flex flex-wrap justify-between mb-5 md:w-10/12">
            <div className="w-full md:w-6/12">
              <div className="mb-5">
                <h3 className="text-base text-secondary-600 mb-3">
                  {intl.formatMessage({
                    id: "input.startDate",
                    defaultMessage: "Vesting start date"
                  })}
                </h3>
                <DateTimePicker
                  placeholder={intl.formatMessage({
                    id: "form.startDate",
                    defaultMessage: "Select start date"
                  })}
                  showTime
                  value={getValDate(formik.values.vesting_start)}
                  onChange={(val) => handleDataChange(val, "vesting_start")}
                  minDate={minDate}
                  error={formik.errors.vesting_start}
                />
              </div>
            </div>
            <p className="text-sm text-white/50 mb-4 mt-2 md:mt-7 md:w-5/12 max-w-[360px]">
              {intl.formatMessage({
                id: "tokenForm.dateStart",
                defaultMessage:
                  "Choose the date and time when the vested tokens will begin to unlock. Note: the cliff period will be calculated automatically"
              })}
            </p>
          </div>
          <div className="flex flex-wrap justify-between mb-5 md:w-10/12">
            <div className="w-full md:w-6/12">
              <div className="mb-5">
                <h3 className="text-base text-secondary-600 mb-3">
                  {intl.formatMessage({
                    id: "input.endDate",
                    defaultMessage: "Vesting end date"
                  })}
                </h3>
                <DateTimePicker
                  placeholder={intl.formatMessage({
                    id: "form.endDate",
                    defaultMessage: "Select end date"
                  })}
                  showTime
                  value={getValDate(formik.values.vesting_end)}
                  onChange={(val) => handleDataChange(val, "vesting_end")}
                  minDate={minDate}
                  error={formik.errors.vesting_end}
                />
              </div>
            </div>
            <p className="text-sm text-white/50 mb-4 mt-2 md:mt-7 md:w-5/12 max-w-[360px]">
              {intl.formatMessage({
                id: "tokenForm.dateEnd",
                defaultMessage:
                  "Choose the date and time when the total amount of vested tokens will be unlocked"
              })}
            </p>
          </div>
          <div className="flex mb-5 md:w-5/12">
            <Checkbox disabled />
            <div className="ml-4">
              <Tooltip
                config={{ placement: "top-start" }}
                content={
                  <span className="text-sm text-white">
                    {intl.formatMessage({
                      id: "tokenForm.soon",
                      defaultMessage: "It will be available soon"
                    })}
                  </span>
                }
              >
                <div className="flex">
                  <h3 className="text-base text-secondary-600 mb-3 leading-4">
                    {intl.formatMessage({
                      id: "input.periodic",
                      defaultMessage: "Periodic"
                    })}
                  </h3>
                  <InfoIcon className="relative bottom-2 ml-2" />
                </div>
              </Tooltip>
              <p className="text-sm text-white/50 mb-3">
                {intl.formatMessage({
                  id: "input.periodic",
                  defaultMessage:
                    "Periodic vesting is used when tokens are unlocked in batches at certain intervals of time"
                })}
              </p>
            </div>
          </div>
          <div className="flex mb-5 md:w-5/12">
            <Checkbox disabled checked />
            <div className="ml-4">
              <Tooltip
                config={{ placement: "top-start" }}
                content={
                  <span className="text-sm text-white">
                    {intl.formatMessage({
                      id: "tokenForm.soon",
                      defaultMessage: "It will be available soon"
                    })}
                  </span>
                }
              >
                <div className="flex">
                  <h3 className="text-base text-secondary-600 mb-3 leading-4">
                    {intl.formatMessage({
                      id: "input.linear",
                      defaultMessage: "Linear"
                    })}
                  </h3>
                  <InfoIcon className="relative bottom-2 ml-2" />
                </div>
              </Tooltip>
              <p className="text-sm text-white/50 mb-3">
                {intl.formatMessage({
                  id: "input.linear",
                  defaultMessage:
                    "Linear vesting is used when the amount of vested tokens is distributed evenly throughout the vesting period"
                })}
              </p>
            </div>
          </div>
          <div className="page_separator mt-8" />
          <h3 className="font-poppins font-medium text-xl text-white mb-6 mt-8">
            {intl.formatMessage({
              id: "vesting.manage.recipients",
              defaultMessage: "Recipients"
            })}
          </h3>
          <div className="w-full">
            <div className="flex">
              <div className="md:w-5/12 mr-10">
                <h3 className="text-base text-secondary-600 mb-3">
                  {intl.formatMessage({
                    id: "input.recipient",
                    defaultMessage: "Recipient"
                  })}
                </h3>
                <InputField
                  id="address"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  placeholder={intl.formatMessage({
                    id: "form.address",
                    defaultMessage: "user address"
                  })}
                />
                <span className="text-sm text-danger-600">
                  {formik.errors.address ?? null}
                </span>
              </div>
              <div className="ml-10 md:w-5/12">
                <h3 className="text-base text-secondary-600 mb-3">
                  {intl.formatMessage({
                    id: "input.vestingAmount",
                    defaultMessage: "Vesting amount"
                  })}
                </h3>
                <InputField
                  id="vesting_amount"
                  name="vesting_amount"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.vesting_amount}
                  placeholder={intl.formatMessage({
                    id: "form.amount",
                    defaultMessage: "amount"
                  })}
                />
                <span className="text-sm text-danger-600">
                  {formik.errors.vesting_amount ?? null}
                </span>
              </div>
            </div>
          </div>
          <div className="page_separator mt-10" />
          {isLoading ? (
            <SpinnerDots className={styles.spinner} />
          ) : (
            <div className="flex items-center justify-end mt-10 md:w-5/12 mb-20">
              <Button
                type="button"
                variant="secondary"
                inverse
                onClick={(e) => handleBack(e)}
                className="w-5/12 max-w-[100px] mr-4"
              >
                {intl.formatMessage({
                  id: "btn.back",
                  defaultMessage: "Back"
                })}
              </Button>
              <Button
                variant="secondary"
                className="w-8/12 max-w-[130px]"
                type="submit"
                disabledError
                disabled={!formik.isValid || !walletStore.isConnected}
              >
                {intl.formatMessage({
                  id: "btn.vesting",
                  defaultMessage: "Start vesting"
                })}
              </Button>
            </div>
          )}
        </form>
      </div>
    </Container>
  );
};

export default observer(ManageVestingPage);
