import BigNumber from "bignumber.js";
import VenomConnect from "venom-connect";
import { makeAutoObservable, runInAction } from "mobx";
import { Address, ProviderRpcClient } from "everscale-inpage-provider";
import { TokenRootAbi } from "@/abi/token-root";
import { TokenWalletAbi } from "@/abi/token-wallet";
import { COIN_DECIMALS, COIN_SYMBOL, WalletId } from "@/utils/constants";
import { TokenModel } from "@/models/TokenModel";

type Account = {
  address: Address;
  publicKey: string;
  walletType: string;
};

const BALANCE_FETCH_INTERVAL_SEC = 30;

export class WalletStore {
  constructor() {
    makeAutoObservable(this);
  }

  venomConnect?: VenomConnect;
  account?: Account;
  coinBalance: BigNumber = new BigNumber(0);

  get coinSymbol() {
    return COIN_SYMBOL;
  }

  get coinDecimals() {
    return COIN_DECIMALS;
  }

  get isConnected() {
    return !!this.account;
  }

  private balanceInterval?: NodeJS.Timer;
  private _provider?: ProviderRpcClient | null;

  get provider() {
    return this._provider;
  }

  async init() {
    const venomConnect = await initVenomConnect();

    runInAction(() => {
      this.venomConnect = venomConnect;
    });

    const provider = await venomConnect.checkAuth();

    if (provider) {
      await this.onProviderChanged(provider);
    }

    venomConnect.on("connect", (provider) => this.onProviderChanged(provider));
  }

  async connect() {
    await this.venomConnect?.connect();
  }

  async disconnect() {
    await this.provider?.disconnect();
    await this.onProviderChanged();
  }

  async getTokenBalance(token: TokenModel, walletAdd: Address) {
    if (!this.provider || !this.account) {
      return new BigNumber(0);
    }

    try {
      const tokenRoot = new this.provider.Contract(
        TokenRootAbi,
        new Address(token.address)
      );
      const { value0: walletAddress } = await tokenRoot.methods
        .walletOf({
          answerId: 0,
          walletOwner: walletAdd
        })
        .call();

      const tokenWallet = new this.provider.Contract(
        TokenWalletAbi,
        walletAddress
      );

      const { value0: balance } = await tokenWallet.methods
        .balance({ answerId: 0 })
        .call();

      return new BigNumber(balance).shiftedBy(-token.decimals);
    } catch {
      return new BigNumber(0);
    }
  }

  async isTokenWalletDeployed(tokenWalletAddress: Address) {
    if (!this.provider) {
      return false;
    }

    try {
      const tokenWallet = new this.provider.Contract(
        TokenWalletAbi,
        tokenWalletAddress
      );

      await tokenWallet.methods
        .balance({ answerId: 0 })
        .call({ responsible: true });

      return true;
    } catch {
      return false;
    }
  }

  private async onProviderChanged(provider?: ProviderRpcClient | null) {
    const state = await provider?.getProviderState();
    const accountInteraction = state?.permissions.accountInteraction;

    let account: Account | undefined = undefined;

    if (accountInteraction) {
      account = {
        address: accountInteraction.address,
        publicKey: accountInteraction.publicKey,
        walletType: accountInteraction.contractType
      };
    }

    if (this.balanceInterval) {
      clearInterval(this.balanceInterval);
    }

    const balanceInterval = account
      ? setInterval(async () => {
          const coinBalance = await this.getCoinBalance(
            provider,
            account?.address
          );

          runInAction(() => {
            this.coinBalance = coinBalance;
          });
        }, BALANCE_FETCH_INTERVAL_SEC * 1000)
      : undefined;

    const coinBalance = await this.getCoinBalance(provider, account?.address);

    runInAction(() => {
      this._provider = provider;
      this.account = account;
      this.coinBalance = coinBalance;
      this.balanceInterval = balanceInterval;
    });
  }

  private async getCoinBalance(
    provider: ProviderRpcClient | undefined | null,
    address: Address | undefined
  ) {
    if (!provider || !address) {
      return new BigNumber(0);
    }

    const balance = await provider.getBalance(address);

    return new BigNumber(balance).shiftedBy(-this.coinDecimals);
  }
}

const initVenomConnect = async () => {
  return new VenomConnect({
    theme: "dark",
    checkNetworkId: [42, 1],
    checkNetworkName: "Ever Mainnet",
    providersOptions: {
      [WalletId.Venom]: {
        links: {
          extension: [
            {
              browser: "chrome",
              link: "https://chrome.google.com/webstore/detail/venom-wallet/ojggmchlghnjlapmfbnjholfjkiidbch"
            },
            {
              browser: "chrome",
              link: "https://chrome.google.com/webstore/detail/ever-wallet/cgeeodpfagjceefieflmdfphplkenlfk"
            },
            {
              browser: "firefox",
              link: "https://addons.mozilla.org/en-US/firefox/addon/ever-wallet"
            }
          ],
          android: undefined,
          ios: null
        },
        walletWaysToConnect: [
          {
            package: ProviderRpcClient,
            packageOptions: {
              fallback:
                VenomConnect.getPromise("venomwallet", "extension") ||
                (() => Promise.reject()),
              forceUseFallback: true
            },
            id: "extension",
            type: "extension"
          }
        ],
        defaultWalletWaysToConnect: ["mobile", "ios", "android"]
      },

      [WalletId.Ever]: {
        links: {
          extension: [
            {
              browser: "chrome",
              link: "https://chrome.google.com/webstore/detail/ever-wallet/cgeeodpfagjceefieflmdfphplkenlfk"
            },
            {
              browser: "firefox",
              link: "https://addons.mozilla.org/en-US/firefox/addon/ever-wallet"
            }
          ],
          android:
            "https://play.google.com/store/apps/details?id=com.broxus.crystal.app",
          ios: "https://apps.apple.com/us/app/ever-wallet-everscale/id1581310780"
        },
        walletWaysToConnect: [
          {
            package: ProviderRpcClient,
            packageOptions: {
              fallback:
                VenomConnect.getPromise("everwallet", "extension") ||
                (() => Promise.reject()),
              forceUseFallback: true
            },
            id: "extension",
            type: "extension"
          }
        ],
        defaultWalletWaysToConnect: ["mobile", "ios", "android"]
      }
    }
  });
};
