import { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { useNavigate, Link } from "react-router-dom";
import { useWallet, useNotifyStore } from "@/providers/BaseStoresProvider";
import { cutString } from "@/utils/strings";
import styles from "./index.module.css";
import classNames from "classnames";
import ROUTES from "@/routes";

import copy from "copy-to-clipboard";
import { ReactComponent as CopyIcon } from "@/assets/icons/copy.svg";
import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";
import { ReactComponent as ExternalIcon } from "@/assets/icons/link_external.svg";

import Button from "@/components/core/button";
import { Spinner } from "@/components/common/spinner";
import { TokenModel } from "@/models/TokenModel";
import TokenSelect from "@/components/common/token-select";
import { useTokenStore } from "@/providers/BaseStoresProvider";
import Container from "@/components/core/container";
import Tooltip from "@/components/common/tooltip";
import { VENOM_SCAN } from "@/utils/constants";
import Modal from "@/components/core/modal";
import InputField from "@/components/core/input-field";

const MainPage = () => {
  // on tokens page we show only OWNED tokens by user
  const [getSelectTokenVal, setTokenVal] = useState<TokenModel | null>(null);
  const [showCustomModal, setCustomModal] = useState<boolean>(false);
  const [customTokenAdd, setCustomAdd] = useState<string>("");
  const tokenStore = useTokenStore();
  const intl = useIntl();
  const walletStore = useWallet();
  const navigate = useNavigate();
  const noficiations = useNotifyStore();

  const reqTokens = useCallback(() => {
    const func = async () => {
      await tokenStore.loadUserTokens();
      if (!tokenStore.tokensData) return;
      tokenChange(tokenStore.tokensData[0]);
    };
    func();
  }, [tokenStore]);

  const handleCopyAddress = (address: string) => {
    address && copy(address);
    noficiations.notify("", {
      type: "success",
      title: "Address was copied"
    });
  };

  useEffect(() => {
    if (!walletStore?.isConnected) {
      setTokenVal(null);
      return;
    }
    // case for new minted token, there is a little delay of minting
    // currenly we are imitating on success creation
    if (tokenStore.tokensData?.length) {
      tokenChange(tokenStore.tokensData[0]);
      return;
    }
    reqTokens();
  }, [tokenStore, reqTokens, walletStore?.isConnected]);

  // sorting table
  const filterArr = (arr: [string, any][]) => {
    const keys = ["name", "symbol", "supply", "decimals", "address"];
    const arrVal: [string, any][] = keys.map((_) => {
      const val: any[] | undefined = arr.find((item) => item[0] === _);

      return [_, val ? val[1] : ""];
    });

    return arrVal;
  };

  const tokenChange = (val: TokenModel | null) => {
    setTokenVal(val);
  };

  const addCustomTokenTrigger = async () => {
    tokenStore.addCustomToken(customTokenAdd);
    setCustomModal(false);
    await tokenStore.loadUserTokens();

    const token = tokenStore.tokensData.find(
      (_) => _.address.toLowerCase() === customTokenAdd.toLowerCase()
    );

    if (token) tokenChange(token);

    noficiations.notify("Success", {
      type: "success",
      title: "Address added"
    });
  };

  const checkForTransfer = () => {
    return (
      !getSelectTokenVal ||
      (getSelectTokenVal &&
        getSelectTokenVal?.owner !== walletStore.account?.address.toString())
    );
  };

  const openModalToken = () => {
    console.log("openModalToken");
    setCustomModal(true);
  };

  const handleChange = (e: any) => {
    setCustomAdd(e.target.value ?? "");
  };

  return (
    <Container className="w-8/12 pr-4 mt-2 pl-1">
      <Modal
        noPadding
        bounce={false}
        size="sm"
        isOpened={showCustomModal}
        onClose={() => setCustomModal(false)}
      >
        <div className="p-6">
          <div className="mb-5 w-full">
            <h3 className="text-base text-secondary-600 mb-3">
              {intl.formatMessage({
                id: "input.tokenName",
                defaultMessage: "Token Name"
              })}
            </h3>
            <InputField
              id="address"
              name="address"
              onChange={handleChange}
              value={customTokenAdd}
              placeholder={intl.formatMessage({
                id: "form.address",
                defaultMessage: "address"
              })}
            />
          </div>
          <Button
            disabled={!walletStore.isConnected}
            variant="primary"
            className={styles.title_bar_button}
            onClick={addCustomTokenTrigger}
          >
            {intl.formatMessage({
              id: "main_page.addToken",
              defaultMessage: "Add token"
            })}
          </Button>
        </div>
      </Modal>
      <div className="breadcrumbs">
        <Link
          className="breadcrumbs__link breadcrumbs__link--active"
          to={ROUTES.tokens.path}
        >
          {intl.formatMessage({
            id: "breadcrumbs.tokens",
            defaultMessage: "Tokens"
          })}
        </Link>
      </div>
      {tokenStore.tokensLoading ? (
        <div className="w-full flex items-center justify-center h-[50vh]">
          <Spinner className="max-w-[240px]" />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className="flex items-center mr-8 mb-2">
              {tokenStore.tokensData?.length > 0 ? (
                <div className="max-w-[180px]">
                  <TokenSelect
                    type="large"
                    list={tokenStore.tokensData}
                    value={getSelectTokenVal}
                    onChange={tokenChange}
                  />
                </div>
              ) : (
                <div className="w-full">
                  <h3 className="font-poppins font-medium text-3xl text-secondary-600">
                    {intl.formatMessage({
                      id: "main.noToken",
                      defaultMessage:
                        "You have not created any tokens yet VENOM"
                    })}
                  </h3>

                  <ul className="pl-4 mt-6 text-secondary-600 list-disc">
                    <li className="text-base mb-2">
                      {intl.formatMessage({
                        id: "main.noToken1",
                        defaultMessage:
                          "This is the management dashboard for all the tokens you create."
                      })}
                    </li>
                    <li className="text-base mb-2">
                      {intl.formatMessage({
                        id: "main.noToken2",
                        defaultMessage:
                          "Once you create your first token, the table below will display the corresponding data immediately. You will also be able to perform crucial token actions."
                      })}
                    </li>
                    <li className="text-base mb-2">
                      {intl.formatMessage({
                        id: "main.noToken2",
                        defaultMessage:
                          "You can create as many tokens as you want, so feel free to create your first one now"
                      })}
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {tokenStore.tokensData.length > 0 && (
              <Button
                disabled={!walletStore.isConnected}
                onClick={() => navigate("/tokens/create")}
                variant="primary"
                className={styles.title_bar_button}
              >
                {intl.formatMessage({
                  id: "main_page.mint",
                  defaultMessage: "Create new token"
                })}
              </Button>
            )}

            {tokenStore.tokensData?.length > 0 && (
              <Button
                disabled={!walletStore.isConnected}
                onClick={() => openModalToken()}
                variant="primary"
                className={classNames(styles.title_bar_button, "ml-4")}
              >
                {intl.formatMessage({
                  id: "main_page.custom",
                  defaultMessage: "Add custom token"
                })}
              </Button>
            )}
          </div>
          {tokenStore.tokensData?.length === 0 && (
            <Button
              disabled={!walletStore.isConnected}
              onClick={() => openModalToken()}
              variant="primary"
              className={classNames(styles.title_bar_button, "mb-6")}
            >
              {intl.formatMessage({
                id: "main_page.custom",
                defaultMessage: "Add custom token"
              })}
            </Button>
          )}

          <div className="page_separator" />
          <h3 className="font-poppins font-medium text-xl text-secondary-600">
            {intl.formatMessage({
              id: "main.tokenTitle",
              defaultMessage: "Token statistics"
            })}
          </h3>
          <p className="font-base text-secondary-600 mt-2 mb-8">
            {intl.formatMessage({
              id: "main.tokenTitle",
              defaultMessage:
                "To view the tokens you have created in the wallet, you need to manually add them by using the root address provided in the table below"
            })}
          </p>

          {tokenStore.createdAndFilteredUserTokens.length &&
          getSelectTokenVal ? (
            <div className={styles.table_row}>
              {filterArr(Object.entries(getSelectTokenVal)).map(
                ([key, val]) => (
                  <div className={styles.table_item} key={key}>
                    {key === "name" && (
                      <>
                        <span className="flex items-center text-white/50 text-sm mb-1">
                          {intl.formatMessage({
                            id: "main.tokensName",
                            defaultMessage: "Token name"
                          })}
                          <Tooltip
                            config={{ placement: "top-start" }}
                            content={
                              <span className="text-white">
                                {intl.formatMessage({
                                  id: "main.nameTooltip1",
                                  defaultMessage:
                                    "This name represents your token to users in the Venom blockchain"
                                })}
                              </span>
                            }
                          >
                            <InfoIcon className="ml-4" />
                          </Tooltip>
                        </span>
                        <Tooltip
                          config={{ placement: "top-start" }}
                          content={val}
                        >
                          <div className="text-ellipsis overflow-hidden max-w-[100px]">
                            {val}
                          </div>
                        </Tooltip>
                      </>
                    )}
                    {key === "symbol" && (
                      <>
                        <span className="flex items-center text-white/50 text-sm mb-1">
                          {intl.formatMessage({
                            id: "main.symbol",
                            defaultMessage: "Symbol"
                          })}
                          <Tooltip
                            config={{ placement: "top-start" }}
                            content={
                              <span className="text-sm text-white">
                                {intl.formatMessage({
                                  id: "main.nameTooltip2",
                                  defaultMessage:
                                    "A custom symbol for the token"
                                })}
                              </span>
                            }
                          >
                            <InfoIcon className="ml-4" />
                          </Tooltip>
                        </span>
                        <Tooltip
                          config={{ placement: "top-start" }}
                          content={val}
                        >
                          <div className="text-ellipsis overflow-hidden max-w-[150px]">
                            {val}
                          </div>
                        </Tooltip>
                      </>
                    )}
                    {key === "supply" && (
                      <>
                        <span className="flex items-center text-white/50 text-sm mb-1">
                          {intl.formatMessage({
                            id: "main.tokensIssued",
                            defaultMessage: "Tokens issued"
                          })}
                          <Tooltip
                            config={{ placement: "top-start" }}
                            content={
                              <span className="text-sm text-white">
                                {intl.formatMessage({
                                  id: "main.nameTooltip3",
                                  defaultMessage:
                                    "Number of tokens in circulation"
                                })}
                              </span>
                            }
                          >
                            <InfoIcon className="ml-4" />
                          </Tooltip>
                        </span>
                        <span>{val}</span>
                      </>
                    )}
                    {key === "decimals" && (
                      <>
                        <span className="flex items-center text-white/50 text-sm mb-1">
                          {intl.formatMessage({
                            id: "main.decimals",
                            defaultMessage: "Decimals"
                          })}
                          <Tooltip
                            config={{ placement: "top-start" }}
                            content={
                              <span className="text-sm text-white">
                                {intl.formatMessage({
                                  id: "main.nameTooltip4",
                                  defaultMessage:
                                    "Set the decimal precision for the token (up to 18), which is typically 9 in Venom"
                                })}
                              </span>
                            }
                          >
                            <InfoIcon className="ml-4" />
                          </Tooltip>
                        </span>
                        <span>{val}</span>
                      </>
                    )}
                    {key === "address" && (
                      <>
                        <span className="flex items-center text-white/50 text-sm mb-1">
                          {intl.formatMessage({
                            id: "main.root",
                            defaultMessage: "Root address"
                          })}
                          <Tooltip
                            config={{ placement: "top-start" }}
                            content={
                              <span className="text-sm text-white">
                                {intl.formatMessage({
                                  id: "main.nameTooltip5",
                                  defaultMessage:
                                    "The smart contract address that governs the token balance"
                                })}
                              </span>
                            }
                          >
                            <InfoIcon className="ml-4" />
                          </Tooltip>
                        </span>
                        <span className="flex items-center">
                          <a
                            className="text-green-100 underline hover:no-underline"
                            href={`${VENOM_SCAN}/accounts/${val}/holders`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {cutString(val, 5, 6)}
                          </a>
                          <CopyIcon
                            className="copy"
                            onClick={() => handleCopyAddress(val)}
                          />
                        </span>
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          ) : !tokenStore.tokensData.length ? (
            <div
              className={classNames(
                styles.table_row_placeholder,
                styles.table_row_placeholder_noborder,
                "text-white/60 text-base mb-16 px-8 py-8"
              )}
            >
              <Button
                disabled={!walletStore.isConnected}
                onClick={() => navigate("/tokens/create")}
                variant="primary"
                className={styles.title_bar_button}
              >
                {intl.formatMessage({
                  id: "main_page.mint",
                  defaultMessage: "Create new token"
                })}
              </Button>
            </div>
          ) : null}
          <h3 className="font-poppins font-medium text-xl text-secondary-600 mt-10">
            {intl.formatMessage({
              id: "main.tokenTitle2",
              defaultMessage: "Token actions"
            })}
          </h3>
          <p className="font-base text-secondary-600 mt-2 mb-8 mb-6">
            {intl.formatMessage({
              id: "main.tokenTitleActions",
              defaultMessage:
                "Make critical modifications to your token in seconds with just a few clicks"
            })}
          </p>

          <div className="flex flex-wrap">
            <div className="max-w-[360px] pr-8 mt-10">
              <h3 className="font-poppins font-medium text-base text-secondary-600 mb-6">
                {intl.formatMessage({
                  id: "main.tokenMint",
                  defaultMessage: "Mint"
                })}
              </h3>
              <p className="text-sm text-white/50 mt-4 mb-4">
                {intl.formatMessage({
                  id: "main_page.mintText",
                  defaultMessage:
                    "Issue additional tokens to increase the token's circulating supply"
                })}
              </p>
              <Button
                onClick={() =>
                  navigate(
                    `${ROUTES.tokens.mint.path}/${getSelectTokenVal?.address}`
                  )
                }
                variant="primary"
                disabled={!getSelectTokenVal}
              >
                {intl.formatMessage({
                  id: "main_page.mintAction",
                  defaultMessage: "Mint tokens"
                })}
              </Button>
            </div>
            <div className="max-w-[360px] mt-10">
              <h3 className="font-poppins font-medium text-base text-secondary-600 mb-6">
                {intl.formatMessage({
                  id: "main.tokenTransfer",
                  defaultMessage: "Transfer ownership"
                })}
              </h3>
              <p className="text-sm text-white/50 mt-4 mb-4">
                {intl.formatMessage({
                  id: "main_page.transferText",
                  defaultMessage:
                    "Set a new token owner quickly and easily to take over the token's management"
                })}
              </p>
              <Button
                onClick={() =>
                  navigate(`transfer/${getSelectTokenVal?.address}`)
                }
                variant="primary"
                disabled={checkForTransfer()}
              >
                {intl.formatMessage({
                  id: "main_page.transferBtn",
                  defaultMessage: "Transfer ownership"
                })}
              </Button>
            </div>
          </div>
          <div className="flex flex-wrap mt-10 mb-20 md:mb-0">
            <div className="max-w-[360px] pr-8 mt-10">
              <h3 className="font-poppins font-medium text-base text-secondary-600 mb-6">
                {intl.formatMessage({
                  id: "main.tokenBurn",
                  defaultMessage: "Burn"
                })}
              </h3>
              <p className="text-sm text-white/50 mt-4 mb-4">
                {intl.formatMessage({
                  id: "main_page.burnText",
                  defaultMessage:
                    "Burn additional tokens to decrease the token's circulating supply"
                })}
              </p>
              {(!getSelectTokenVal || +getSelectTokenVal.supply === 0) &&
              walletStore.isConnected ? (
                <Tooltip
                  config={{ placement: "top-start" }}
                  content={
                    <span className="text-white">
                      {intl.formatMessage({
                        id: "main.burnTooltip",
                        defaultMessage:
                          "To burn tokens, they must first be minted."
                      })}
                    </span>
                  }
                >
                  <Button
                    onClick={() =>
                      navigate(
                        `${ROUTES.tokens.burn.path}/${getSelectTokenVal?.address}`
                      )
                    }
                    variant="primary"
                    disabled={
                      !getSelectTokenVal || +getSelectTokenVal.supply === 0
                    }
                  >
                    {intl.formatMessage({
                      id: "main_page.burnAction",
                      defaultMessage: "Burn tokens"
                    })}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={() =>
                    navigate(
                      `${ROUTES.tokens.burn.path}/${getSelectTokenVal?.address}`
                    )
                  }
                  variant="primary"
                  disabled={
                    !getSelectTokenVal || +getSelectTokenVal.supply === 0
                  }
                >
                  {intl.formatMessage({
                    id: "main_page.burnAction",
                    defaultMessage: "Burn tokens"
                  })}
                </Button>
              )}
            </div>
            <div className="max-w-[360px] mt-10">
              <h3 className="font-poppins font-medium text-base text-secondary-600 mb-6">
                {intl.formatMessage({
                  id: "main.tokenHolders",
                  defaultMessage: "See all token holders"
                })}
              </h3>
              <p className="text-sm text-white/50 mt-4 mb-4">
                {intl.formatMessage({
                  id: "main_page.holdersText",
                  defaultMessage:
                    "You can view the total number of token owners and their corresponding addresses"
                })}
              </p>
              <a
                className={!getSelectTokenVal ? "pointer-events-none" : ""}
                target="_blank"
                rel="noopener noreferrer"
                href={`${VENOM_SCAN}/accounts/${getSelectTokenVal?.address.toString()}/holders`}
              >
                <Button variant="primary" disabled={!getSelectTokenVal}>
                  {intl.formatMessage({
                    id: "main_page.tokenHolders",
                    defaultMessage: "See token holders"
                  })}
                  <ExternalIcon className={styles.external} />
                </Button>
              </a>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default observer(MainPage);
