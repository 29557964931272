export const Drop3FactoryAbi = {
  "ABI version": 2,
  version: "2.2",
  header: ["pubkey", "time", "expire"],
  functions: [
    {
      name: "constructor",
      inputs: [
        { name: "_linearVestingCode", type: "cell" },
        { name: "_airdropCode", type: "cell" },
        { name: "_linearVestingVersion", type: "uint16" },
        { name: "_airdropVersion", type: "uint16" },
        { name: "_rootAdmin", type: "address" },
        { name: "tokenRoot", type: "optional(address)" }
      ],
      outputs: []
    },
    {
      name: "getDeployAirdropValue",
      inputs: [{ name: "length", type: "uint128" }],
      outputs: [{ name: "value", type: "uint128" }]
    },
    {
      name: "getAirdropAddress",
      inputs: [
        { name: "nonce", type: "uint128" },
        { name: "version", type: "uint16" },
        { name: "name", type: "string" }
      ],
      outputs: [{ name: "airdrop", type: "address" }]
    },
    {
      name: "getAirdropsDeployed",
      inputs: [],
      outputs: [{ name: "airdrop_deployed", type: "uint128" }]
    },
    {
      name: "getAirdropVersion",
      inputs: [],
      outputs: [{ name: "airdrop_version", type: "uint128" }]
    },
    {
      name: "updateLinearVestingCode",
      inputs: [{ name: "code", type: "cell" }],
      outputs: []
    },
    {
      name: "updateLinearVestingBatch",
      inputs: [
        { name: "addrs", type: "address[]" },
        { name: "send_gas_to", type: "address" }
      ],
      outputs: []
    },
    {
      name: "updateAirdropCode",
      inputs: [{ name: "code", type: "cell" }],
      outputs: []
    },
    {
      name: "updateAirdropBatch",
      inputs: [
        { name: "addrs", type: "address[]" },
        { name: "send_gas_to", type: "address" }
      ],
      outputs: []
    },
    {
      name: "deployVesting",
      inputs: [
        { name: "name", type: "string" },
        { name: "user", type: "address" },
        { name: "token", type: "address" },
        { name: "wever_token", type: "address" },
        { name: "wever_vault", type: "address" },
        { name: "vesting_amount", type: "uint128" },
        { name: "vesting_start", type: "uint32" },
        { name: "vesting_end", type: "uint32" }
      ],
      outputs: []
    },
    {
      name: "deployAirdrop",
      inputs: [
        { name: "name", type: "string" },
        { name: "refund_destination", type: "address" },
        { name: "refund_lock_duration", type: "uint256" },
        { name: "token_root_address", type: "address" },
        { name: "recipients", type: "address[]" },
        { name: "amounts", type: "uint128[]" },
        { name: "airdropType", type: "uint8" }
      ],
      outputs: []
    },
    {
      name: "onVestingDeployed",
      inputs: [
        { name: "nonce", type: "uint128" },
        { name: "user", type: "address" },
        { name: "creator", type: "address" },
        { name: "token", type: "address" },
        { name: "wever_token", type: "address" },
        { name: "wever_vault", type: "address" },
        { name: "vesting_amount", type: "uint128" },
        { name: "vesting_start", type: "uint32" },
        { name: "vesting_end", type: "uint32" }
      ],
      outputs: []
    },
    {
      name: "onAirdropDeployed",
      inputs: [
        { name: "nonce", type: "uint128" },
        { name: "version", type: "uint16" },
        { name: "name", type: "string" },
        { name: "token", type: "address" }
      ],
      outputs: []
    },
    {
      name: "wEverOccur",
      inputs: [
        { name: "amount", type: "uint128" },
        { name: "payload", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "onAcceptTokensTransfer",
      inputs: [
        { name: "tokenRoot", type: "address" },
        { name: "amount", type: "uint128" },
        { name: "sender", type: "address" },
        { name: "value3", type: "address" },
        { name: "remainingGasTo", type: "address" },
        { name: "payload", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "upgrade",
      inputs: [
        { name: "new_code", type: "cell" },
        { name: "send_gas_to", type: "address" }
      ],
      outputs: []
    },
    {
      name: "owner",
      inputs: [],
      outputs: [{ name: "owner", type: "address" }]
    },
    {
      name: "linearVestingVersion",
      inputs: [],
      outputs: [{ name: "linearVestingVersion", type: "uint16" }]
    },
    {
      name: "airdropVersion",
      inputs: [],
      outputs: [{ name: "airdropVersion", type: "uint16" }]
    },
    {
      name: "rootAdmin",
      inputs: [],
      outputs: [{ name: "rootAdmin", type: "address" }]
    },
    {
      name: "vestings_deployed",
      inputs: [],
      outputs: [{ name: "vestings_deployed", type: "uint128" }]
    },
    {
      name: "airdrops_deployed",
      inputs: [],
      outputs: [{ name: "airdrops_deployed", type: "uint128" }]
    }
  ],
  data: [
    { key: 1, name: "root", type: "address" },
    { key: 2, name: "owner", type: "address" }
  ],
  events: [
    {
      name: "NewLinearVesting",
      inputs: [
        { name: "vesting", type: "address" },
        { name: "user", type: "address" },
        { name: "creator", type: "address" },
        { name: "token", type: "address" },
        { name: "wever_token", type: "address" },
        { name: "wever_vault", type: "address" },
        { name: "amount", type: "uint128" },
        { name: "start", type: "uint32" },
        { name: "end", type: "uint32" }
      ],
      outputs: []
    },
    {
      name: "NewAirdrop",
      inputs: [
        { name: "airdrop", type: "address" },
        { name: "creator", type: "address" },
        { name: "token", type: "address" }
      ],
      outputs: []
    },
    {
      name: "LinearVestingCodeUpdate",
      inputs: [{ name: "new_version", type: "uint16" }],
      outputs: []
    },
    {
      name: "AirdropCodeUpdate",
      inputs: [{ name: "new_version", type: "uint16" }],
      outputs: []
    }
  ],
  fields: [
    { name: "_pubkey", type: "uint256" },
    { name: "_timestamp", type: "uint64" },
    { name: "_constructorFlag", type: "bool" },
    { name: "root", type: "address" },
    { name: "owner", type: "address" },
    { name: "linearVestingCode", type: "cell" },
    { name: "airdropCode", type: "cell" },
    { name: "linearVestingVersion", type: "uint16" },
    { name: "airdropVersion", type: "uint16" },
    { name: "rootAdmin", type: "address" },
    { name: "vestings_deployed", type: "uint128" },
    { name: "airdrops_deployed", type: "uint128" },
    { name: "_wallets", type: "map(address,address)" },
    { name: "_vestingAmounts", type: "map(address,uint128)" },
    { name: "_weverVestingAmounts", type: "map(address,uint128)" },
    { name: "_airdropAmounts", type: "map(address,uint128)" },
    { name: "_airdropWeverAmounts", type: "map(address,uint128)" }
  ]
} as const;
