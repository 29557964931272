import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useIntl } from "react-intl";
import styles from "./index.module.css";
import {
  useTokenStore,
  useAirdropStore,
  useNotifyStore
} from "@/providers/BaseStoresProvider";
import ROUTES from "@/routes";
import Container from "@/components/core/container";
import { observer } from "mobx-react-lite";
import { Address } from "everscale-inpage-provider";
import { useWallet } from "@/providers/BaseStoresProvider";
import BreadCrumbs from "@/components/common/breadcrumbs";
import { convertAmount } from "@/utils/BN";
import { unixTsToDateStr } from "@/utils/dates";
import { cutString } from "@/utils/strings";
import { Spinner } from "@/components/common/spinner";
import Tooltip from "@/components/common/tooltip";

import copy from "copy-to-clipboard";
import { ReactComponent as CopyIcon } from "@/assets/icons/copy.svg";

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";
import { VENOM_SCAN } from "@/utils/constants";

interface IProps {}

const AirdropDetailedPage: React.FC<IProps> = () => {
  const [getAirdrop, setCurrentAirdrop] = useState<any | null>(null);
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState<boolean>(true);
  const intl = useIntl();
  const noficiations = useNotifyStore();

  const walletStore = useWallet();
  const tokenStore = useTokenStore();
  const airdropStore = useAirdropStore();

  const breadLinks = [
    {
      name: intl.formatMessage({
        id: "breadcrumbs.airdrop",
        defaultMessage: "Airdrop"
      }),
      active: false,
      link: ROUTES.airdrop.path
    },
    {
      name: intl.formatMessage({
        id: "breadcrumbs.airdropDetails",
        defaultMessage: "Airdrop Details"
      }),
      active: true,
      link: `${ROUTES.airdrop.path}/${contractId}`
    }
  ];

  const handleCopyAddress = (address: string) => {
    address && copy(address);
    noficiations.notify("", {
      type: "success",
      title: "Address was copied"
    });
  };

  useEffect(() => {
    const findAirdrop = () => {
      const curAirdrop = airdropStore.allUserAirdrops.find(
        (item) => item.contract.toString() === contractId
      );
      setLoading(false);
      setCurrentAirdrop(curAirdrop);
    };
    if (airdropStore.allUserAirdrops?.length > 0) findAirdrop();
    if (!walletStore.isConnected) {
      tokenStore.resetStore();
      return;
    }
    const reqFactoryState = async () => {
      await airdropStore.getAllAidrops();
      findAirdrop();
    };
    reqFactoryState();
  }, [tokenStore, walletStore.isConnected, airdropStore, contractId]);

  return (
    <Container className="w-8/12 pr-4 mt-2 pl-1">
      <BreadCrumbs list={breadLinks} />
      {getAirdrop?.name && (
        <div className={styles.header}>
          <span className="font-poppins font-semibold text-3xl text-white mr-8">
            {getAirdrop?.name}
          </span>
        </div>
      )}
      {isLoading ? (
        <div className="w-full flex items-center justify-center mt-44">
          <Spinner className={styles.spinner} />
        </div>
      ) : (
        <>
          <div className="my-8 lg:w-5/12">
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "airdrop.details.token",
                  defaultMessage: "Token"
                })}
              </span>
              <span className="flex items-center font-sm text-white mr-5">
                <img
                  alt="logo"
                  src="/venom_logo.svg"
                  className="mr-2 w-5 h-5"
                />
                {getAirdrop.symbol?.length > 10 ? (
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={getAirdrop.symbol}
                  >
                    <span>{cutString(getAirdrop.symbol.toString(), 5, 4)}</span>
                  </Tooltip>
                ) : (
                  getAirdrop.symbol
                )}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "airdrop.details.amount",
                  defaultMessage: "Amount"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {convertAmount(getAirdrop?.total_amount, getAirdrop?.decimals)}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "airdrop.details.tokenAddress",
                  defaultMessage: "Token address"
                })}
              </span>
              <div className="flex items-center">
                <span className="font-sm text-white mr-2">
                  {cutString(getAirdrop?.token?.toString(), 5, 4)}
                </span>
                <CopyIcon
                  className="copy mr-4"
                  onClick={() => handleCopyAddress(getAirdrop?.token)}
                />
              </div>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "airdrop.details.date",
                  defaultMessage: "Created at"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {unixTsToDateStr(getAirdrop?.date, "DD.MM.YYYY HH:mm")}
              </span>
            </div>
          </div>
          <div className="font-poppins font-medium text-xl text-white mt-2 mb-4">
            {intl.formatMessage({
              id: "aidrop.recipients",
              defaultMessage: "Recipients"
            })}
          </div>

          <table className="table-bordered table-bordered--nohover">
            <thead className="text-2xl">
              <tr>
                <th>
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={
                      <span className="text-sm text-white">
                        {intl.formatMessage({
                          id: "airdrop.detailed.nameTooltip1",
                          defaultMessage: "Recipients address"
                        })}
                      </span>
                    }
                  >
                    <div className="flex items-center">
                      <span>
                        {intl.formatMessage({
                          id: "airdrop.table.address",
                          defaultMessage: "ADDRESS"
                        })}
                      </span>
                      <InfoIcon className="ml-4" />
                    </div>
                  </Tooltip>
                </th>
                <th>
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={
                      <span className="text-sm text-white">
                        {intl.formatMessage({
                          id: "airdrop.detailed.nameTooltip2",
                          defaultMessage:
                            "The number of tokens that are subject to an airdrop"
                        })}
                      </span>
                    }
                  >
                    <div className="flex items-center">
                      <span>
                        {intl.formatMessage({
                          id: "airdrop.table.tokens",
                          defaultMessage: "TOKENS"
                        })}
                      </span>
                      <InfoIcon className="ml-4" />
                    </div>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody className="table-bordered__body">
              {getAirdrop &&
                getAirdrop.airdrop_recipients?.map(
                  (user: Address, index: number) => (
                    <tr key={index}>
                      <td>
                        <a
                          className="text-green-100 underline hover:no-underline"
                          href={`${VENOM_SCAN}/accounts/${user.toString()}/holders`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user?.toString()}
                        </a>
                      </td>
                      <td>
                        <div className="flex">
                          <span className="mr-1">
                            {convertAmount(
                              getAirdrop.airdrop_amounts[index],
                              getAirdrop.decimals
                            )}
                          </span>
                          {getAirdrop?.symbol?.length > 10 ? (
                            <div className="text-ellipsis overflow-hidden max-w-[150px]">
                              <Tooltip
                                config={{ placement: "top-start" }}
                                content={getAirdrop?.symbol}
                              >
                                <span>
                                  {cutString(
                                    getAirdrop?.symbol.toString(),
                                    5,
                                    4
                                  )}
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            <span>{getAirdrop?.symbol}</span>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </>
      )}
    </Container>
  );
};

export default observer(AirdropDetailedPage);
