import React, { CSSProperties } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { Config } from "react-popper-tooltip/dist/types";
import styles from "./index.module.css";

interface IProps {
  content: string | JSX.Element;
  config?: Config;
  fixed?: boolean;
  containerStyles?: CSSProperties;
  children: any;
}

const Tooltip: React.FC<IProps> = ({
  containerStyles,
  children,
  content,
  config
}) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({ ...config });
  return (
    <div className={styles.container}>
      <div
        ref={setTriggerRef}
        style={{ cursor: "pointer", ...containerStyles }}
      >
        {children}
      </div>
      {visible && (
        <div className={styles.root} ref={setTooltipRef} {...getTooltipProps()}>
          {content}
        </div>
      )}
    </div>
  );
};
export default Tooltip;
