/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** contract address */
export type Address = string;

/**
 * unix timestamp
 * @format int32
 */
export type Ts = number;

export interface Profile {
  /** contract address */
  address: Address;
  /** unix timestamp */
  created: Ts;
  /** @maxLength 100 */
  name: string;
  bio?: string;
  /** @maxLength 100 */
  email?: string;
  /** @maxLength 100 */
  site?: string;
  /** @maxLength 100 */
  twitter?: string;
}

export interface Price {
  /** contract address */
  priceToken: Address;
  price: string;
  usdPrice?: string;
}

export interface NftPrice {
  price: string;
  /** contract address */
  priceToken?: Address;
  /** unix timestamp */
  ts: Ts;
}

export interface AuctionBid {
  /** contract address */
  from: Address;
  /** contract address */
  auction: Address;
  /** contract address */
  nft: Address;
  price: string;
  usdPrice?: string;
  /** unix timestamp */
  createdAt: Ts;
  active?: boolean;
}

export interface DirectBuy {
  /** contract address */
  address: Address;
  /** contract address */
  buyer: Address;
  /** contract address */
  nft: Address;
  price: Price;
  /** unix timestamp */
  createdAt: Ts;
  /** unix timestamp */
  expiredAt?: Ts;
  /** unix timestamp */
  finishedAt?: Ts;
  status?: DirectBuyStatus;
}

export enum DirectBuyStatus {
  Create = 'create',
  AwaitTokens = 'await_tokens',
  Active = 'active',
  Filled = 'filled',
  Cancelled = 'cancelled',
  Expired = 'expired',
}

export interface DirectSell {
  /** contract address */
  address: Address;
  /** contract address */
  buyer?: Address;
  /** contract address */
  seller: Address;
  /** contract address */
  nft: Address;
  price: Price;
  /** unix timestamp */
  createdAt: Ts;
  /** unix timestamp */
  finishedAt?: Ts;
  /** unix timestamp */
  expiredAt?: Ts;
  status?: DirectSellStatus;
}

export enum DirectSellStatus {
  Create = 'create',
  AwaitNft = 'await_nft',
  Active = 'active',
  Cancelled = 'cancelled',
  Expired = 'expired',
}

export interface Auction {
  /** contract address */
  address: Address;
  /** contract address */
  nft: Address;
  /** contract address */
  bidToken: Address;
  /** contract address */
  walletForBids?: Address;
  startBid?: string;
  startUsdBid?: string;
  maxBid?: string;
  minBid?: string;
  maxUsdBid?: string;
  minUsdBid?: string;
  /** unix timestamp */
  startTime?: Ts;
  /** unix timestamp */
  finishTime?: Ts;
  status?: AuctionStatus;
  /** contract address */
  lastBidFrom?: Address;
  /** unix timestamp */
  lastBidTime?: Ts;
  lastBidValue?: string;
  lastBidUsdValue?: string;
}

export enum AuctionStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Expired = 'expired',
}

export interface Contract {
  /** contract address */
  address: Address;
  name: string;
  description?: string;
  /** contract address */
  owner: Address;
  verified?: boolean;
}

export type Nft = Contract & {
  type?: string;
  /** @format uri */
  image: string;
  mimetype?: string;
  /** contract address */
  collection: Address;
  attributes: object[];
  currentPrice?: Price;
  lastPrice?: Price;
  /** contract address */
  auction?: Address;
  /** contract address */
  forsale?: Address;
  /** contract address */
  bestOffer?: Address;
  /** contract address */
  manager?: Address;
};

export type Collection = Contract & {
  verified?: boolean;
  /** unix timestamp */
  createdAt: Ts;
  /** @format uri */
  wallpaper?: string;
  /** @format uri */
  logo?: string;
  lowestPrice?: string;
  totalPrice?: string;
  /** @format int32 */
  ownersCount: number;
  /** @format int32 */
  nftCount?: number;
};

export enum EventType {
  AuctionDeployed = 'auction_deployed',
  AuctionCreated = 'auction_created',
  AuctionRootOwnershipTransferred = 'auction_root_ownership_transferred',
  AuctionActive = 'auction_active',
  AuctionDeclined = 'auction_declined',
  AuctionBidPlaced = 'auction_bid_placed',
  AuctionBidDeclined = 'auction_bid_declined',
  AuctionCancelled = 'auction_cancelled',
  AuctionComplete = 'auction_complete',
  DirectBuyDeployed = 'direct_buy_deployed',
  DirectBuyDeclined = 'direct_buy_declined',
  FactoryDirectBuyOwnershipTransferred = 'factory_direct_buy_ownership_transferred',
  DirectBuyStateChanged = 'direct_buy_state_changed',
  DirectSellDeployed = 'direct_sell_deployed',
  DirectSellDeclined = 'direct_sell_declined',
  FactoryDirectSellOwnershipTransferred = 'factory_direct_sell_ownership_transferred',
  DirectSellStateChanged = 'direct_sell_state_changed',
  NftOwnerChanged = 'nft_owner_changed',
  NftManagerChanged = 'nft_manager_changed',
  CollectionOwnershipTransferred = 'collection_ownership_transferred',
  NftCreated = 'nft_created',
  NftBurned = 'nft_burned',
}

export enum EventCategory {
  Auction = 'auction',
  DirectBuy = 'direct_buy',
  DirectSell = 'direct_sell',
}

export interface Event {
  /** @format int64 */
  id: number;
  type: EventType;
  cat: EventCategory;
  /** contract address */
  address: Address;
  /** unix timestamp */
  ts: Ts;
  args?: object;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://indexer-api.bf.works' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title nft indexer api
 * @version 0.1
 * @baseUrl https://indexer-api.bf.works
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  search = {
    /**
     * No description
     *
     * @name SearchEverything
     * @request POST:/search
     */
    searchEverything: (data: Address, params: RequestParams = {}) =>
      this.request<
        {
          count?: number;
          items?: {
            /** @format uri */
            image?: string;
            address: string;
            contractType: 'nft' | 'collection' | 'auction' | 'directBuy' | 'directSell';
          }[];
        },
        any
      >({
        path: `/search`,
        method: 'POST',
        body: data,
        type: ContentType.Text,
        format: 'json',
        ...params,
      }),
  };
  collections = {
    /**
     * No description
     *
     * @name ListCollections
     * @request POST:/collections
     */
    listCollections: (
      data?: {
        name?: string;
        owners?: Address[];
        collections?: Address[];
        /** @default true */
        verified?: boolean;
        limit?: number;
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: Collection[];
        },
        any
      >({
        path: `/collections`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ByOwner
     * @request POST:/collections/by-owner
     */
    byOwner: (
      data: {
        /** contract address */
        owner?: Address;
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: Collection[];
        },
        any
      >({
        path: `/collections/by-owner`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  nft = {
    /**
     * No description
     *
     * @name GetNftDirectBuy
     * @request POST:/nft/direct/buy
     */
    getNftDirectBuy: (
      data: {
        /** contract address */
        nft?: Address;
        status?: DirectBuyStatus[];
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          items?: DirectBuy[];
          totalCount?: number;
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
        },
        any
      >({
        path: `/nft/direct/buy`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetNftPriceHistory
     * @request POST:/nft/price-history
     */
    getNftPriceHistory: (
      data: {
        /** contract address */
        nft?: Address;
        /**
         * periods by days/hours
         * @default "d"
         */
        scale?: 'd' | 'h';
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: {
            usdPrice: string;
            /** unix timestamp */
            ts: Ts;
          }[];
        },
        any
      >({
        path: `/nft/price-history`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name Details
     * @request POST:/nft/details
     */
    details: (
      data: {
        /** contract address */
        nft?: Address;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          nft?: Nft;
          auction?: Record<string, Auction>;
          directSell?: Record<string, DirectSell>;
          directBuy?: Record<string, DirectBuy>;
          collection?: Record<string, Collection>;
        },
        any
      >({
        path: `/nft/details`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ReloadMeta
     * @request POST:/nft/reload-meta
     */
    reloadMeta: (
      data: {
        /** contract address */
        nft?: Address;
      },
      params: RequestParams = {}
    ) =>
      this.request<Nft, any>({
        path: `/nft/reload-meta`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  collection = {
    /**
     * No description
     *
     * @name Details
     * @request POST:/collection/details
     */
    details: (
      data: {
        /** contract address */
        collection?: Address;
      },
      params: RequestParams = {}
    ) =>
      this.request<Collection, any>({
        path: `/collection/details`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  nfts = {
    /**
     * No description
     *
     * @name ListNft
     * @request POST:/nfts/
     */
    listNft: (
      data: {
        owners?: Address[];
        collections?: Address[];
        /** contract address */
        priceToken?: Address;
        /** @format int64 */
        priceFrom?: number;
        /** @format int64 */
        priceTo?: number;
        /** @default true */
        verified?: boolean;
        forsale?: boolean;
        auction?: boolean;
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: Nft[];
          collection?: Record<string, Collection>;
          auction?: Record<string, Auction>;
          directSell?: Record<string, DirectSell>;
          directBuy?: Record<string, DirectBuy>;
        },
        any
      >({
        path: `/nfts/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  auction = {
    /**
     * No description
     *
     * @name GetAuction
     * @request POST:/auction
     */
    getAuction: (
      data: {
        /** contract address */
        auction?: Address;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          auction: Auction;
          bid?: AuctionBid;
          nft: Record<string, Nft>;
          collection?: Record<string, Collection>;
        },
        any
      >({
        path: `/auction`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ListAuctionBids
     * @request POST:/auction/bids
     */
    listAuctionBids: (
      data: {
        /** contract address */
        auction?: Address;
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: AuctionBid[];
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
          auction?: Record<string, Auction>;
        },
        any
      >({
        path: `/auction/bids`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  auctions = {
    /**
     * No description
     *
     * @name ListAuctions
     * @request POST:/auctions
     */
    listAuctions: (
      data: {
        owners?: Address[];
        collections?: Address[];
        tokens?: Address[];
        /** @default "start-date" */
        sort?: 'start-date' | 'bids-count' | 'average' | 'average-in-hour' | 'average-in-day';
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: Auction[];
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
        },
        any
      >({
        path: `/auctions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  owner = {
    /**
     * No description
     *
     * @name BidsOut
     * @request POST:/owner/bids-out
     */
    bidsOut: (
      data: {
        /** contract address */
        owner?: Address;
        collections?: Address[];
        lastbid?: boolean;
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: AuctionBid[];
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
          auction?: Record<string, Auction>;
        },
        any
      >({
        path: `/owner/bids-out`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name BidsIn
     * @request POST:/owner/bids-in
     */
    bidsIn: (
      data: {
        /** contract address */
        owner?: Address;
        collections?: Address[];
        active?: boolean;
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: AuctionBid[];
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
          auction?: Record<string, Auction>;
        },
        any
      >({
        path: `/owner/bids-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DirectBuy
     * @request POST:/owner/direct/buy
     */
    directBuy: (
      data: {
        /** contract address */
        owner?: Address;
        collections?: Address[];
        status?: DirectBuyStatus[];
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: DirectBuy[];
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
        },
        any
      >({
        path: `/owner/direct/buy`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DirectBuyIn
     * @request POST:/owner/direct/buy-in
     */
    directBuyIn: (
      data: {
        /** contract address */
        owner?: Address;
        collections?: Address[];
        status?: DirectBuyStatus[];
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: DirectBuy[];
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
        },
        any
      >({
        path: `/owner/direct/buy-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DirectSell
     * @request POST:/owner/direct/sell
     */
    directSell: (
      data: {
        /** contract address */
        owner?: Address;
        collections?: Address[];
        status?: DirectBuyStatus[];
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: DirectSell[];
          nft?: Record<string, Nft>;
          collection?: Record<string, Collection>;
        },
        any
      >({
        path: `/owner/direct/sell`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @name ListEvents
     * @request POST:/events
     */
    listEvents: (
      data: {
        /** contract address */
        nft?: Address;
        /** contract address */
        collection?: Address;
        /** contract address */
        owner?: Address;
        type?: EventType[];
        /** @default 100 */
        limit?: number;
        /** @default 0 */
        offset?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          totalCount: number;
          items: Event[];
        },
        any
      >({
        path: `/events`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
