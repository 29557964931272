import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { useTokenStore, useWallet } from "@/providers/BaseStoresProvider";
import styles from "./index.module.css";
import { cutString } from "@/utils/strings";
import { validateTokenForm } from "@/utils/validate";
import ROUTES from "@/routes";

import { TokenModel, EchainId, TOKEN_ACTION_TYPES } from "@/models/TokenModel";
import { useFormik } from "formik";

import InputField from "@/components/core/input-field";
import { SpinnerDots } from "@/components/common/spinner";
import BreadCrumbs from "@/components/common/breadcrumbs";
import Checkbox from "@/components/core/checkbox";
import Button from "@/components/core/button";
import Container from "@/components/core/container";
import Tooltip from "@/components/common/tooltip";

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";

interface IProps {
  type: TOKEN_ACTION_TYPES;
}

const TokenFormPage: React.FC<IProps> = ({ type }) => {
  const [enableValidation, setEnableValidation] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [getFormToken, setFormToken] = useState<TokenModel | null>(null);
  const { tokenId } = useParams();
  const walletStore = useWallet();
  const tokenStore = useTokenStore();
  const intl = useIntl();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      symbol: "",
      supply: "",
      newAddress: "",
      targetAddress: walletStore.account?.address.toString() ?? "",
      initialSupply: "0",
      decimals: "9"
    },
    validateOnBlur: enableValidation,
    validateOnChange: enableValidation,
    validate: (val) => validateTokenForm(val, type),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        console.log("SUBMIT__");
        const resp = await tokenStore.createNewToken(values);
        if (resp) navigate(ROUTES.tokens.path);
        if (!resp) setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  });

  const getTitle = (type: TOKEN_ACTION_TYPES) => {
    if (type === "transfer") {
      return "Transfer ownership";
    }

    return `Token ${type}`;
  };

  const breadLinks = [
    {
      name: intl.formatMessage({
        id: "breadcrumbs.tokens",
        defaultMessage: "Tokens"
      }),
      active: false,
      link: ROUTES.tokens.path
    },
    {
      name: getTitle(type),
      active: true,
      link: `${ROUTES.tokens.path}/${type}`
    }
  ];

  const handleTransfer = async () => {
    setEnableValidation(true);
    await formik.validateForm();
    if (formik.errors.newAddress) return;

    setLoading(true);

    try {
      if (!tokenId) return;
      await tokenStore.transferTokenOwnership(
        tokenId,
        formik.values.newAddress
      );
      await tokenStore.loadUserTokens();
      navigate(ROUTES.tokens.path);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSupplyOrBurn = async (isBurn: boolean) => {
    console.log(isBurn, "handleSupplyOrBurn");
    setEnableValidation(true);
    formik.validateForm();
    console.log(isBurn, tokenId, getFormToken, "handleSupplyOrBurn2");
    if (!formik.values.supply || formik.values.supply === "0") return;
    if (!tokenId) return;
    setLoading(true);

    try {
      const loadBalance = async () => {
        if (!walletStore.account?.address) return;
        return await walletStore.getTokenBalance(
          {
            address: tokenId,
            name: getFormToken ? getFormToken.name : formik.values.name,
            decimals: getFormToken ? +getFormToken.decimals : 9,
            symbol: getFormToken ? getFormToken.symbol : formik.values.symbol,
            logoURI: "",
            verified: true,
            supply: walletStore.account.publicKey,
            chainId: EchainId.first
          },
          walletStore.account.address
        );
      };
      const bMintBal = await loadBalance();
      if (!isBurn) {
        await tokenStore.mintNewSupply(
          tokenId,
          formik.values.supply,
          getFormToken ? getFormToken.decimals : 9,
          formik.values.targetAddress
        );
      } else {
        await tokenStore.burnSupply(
          tokenId,
          formik.values.supply,
          getFormToken ? getFormToken.decimals : 9,
          formik.values.targetAddress
        );
      }
      const intervalId = setInterval(async () => {
        const aMintBal = await loadBalance();
        if (!bMintBal || !aMintBal) return;
        if (!getFormToken) return;

        if (!bMintBal.eq(aMintBal)) {
          const token = {
            ...getFormToken,
            supply: aMintBal.toString()
          };
          setFormToken(token);
          clearInterval(intervalId);
          await tokenStore.loadUserTokens();
          setLoading(false);

          navigate(ROUTES.tokens.path);
        }
      }, 5000);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tokenId && !getFormToken) {
      const token = tokenStore.tokensData.find((_) => _.address === tokenId);

      if (token) setFormToken(token);
      else {
        const reqToken = async () =>
          setFormToken(await tokenStore.getTokenData(tokenId, true, true));
        reqToken();
      }
    }

    if (!tokenId) setFormToken(null);
  }, [tokenId, tokenStore, getFormToken]);

  const handleBack = (e: any) => {
    const event = e.target.type;
    if (event === "submit") return;
    else navigate(ROUTES.tokens.path);
  };

  return (
    <Container className="w-full md:w-10/12 pr-4 mt-2 pl-1">
      <BreadCrumbs list={breadLinks} />
      <div className={styles.header}>
        <div className={styles.header_text}>
          <h3 className="font-poppins font-semibold text-3xl text-white">
            {getTitle(type)}
          </h3>
        </div>
        {type === "transfer" && (
          <h3 className="font-poppins font-medium text-base text-white mb-8">
            <span className="font-poppins font-medium text-base text-danger-600">
              Important note:{" "}
            </span>
            {intl.formatMessage({
              id: "input.tokenName",
              defaultMessage:
                "Please be aware that this action is irreversible and will permanently remove the token from your dashboard. We highly recommend double-checking before proceeding with this crucial step."
            })}
          </h3>
        )}
        <form
          className="mb-12 w-full"
          onSubmit={(event) => {
            setEnableValidation(true);
            event.preventDefault();
            formik.handleSubmit();
          }}
        >
          <div className="flex flex-wrap justify-between w-full md:w-10/12">
            <div className="mb-5 w-full md:w-6/12">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.tokenName",
                  defaultMessage: "Token Name"
                })}
              </h3>
              <InputField
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={getFormToken?.name ?? formik.values.name}
                placeholder={intl.formatMessage({
                  id: "form.name",
                  defaultMessage: "name"
                })}
                disabled={!!getFormToken?.name}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.name ?? null}
              </span>
            </div>

            {type === "create" && (
              <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                {intl.formatMessage({
                  id: "tokenForm.nameExplanation",
                  defaultMessage:
                    "This name will not be changed and will represent your tokens to users in the whole Venom blockchain."
                })}
              </p>
            )}
          </div>
          <div className="flex flex-wrap justify-between w-full md:w-10/12">
            <div className="mb-5 w-full md:w-6/12">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.symbol",
                  defaultMessage: "Token Symbol"
                })}
              </h3>
              <InputField
                id="symbol"
                name="symbol"
                onChange={formik.handleChange}
                value={getFormToken?.symbol ?? formik.values.symbol}
                placeholder={intl.formatMessage({
                  id: "input.symbol",
                  defaultMessage: "symbol"
                })}
                disabled={!!getFormToken?.symbol}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.symbol ?? null}
              </span>
            </div>

            {type === "create" && (
              <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                {intl.formatMessage({
                  id: "tokenForm.symbolText",
                  defaultMessage:
                    "Select a custom symbol for the token, typically consisting of 3 to 5 characters"
                })}
              </p>
            )}
          </div>
          {["burn", "mint"].includes(type) && (
            <>
              <div className="flex flex-wrap justify-between w-full md:w-10/12">
                <div className="w-full md:w-6/12 mb-5">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {type === "mint"
                      ? intl.formatMessage({
                          id: "form.mint",
                          defaultMessage: "Mint"
                        })
                      : intl.formatMessage({
                          id: "form.burn",
                          defaultMessage: "Burn"
                        })}
                  </h3>
                  {/* supply field using for burn also */}
                  <InputField
                    id="supply"
                    name="supply"
                    type="number"
                    decimals={getFormToken?.decimals}
                    onChange={formik.handleChange}
                    value={formik.values.supply}
                    placeholder={
                      type === "mint"
                        ? intl.formatMessage({
                            id: "input.supplyPlaceholder",
                            defaultMessage: "Amount to supply"
                          })
                        : intl.formatMessage({
                            id: "input.burnPlaceholder",
                            defaultMessage: "Amount to burn"
                          })
                    }
                  />
                  <span className="text-sm text-danger-600">
                    {formik.errors.supply ?? null}
                  </span>
                </div>

                <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                  {type === "mint"
                    ? intl.formatMessage({
                        id: "tokenForm.mintText",
                        defaultMessage:
                          "Determine the amount of tokens to add to circulation"
                      })
                    : intl.formatMessage({
                        id: "form.burnText",
                        defaultMessage:
                          "Determine the amount of tokens to withdraw from circulation"
                      })}
                </p>
              </div>
              <div className="flex flex-wrap justify-between w-full md:w-10/12">
                <div className="w-full md:w-6/12 mb-5">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {intl.formatMessage({
                      id: "form.targetAddress",
                      defaultMessage: "Target address"
                    })}
                  </h3>
                  {/* supply field using for burn also */}
                  <InputField
                    id="targetAddress"
                    name="targetAddress"
                    onChange={formik.handleChange}
                    value={formik.values.targetAddress}
                    placeholder={intl.formatMessage({
                      id: "form.targetAddress",
                      defaultMessage: "Target address"
                    })}
                  />
                </div>

                <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                  {type === "mint"
                    ? intl.formatMessage({
                        id: "tokenForm.targetAddressMintText",
                        defaultMessage:
                          "Enter the address where you want the tokens to be minted, which is typically your own balance"
                      })
                    : intl.formatMessage({
                        id: "form.targetAddressBurnText",
                        defaultMessage:
                          "Enter the address from which you want to burn the tokens, which is typically your own balance"
                      })}
                </p>
              </div>
            </>
          )}
          {["transfer"].includes(type) && (
            <div className="flex flex-wrap justify-between w-full md:w-10/12">
              <div className="w-full md:w-6/12 mb-5">
                <h3 className="text-base text-secondary-600 mb-3">
                  {intl.formatMessage({
                    id: "form.transfer",
                    defaultMessage: "Target address"
                  })}
                </h3>
                <InputField
                  id="newAddress"
                  name="newAddress"
                  onChange={formik.handleChange}
                  value={formik.values.newAddress}
                  placeholder={intl.formatMessage({
                    id: "input.address",
                    defaultMessage: "address"
                  })}
                />
                <span className="text-sm text-danger-600">
                  {formik.errors.newAddress ?? null}
                </span>
              </div>
              <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                {intl.formatMessage({
                  id: "tokenForm.transferText",
                  defaultMessage:
                    "Enter the address of the new owner you want to assign to this token"
                })}
              </p>
            </div>
          )}
          {type === "create" && (
            <>
              <div className="flex flex-wrap justify-between w-full md:w-10/12">
                <div className="w-full mb-5 md:w-6/12">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {intl.formatMessage({
                      id: "input.decimals",
                      defaultMessage: "Token Decimals"
                    })}
                  </h3>
                  <InputField
                    id="decimals"
                    name="decimals"
                    type="number"
                    pattern="[0-9]*"
                    onChange={formik.handleChange}
                    value={getFormToken?.decimals ?? formik.values.decimals}
                    placeholder={intl.formatMessage({
                      id: "input.decimals",
                      defaultMessage: "decimals"
                    })}
                    disabled={!!getFormToken?.decimals}
                  />
                  <span className="text-sm text-danger-600">
                    {formik.errors.decimals ?? null}
                  </span>
                </div>
                <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                  {intl.formatMessage({
                    id: "tokenForm.symbolText",
                    defaultMessage:
                      "Set the decimal precision for the token (up to 18), which is typically 9 in Venom"
                  })}
                </p>
              </div>
              <div className="flex flex-wrap justify-between w-full md:w-10/12">
                <div className="w-full mb-5 md:w-6/12">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {intl.formatMessage({
                      id: "input.initialSupply",
                      defaultMessage: "Initial supply"
                    })}
                  </h3>
                  <InputField
                    id="initialSupply"
                    name="initialSupply"
                    type="number"
                    disabled
                    onChange={formik.handleChange}
                    value={formik.values.initialSupply}
                    placeholder={intl.formatMessage({
                      id: "input.initialSupply",
                      defaultMessage: "initial supply"
                    })}
                  />
                  <span className="text-sm text-danger-600">
                    {formik.errors.initialSupply ?? null}
                  </span>
                </div>
                <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                  {intl.formatMessage({
                    id: "tokenForm.supplyText",
                    defaultMessage:
                      "Set the initial number of tokens that will be created and made available in your account"
                  })}
                </p>
              </div>
              <div className="flex flex-wrap justify-between w-full md:w-10/12">
                <div className="w-full mb-5 md:w-6/12">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {intl.formatMessage({
                      id: "input.maxSupply",
                      defaultMessage: "Max supply"
                    })}
                  </h3>
                  <InputField
                    disabled
                    value="Unlimited"
                    placeholder={intl.formatMessage({
                      id: "input.maxSupply",
                      defaultMessage: "Max supply"
                    })}
                  />
                  <span className="text-sm text-danger-600">
                    {formik.errors.initialSupply ?? null}
                  </span>
                </div>
                <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                  {intl.formatMessage({
                    id: "tokenForm.maxSupplyText",
                    defaultMessage:
                      "Specify the maximum number of tokens that can be created and made available Unlimited"
                  })}
                </p>
              </div>

              <div className="flex flex-wrap justify-between w-full md:w-10/12">
                <div className="w-full mb-8 md:w-6/12">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {intl.formatMessage({
                      id: "input.tokenOwner",
                      defaultMessage: "Token owner"
                    })}
                  </h3>
                  {walletStore?.account?.address && (
                    <InputField
                      disabled
                      value={cutString(
                        walletStore.account.address.toString(),
                        7,
                        6
                      )}
                    />
                  )}
                </div>
                <p className="text-sm text-white/50 w-full mb-4 md:mt-7 md:w-5/12 max-w-[360px]">
                  {intl.formatMessage({
                    id: "tokenForm.tokenOwnerText",
                    defaultMessage:
                      "Token owner An account with access to the token dashboard and authorized to perform key actions"
                  })}
                </p>
              </div>
              <div className="flex mb-5 md:w-5/12">
                <Checkbox disabled checked />
                <div className="ml-4">
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={
                      <span className="text-sm text-white">
                        {intl.formatMessage({
                          id: "tokenForm.soon",
                          defaultMessage: "It will be available soon"
                        })}
                      </span>
                    }
                  >
                    <div className="flex">
                      <h3 className="text-base text-secondary-600 mb-3 leading-4">
                        {intl.formatMessage({
                          id: "input.mintable",
                          defaultMessage: "Mintable"
                        })}
                      </h3>
                      <InfoIcon className="relative bottom-2 ml-2" />
                    </div>
                  </Tooltip>
                  <p className="text-sm text-white/50 mb-3">
                    {intl.formatMessage({
                      id: "input.mintableText",
                      defaultMessage:
                        "Owners with minting permissions can create new tokens, up to the maximum token supply, by minting them"
                    })}
                  </p>
                </div>
              </div>
              <div className="flex mb-5 md:w-5/12">
                <Checkbox disabled checked />
                <div className="ml-4">
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={
                      <span className="text-sm text-white">
                        {intl.formatMessage({
                          id: "tokenForm.soon",
                          defaultMessage: "It will be available soon"
                        })}
                      </span>
                    }
                  >
                    <div className="flex">
                      <h3 className="text-base text-secondary-600 mb-3 leading-4">
                        {intl.formatMessage({
                          id: "input.burnable",
                          defaultMessage: "Burnable"
                        })}
                      </h3>
                      <InfoIcon className="relative bottom-2 ml-2" />
                    </div>
                  </Tooltip>
                  <p className="text-sm text-white/50 mb-3">
                    {intl.formatMessage({
                      id: "input.burnableText",
                      defaultMessage:
                        "If you want your token to have the ability to be manually burned to decrease its circulating supply, then select this option"
                    })}
                  </p>
                </div>
              </div>
              <div className="flex mb-5 md:w-5/12">
                <Checkbox disabled checked />
                <div className="ml-4">
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={
                      <span className="text-sm text-white">
                        {intl.formatMessage({
                          id: "tokenForm.soon",
                          defaultMessage: "It will be available soon"
                        })}
                      </span>
                    }
                  >
                    <div className="flex">
                      <h3 className="text-base text-secondary-600 mb-3 leading-4">
                        {intl.formatMessage({
                          id: "input.transferrable",
                          defaultMessage: "Transferrable"
                        })}
                      </h3>
                      <InfoIcon className="relative bottom-2 ml-2" />
                    </div>
                  </Tooltip>
                  <p className="text-sm text-white/50 mb-3">
                    {intl.formatMessage({
                      id: "input.transferrableText",
                      defaultMessage:
                        "This feature determines whether the token owner can be modified at a later time"
                    })}
                  </p>
                </div>
              </div>
            </>
          )}
          {isLoading ? (
            <SpinnerDots className={styles.spinner} />
          ) : (
            <div className="flex justify-end mt-10 md:w-5/12">
              <Button
                type="button"
                variant="secondary"
                inverse
                onClick={(e) => handleBack(e)}
                className="md:w-4/12"
              >
                {intl.formatMessage({
                  id: "btn.back",
                  defaultMessage: "Back"
                })}
              </Button>
              {type === "create" ? (
                <Button
                  variant="secondary"
                  type="submit"
                  disabled={!formik.isValid || !walletStore.isConnected}
                  disabledError={!walletStore.isConnected ? false : true}
                  className="w-10/12 ml-4 max-w-[160px]"
                >
                  {intl.formatMessage({
                    id: "btn.create",
                    defaultMessage: "Create new token"
                  })}
                </Button>
              ) : ["mint", "burn"].includes(type) ? (
                <Button
                  className="md:w-10/12 ml-4 max-w-[160px]"
                  variant="secondary"
                  type="button"
                  disabledError={!walletStore.isConnected ? false : true}
                  disabled={!formik.isValid || !walletStore.isConnected}
                  onClick={() =>
                    handleSupplyOrBurn(type === "mint" ? false : true)
                  }
                >
                  {type === "mint"
                    ? intl.formatMessage({
                        id: "btn.mint",
                        defaultMessage: "Mint tokens"
                      })
                    : intl.formatMessage({
                        id: "btn.burn",
                        defaultMessage: "Burn tokens"
                      })}
                </Button>
              ) : ["transfer"].includes(type) ? (
                <Button
                  className="md:w-10/12 ml-4 max-w-[160px]"
                  variant="secondary"
                  disabled={!formik.isValid || !walletStore.isConnected}
                  disabledError={!walletStore.isConnected ? false : true}
                  onClick={() => handleTransfer()}
                >
                  {intl.formatMessage({
                    id: "btn.transfer",
                    defaultMessage: "Change owner"
                  })}
                </Button>
              ) : null}
            </div>
          )}
        </form>
      </div>
    </Container>
  );
};

export default observer(TokenFormPage);
