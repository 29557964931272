import styles from "./index.module.css";
import React, { useMemo } from "react";
import classNames from "classnames";
import { Spinner } from "@/components/common/spinner";

type ButtonProps = {
  variant: "primary" | "secondary" | "tertiary";
  inverse?: boolean;
  disabledError?: boolean;
  showSpinner?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      inverse,
      className,
      children,
      showSpinner,
      disabledError,
      ...buttonProps
    },
    ref
  ) => {
    const btnClassName = useMemo(() => {
      switch (variant) {
        case "primary":
          return !inverse ? styles.primary : styles.primary_inverse;
        case "secondary":
          return !inverse ? styles.secondary : styles.secondary_inverse;
        case "tertiary":
          return !inverse ? styles.tertiary : styles.tertiary_inverse;
        default:
          return undefined;
      }
    }, [inverse, variant]);

    return (
      <button
        {...buttonProps}
        className={classNames(
          styles.button,
          btnClassName,
          disabledError && styles.error,
          className
        )}
        ref={ref}
      >
        {showSpinner && <Spinner className={styles.spinner_btn} />}

        {children}
      </button>
    );
  }
);

export default Button;
