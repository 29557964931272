import BigNumber from "bignumber.js";

const defaultBNFormatting = {
  prefix: "",
  decimalSeparator: ".",
  groupSeparator: " ",
  groupSize: 3,
  secondaryGroupSize: 0,
  suffix: ""
};

export const bigNumberToStr = (number: BigNumber, decimalPlaces: number) => {
  return number
    .dp(decimalPlaces, BigNumber.ROUND_DOWN)
    .toFormat(defaultBNFormatting);
};
