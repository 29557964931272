export enum EchainId {
  first = 1
}

export type TOKEN_ACTION_TYPES = "create" | "mint" | "burn" | "transfer";

type ConstructorProps = {
  name: string;
  chainId: EchainId.first;
  symbol: string;
  decimals: number;
  address: string;
  logoURI: string;
  verified: boolean;
  supply: string;
  owner?: string;
};

export class TokenModel {
  constructor(props: ConstructorProps) {
    this.name = props.name;
    this.chainId = props.chainId;
    this.symbol = props.symbol;
    this.decimals = props.decimals;
    this.address = props.address;
    this.logoURI = props.logoURI;
    this.verified = props.verified;
    this.supply = props.supply;
    this.owner = props.owner;
  }

  name: string;
  chainId: 1;
  symbol: string;
  decimals: number;
  address: string;
  logoURI: string;
  verified: boolean;
  supply: string;
  owner?: string;
}
