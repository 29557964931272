import React, { useMemo, createContext } from "react";
import notification from "rc-notification";
import { useVM } from "@/hooks/useVM";
import { makeAutoObservable } from "mobx";
import Notify, { closeAlertIcon } from "@/components/common/notify";

const ctx = createContext<NotificationStore | null>(null);

interface IProps {
  children?: any;
}

export const NotificationsVMProvider: React.FC<IProps> = ({ children }) => {
  const store = useMemo(() => new NotificationStore(), []);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useNotificationsVM = () => useVM(ctx);

export type TNotifyOptions = Partial<{
  duration: number;
  closable: boolean;
  key: string;

  type: "error" | "info" | "warning" | "success";
  link?: string;
  linkTitle?: string;
  title: string;
  onClick?: () => void;
  onClickText?: string;
  style: { [key: string]: string | number };
}>;

const style = {
  boxShadow: "0px 8px 24px rgba(54, 56, 112, 0.16)",
  borderRadius: 12,
  padding: 16
};

const styles = {
  error: {
    ...style,
    backgroundColor: "#cd323299"
  },
  warning: {
    ...style
  },
  info: {
    ...style,
    backgroundColor: "#57ff9499"
  },
  success: {
    ...style,
    backgroundColor: "#11a97d73"
  }
};

export class NotificationStore {
  public _instance?: any;
  constructor() {
    const width = window.innerWidth;
    const mobileStyle = {
      top: 80,
      right: 16,
      left: 16,
      zIndex: "1000000000000000000"
    };
    const desktopStyle = {
      top: 96,
      right: 16,
      left: width - 320 - 16,
      zIndex: "1000000000000000000"
    };
    makeAutoObservable(this);
    notification.newInstance(
      {
        closeIcon: closeAlertIcon,
        style: width >= 880 ? desktopStyle : mobileStyle
      },
      (notification: any) => (this._instance = notification)
    );
  }

  notify(content: string, opts: TNotifyOptions = {}) {
    if (opts.key) {
      this._instance.removeNotice(opts.key);
    }
    const type = opts.type || "info";
    try {
      this._instance &&
        this._instance.notice({
          ...opts,
          placement: "center",
          content: Notify(content, {
            ...opts,
            type
          }),
          style: {
            ...styles[type],
            border: `1px solid #ffffff33`,
            ...opts.style
          },
          className: "custom-notification",
          duration: opts.duration ?? 3,
          key: opts.key,
          closable: true,
          closeIcon: closeAlertIcon
        });
    } catch (e) {
      console.error(content);
    }
  }
}
