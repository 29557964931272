import React from "react";
import { BsX } from "react-icons/bs";
import style from "./index.module.css";
import classNames from "classnames";
import { ReactComponent as ErrorIcon } from "@/assets/icons/error.svg";
import { ReactComponent as SuccessIcon } from "@/assets/icons/success.svg";
import { ReactComponent as WarningIcon } from "@/assets/icons/warning.svg";
import { ReactComponent as InfoIcon } from "@/assets/icons/information.svg";

// todo import
export type TNotifyOptions = Partial<{
  duration: number;
  closable: boolean;
  key: string;

  type: "error" | "info" | "warning" | "success";
  link?: string;
  linkTitle?: string;
  title: string;
  onClick?: () => void;
  onClickText?: string;
  style: { [key: string]: string | number };
}>;

const getAlert = (
  content: string,
  { type, title, link, linkTitle, onClick, onClickText }: TNotifyOptions
) => {
  if (!type) return null;
  return (
    <div
      className={classNames(style.root, {
        [style[type]]: type
      })}
    >
      <div className={style.body}>
        <Icon type={type} />
        <div className="flex flex-col">
          {title && (
            <p className="notifications-text text-base font-medium text-white">
              {title}
            </p>
          )}
          <p
            className="notifications-text text-sm text-white"
            style={{ marginTop: 2, width: "100%", wordBreak: "break-word" }}
          >
            {content}
          </p>
          {link && (
            <a
              className={style.link}
              target="_blank"
              href={link}
              rel="noreferrer"
            >
              {linkTitle || link}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const Icon: React.FunctionComponent<{
  type: "error" | "info" | "warning" | "success";
}> = ({ type }) => {
  let icon = null;
  switch (type) {
    case "error":
      icon = <ErrorIcon />;
      break;
    case "success":
      icon = <SuccessIcon />;
      break;
    case "info":
      icon = <InfoIcon />;
      break;
    case "warning":
      icon = <WarningIcon />;
      break;
  }
  return <div className="mr-4">{icon}</div>;
};

export const closeAlertIcon = <BsX />;

export default getAlert;
