import SingleSelect, {
  SingleSelectValue
} from "@/components/core/single-select";
import { TokenModel } from "@/models/TokenModel";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { VENOM_MODEL } from "@/utils/constants";

const tokenToValue = (token: TokenModel) => {
  return {
    label: token.symbol,
    value: token.address,
    icon: token.logoURI || "/venom_logo.svg"
  };
};

type Props = {
  value?: TokenModel | null;
  list?: TokenModel[];
  type?: "large" | "medium";
  addNative?: boolean;
  onChange: (token: TokenModel | null) => void;
};

const TokenSelect = ({
  value,
  list,
  addNative = false,
  type = "medium",
  onChange
}: Props) => {
  const intl = useIntl();
  // const staticData = useStaticData();

  const selectValue = useMemo(
    () => (value ? tokenToValue(value) : undefined),
    [value]
  );

  const onValueChange = useCallback(
    (val: SingleSelectValue | null) => {
      const tokens = list;
      if (!tokens) return;
      const foundToken = tokens.find((_) => _.address === val?.value);
      if (!foundToken) {
        onChange(null);
        return;
      }
      onChange(foundToken);
    },
    [onChange, list]
  );

  const options = useMemo(() => {
    const tokens = list ?? [];

    if (addNative && !tokens.find((_) => _.address === VENOM_MODEL.address)) {
      tokens?.unshift(VENOM_MODEL);
    }

    if (!tokens) return;
    return tokens.map((x) => tokenToValue(x)) ?? [];
  }, [list, addNative]);

  useEffect(() => {
    if (addNative) onChange(VENOM_MODEL);
  }, []);

  return (
    <SingleSelect
      type={type}
      options={options}
      placeholderText={intl.formatMessage({
        id: "select.placeholder",
        defaultMessage: "Select"
      })}
      value={selectValue}
      onChange={onValueChange}
      isSearchable={false}
    />
  );
};

export default TokenSelect;
