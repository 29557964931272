import { useWallet } from "@/providers/BaseStoresProvider";
import { useCallback } from "react";

export const useConnectWallet = () => {
  const wallet = useWallet();

  const showWalletConnect = useCallback(async () => {
    await wallet.connect();
    console.log("connect");
  }, [wallet]);

  return {
    showConnectWallet: showWalletConnect
  };
};
