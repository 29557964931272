import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ProvideBaseStores } from "@/providers/BaseStoresProvider";
import { LanguageStoreProvider } from "@/providers/LanguageStoreProvider";
import { TOKEN_ACTION_TYPES } from "@/models/TokenModel";

import ROUTES from "@/routes";
import Header from "./components/layout/header";
import AccountInfo from "./components/layout/account-info";
import NotFoundPage from "./components/layout/not-found";
import Footer from "@/components/layout/footer";

// @/pages
import HomePage from "@/pages/home";

import MainPage from "@/pages/token";
import TokenForm from "@/pages/token/tokenForm";

import VestingPage from "@/pages/vesting";
import VestingDetailedPage from "@/pages/vesting/detailed";
import CreateVestingPage from "@/pages/vesting/create";
import ClaimVestingPage from "@/pages/vesting/claim";

import AidropPage from "@/pages/airdrop";
import AidropCreatePage from "@/pages/airdrop/create";
import AidropDetailedPage from "@/pages/airdrop/detailed";

import MarketingPage from "@/pages/marketing";

const tokenActions = ["mint", "create", "burn", "transfer"];

const RenderOtherComponents = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== ROUTES.index.path && (
        <>
          <Header />
          <div className="account-lg__wrap">
            <AccountInfo />
          </div>
          <div id="portal" />
          <Footer className="mt-auto" noContainer />
        </>
      )}
    </>
  );
};

export const App = observer(() => {
  if (typeof window === "undefined") return <></>;

  return (
    <LanguageStoreProvider>
      <div className="app">
        <ProvideBaseStores>
          <Router>
            <Routes>
              {/* landing page */}
              <Route path={ROUTES.index.path} element={<HomePage />} />

              {/* token pages */}
              <Route path={ROUTES.tokens.path} element={<MainPage />} />
              <Route
                path={`${ROUTES.tokens.path}/${ROUTES.tokens.create.path}`}
                element={<TokenForm type="create" />}
              />
              {tokenActions.map((_) => (
                <Route
                  key={_}
                  path={`${ROUTES.tokens.path}/${_}/:tokenId`}
                  element={<TokenForm type={_ as TOKEN_ACTION_TYPES} />}
                />
              ))}

              {/* vesting pages */}
              <Route path={ROUTES.vesting.path} element={<VestingPage />} />
              <Route
                path={`${ROUTES.vesting.path}/:contractId`}
                element={<VestingDetailedPage />}
              />
              <Route
                path={`${ROUTES.vesting.path}/${ROUTES.vesting.create.path}`}
                element={<CreateVestingPage />}
              />
              <Route
                path={`${ROUTES.vesting.path}/${ROUTES.vesting.claim.path}`}
                element={<ClaimVestingPage />}
              />

              {/* aidrop pages */}
              <Route path={ROUTES.airdrop.path} element={<AidropPage />} />
              <Route
                path={`${ROUTES.airdrop.path}/:contractId`}
                element={<AidropDetailedPage />}
              />
              <Route
                path={`${ROUTES.airdrop.path}/${ROUTES.airdrop.create.path}`}
                element={<AidropCreatePage />}
              />

              {/* marketing @/pages */}
              <Route path={ROUTES.marketing.path} element={<MarketingPage />} />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>

            <RenderOtherComponents />
          </Router>
        </ProvideBaseStores>
      </div>
    </LanguageStoreProvider>
  );
});
