/* eslint-disable no-restricted-globals */
import { makeAutoObservable, runInAction } from "mobx";
import { Address } from "everscale-inpage-provider";
import { NotificationStore } from "@/stores/NotificationStore";
import { WalletStore } from "@/stores/WalletStore";
import { TokenFactoryAbi } from "@/abi/token-factory";
import { TokenRootAbi } from "@/abi/token-root";
import { TokenRootDeployCallbacks } from "@/abi/token-callback";
import { useStaticRpc, useTokenRpc } from "@/hooks/useStaticRpc";
import { TokenUpgradableAbi } from "@/abi/token-factory-upgradable";
import { Drop3RepoAbi } from "@/abi/drop3-repo";
import { TokenModel, EchainId } from "@/models/TokenModel";
import { TokenWalletAbi } from "@/abi/token-wallet";
import {
  toCoin,
  zeroAddress,
  TOKEN_FACTORY_ADDRESS,
  CONTRACTS_REPO_ADDRESS,
  IS_VENOM
} from "@/utils/constants";
import BN, { decimalsZeros } from "@/utils/BN";
import dayjs from "dayjs";
import axios from "axios";
import BigNumber from "bignumber.js";

const CUSTOM_KEY = "venomize-custom-token";

export class TokenStore {
  constructor(
    private wallet: WalletStore,
    private intl: any,
    private notifications: NotificationStore
  ) {
    makeAutoObservable(this);
  }

  public newCreatedTokens: TokenModel[] = [];
  public tokensData: TokenModel[] = [];
  public tokensLoading = false;

  public contractsFactoryAddress = "";
  public getFactoryLoading = false;
  public isFactoryInitialized = true;

  get filteredUserTokens() {
    return this.tokensData.filter((_) => {
      if (!_?.owner) return false;
      return _.owner === this.wallet.account?.address.toString();
    });
  }

  // case for new created token
  // smtimes its loading at first render, smt not, in this case we can be sure for no duplicates
  get createdAndFilteredUserTokens() {
    const arr = [...this.tokensData, ...this.newCreatedTokens];
    return arr.filter(
      (v, i, a) => a.findIndex((v2) => v2.address === v.address) === i
    );
  }

  async loadUserTokens() {
    this.setTokensLoaded(true);
    console.log("___loadUserTokens");

    try {
      if (!this.wallet.provider || !this.wallet.account) {
        throw new Error("Cannot initVestingFactory. Wallet is not connected");
      }

      console.log(
        this.wallet.account.address,
        "___this.wallet.account.address"
      );

      let tokensData: any[] = [];

      if (IS_VENOM) {
        const list = (
          await axios.post("https://tokens.venomscan.com/v1/root_contract", {
            limit: 100,
            offset: 0,
            substring: null,
            tokenOwnerAddress: this.wallet.account.address.toString()
          })
        ).data?.rootTokenContracts;

        tokensData = list?.map((_: any) => _?.rootAddress);
      } else {
        const tokenRpc = await useTokenRpc(
          this.wallet.account.address.toString()
        );

        tokensData = tokenRpc?.data?.ft?.tokens?.edges
          ?.map((_: Record<"node", any>) => _?.node?.address)
          .filter(Boolean);
      }

      const storageTokens = localStorage.getItem(CUSTOM_KEY);

      if (storageTokens) {
        const parsedTokens = JSON.parse(storageTokens) as string[];
        tokensData = [...tokensData, ...parsedTokens];
      }

      console.log(storageTokens, "___parsedTokens");
      console.log(tokensData, "___tokensData");

      if (tokensData?.length > 0) {
        await Promise.all(
          tokensData.map((_: string) => this.getTokenData(_, true, true))
        );
      }
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      throw err;
    } finally {
      this.setTokensLoaded(false);
    }
  }

  resetStore() {
    this.isFactoryInitialized = false;
    this.tokensLoading = false;
    this.tokensData = [];
    this.newCreatedTokens = [];
    this.contractsFactoryAddress = "";
  }

  setTokensLoaded(payload: boolean) {
    this.tokensLoading = payload;
  }

  setFactoryInitialized(payload: boolean) {
    this.isFactoryInitialized = payload;
  }

  async postMarketingForm(params: any, html: any) {
    try {
      const baseUrl = process.env.REACT_APP_ORIGIN;

      await fetch(`${baseUrl}/marketing/send/creator`, {
        method: "POST",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({ ...params })
      }).then((res) => res.json());

      await fetch(`${baseUrl}/marketing/send/venom`, {
        method: "POST",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({ ...params, html })
      }).then((res) => res.json());

      this.notifications.notify("Email succesfully sent, check Your inbox", {
        type: "success",
        title: "Email sent!",
        duration: 5
      });
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      throw err;
    }
  }

  async transferTokensToContract(params: {
    contract: Address;
    token: Address;
    amount: string | number;
    payload?: string;
    coins?: number;
    deployWalletValue?: number;
  }): Promise<boolean> {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot initVestingFactory. Wallet is not connected");
    }
    try {
      const tokenRoot = new this.wallet.provider.Contract(
        TokenRootAbi,
        params.token
      );
      const tokenData = await this.getTokenData(params.token.toString(), false);
      if (!tokenData) {
        this.notifications.notify("Tokens not exist", {
          type: "error",
          title: "Error",
          duration: 5
        });
        return false;
      }
      const balance = await this.wallet.getTokenBalance(
        tokenData,
        this.wallet.account.address
      );

      if (
        balance.times(10 ** tokenData.decimals).lt(new BigNumber(params.amount))
      ) {
        this.notifications.notify(
          `Amount expected more than ${BN.formatUnits(
            params.amount,
            tokenData.decimals
          ).toNumber()} ${tokenData.symbol} tokens`,
          {
            type: "error",
            title: "Not enough tokens on wallet",
            duration: 5
          }
        );
        return false;
      }
      const { value0: tokenWalletAddres } = await tokenRoot.methods
        .walletOf({
          walletOwner: this.wallet.account.address,
          answerId: 0
        })
        .call({ responsible: true });

      const tokenWallet = new this.wallet.provider.Contract(
        TokenWalletAbi,
        tokenWalletAddres
      );

      console.log("TRANSFERs");
      await tokenWallet.methods
        .transfer({
          amount: params.amount,
          payload: params.payload ?? "",
          recipient: params.contract,
          notify: true,
          deployWalletValue: params.deployWalletValue
            ? toCoin(params.deployWalletValue)
            : 0,
          remainingGasTo: this.wallet.account.address
        })
        .send({
          from: this.wallet.account.address,
          amount: params.coins ? toCoin(params.coins) : toCoin(2),
          bounce: true
        });
      return true;
    } catch (err) {
      console.log(err, "TRANSFER ERR");
      return false;
    }
  }

  async getContractsFactory(): Promise<boolean> {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot getVestingFactory. Wallet is not connected");
    }
    try {
      const staticRpc = useStaticRpc();
      const drop3Contract = new this.wallet.provider.Contract(
        Drop3RepoAbi,
        CONTRACTS_REPO_ADDRESS
      );

      runInAction(() => {
        this.getFactoryLoading = true;
      });

      const vestingFactoryId = await drop3Contract.methods
        .getFactoryAddress({ user: this.wallet.account.address })
        .call();

      if (!staticRpc) return false;

      const result = await staticRpc.getFullContractState({
        address: vestingFactoryId.factory
      });

      runInAction(() => {
        this.contractsFactoryAddress = vestingFactoryId.factory.toString();
        this.getFactoryLoading = false;
      });

      if (!result.state) return false;

      return true;
    } catch (err) {
      return false;
    }
  }

  async initVestingFactory() {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot initVestingFactory. Wallet is not connected");
    }
    try {
      const drop3Address = new this.wallet.provider.Contract(
        Drop3RepoAbi,
        CONTRACTS_REPO_ADDRESS
      );
      await drop3Address.methods.deployDrop3Factory().send({
        from: this.wallet.account.address,
        amount: "500000000",
        bounce: true
      });
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      throw err;
    }
  }

  async burnSupply(
    address: string,
    supply: string,
    decimals: number,
    targetAddress: string
  ) {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot mintNewSupply. Wallet is not connected");
    }
    try {
      const tokenMinted = new this.wallet.provider.Contract(
        TokenUpgradableAbi,
        new Address(address)
      );
      await tokenMinted.methods
        .burnTokens({
          amount: new BN(supply).times(decimalsZeros(decimals)).toFixed(),
          walletOwner: new Address(targetAddress),
          callbackTo: new Address(zeroAddress),
          remainingGasTo: this.wallet.account.address,
          payload: ""
        })
        .send({
          from: this.wallet.account.address,
          amount: "500000000",
          bounce: true
        });
      this.notifications.notify("Tokens successfully burned", {
        type: "success",
        title: "Tokens burned!",
        duration: 5
      });
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      throw err;
    }
  }

  async mintNewSupply(
    address: string,
    supply: string,
    decimals: number,
    targetAddress: string
  ) {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot mintNewSupply. Wallet is not connected");
    }
    try {
      const tokenMinted = new this.wallet.provider.Contract(
        TokenUpgradableAbi,
        new Address(address)
      );

      await tokenMinted.methods
        .mint({
          amount: new BN(supply).times(decimalsZeros(decimals)).toFixed(),
          recipient: new Address(targetAddress),
          deployWalletValue: "100000000",
          remainingGasTo: this.wallet.account.address,
          notify: false,
          payload: ""
        })
        .send({
          from: this.wallet.account.address,
          amount: "500000000",
          bounce: true
        });
      this.notifications.notify("New tokens successfully minted", {
        type: "success",
        title: "New supply minted!",
        duration: 3
      });
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      throw err;
    }
  }

  async transferTokenOwnership(
    tokenAddress: string,
    addressForTransfer: string
  ) {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot create Token. Wallet is not connected");
    }
    try {
      const tokenContract = new this.wallet.provider.Contract(
        TokenUpgradableAbi,
        new Address(tokenAddress)
      );
      await tokenContract.methods
        .transferOwnership({
          newOwner: new Address(addressForTransfer),
          remainingGasTo: this.wallet.account.address,
          callbacks: []
        })
        .send({
          from: this.wallet.account.address,
          amount: toCoin(1),
          bounce: true
        });
      this.notifications.notify("Transfer ownership succeded!", {
        type: "success",
        title: "Ownership changed",
        duration: 5
      });
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      throw err;
    }
  }

  addCustomToken(address: string) {
    let allTokens = localStorage.getItem(CUSTOM_KEY);
    let parsedTokens: string[] = [];

    if (allTokens) {
      parsedTokens = JSON.parse(allTokens) as string[];
    }

    localStorage.setItem(
      CUSTOM_KEY,
      JSON.stringify([...parsedTokens, address])
    );
  }

  // update state for adding tokens to mobx state
  // reset for clearing mobx state to prevent duplicates
  async getTokenData(
    address: string,
    isUpdateState: boolean,
    withReset?: boolean
  ): Promise<TokenModel | null> {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot getTokenData. Wallet is not connected");
    }
    if (withReset) {
      runInAction(() => {
        this.tokensData = [];
      });
    }
    try {
      const staticRpc = useStaticRpc();
      const everAddress = new Address(address);
      const tokenRoot = new this.wallet.provider.Contract(
        TokenRootAbi,
        everAddress
      );

      if (!staticRpc) return null;
      const result = await staticRpc.getFullContractState({
        address: everAddress
      });

      if (result?.state?.isDeployed) {
        const name = await tokenRoot.methods.name({ answerId: 0 }).call();
        const symbol = await tokenRoot.methods.symbol({ answerId: 0 }).call();
        const owner = await tokenRoot.methods.rootOwner({ answerId: 0 }).call();
        const decimals = await tokenRoot.methods
          .decimals({ answerId: 0 })
          .call();
        const supply = await tokenRoot.methods
          .totalSupply({ answerId: 0 })
          .call();

        console.log("1___");
        const tokenData = {
          name: name?.value0,
          chainId: EchainId.first,
          symbol: symbol?.value0,
          owner: owner?.value0.toString(),
          decimals: +decimals?.value0,
          supply: BN.formatUnits(supply?.value0, +decimals.value0).toFormat(0),
          logoURI: "",
          verified: true,
          address
        };

        console.log("2___");
        if (isUpdateState) {
          runInAction(() => {
            this.tokensData.push(tokenData);
          });
        }

        return tokenData;
      }

      return null;
    } catch (err) {
      throw err;
    }
  }

  async createNewToken(params: {
    name: string;
    symbol: string;
    decimals: string;
    initialSupply: string;
  }): Promise<boolean> {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot create Token. Wallet is not connected");
    }
    const tokenFactory = new this.wallet.provider.Contract(
      TokenFactoryAbi,
      TOKEN_FACTORY_ADDRESS
    );
    const callbacks = new this.wallet.provider.Contract(
      TokenRootDeployCallbacks,
      this.wallet.account.address
    );
    const callId = dayjs().unix().toString();

    try {
      const subscriber = new this.wallet.provider.Subscriber();

      const successStream = await subscriber
        .transactions(this.wallet.account.address)
        .flatMap((a) => a.transactions)
        .filterMap(async (transaction) => {
          const decodedTx = await callbacks.decodeTransaction({
            methods: ["onTokenRootDeployed"],
            transaction
          });

          if (decodedTx !== undefined) {
            if (
              decodedTx.method === "onTokenRootDeployed" &&
              decodedTx.input.callId.toString() === callId
            ) {
              return { input: decodedTx.input, transaction };
            }
            return { input: decodedTx.input };
          }

          return undefined;
        })
        .delayed((s) => s.first());

      //initialSupply do not work
      // const iSupply = new BN(0);
      await tokenFactory.methods
        .createToken({
          callId,
          name: params.name,
          symbol: params.symbol,
          decimals: params.decimals,
          initialSupplyTo: this.wallet.account.address,
          initialSupply: "0",
          deployWalletValue: "0",
          mintDisabled: false,
          burnByRootDisabled: false,
          burnPaused: false,
          remainingGasTo: this.wallet.account.address
        })
        .send({
          from: this.wallet.account.address,
          amount: toCoin(5),
          bounce: true
        });

      const result = await successStream();

      if (result?.input?.token_root) {
        const createdToken = result?.input?.token_root.toString();

        this.newCreatedTokens.push({
          name: params.name,
          chainId: 1,
          symbol: params.symbol,
          decimals: +params.decimals,
          logoURI: "",
          verified: true,
          supply: "0",
          address: createdToken
        });
      }

      this.notifications.notify(
        "New token successfully created, check tokens page please",
        {
          type: "success",
          title: "New token created!",
          duration: 2
        }
      );
      return true;
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      return false;
    }
  }
}
