import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { useNavigate } from "react-router";
import { useTokenStore, useAirdropStore } from "@/providers/BaseStoresProvider";
import ROUTES from "@/routes";
import Container from "@/components/core/container";
import Button from "@/components/core/button";
import { observer } from "mobx-react-lite";
import { unixTsToDateStr } from "@/utils/dates";
import BN from "@/utils/BN";
import { TokenModel } from "@/models/TokenModel";
import TokenSelect from "@/components/common/token-select";
import { useWallet } from "@/providers/BaseStoresProvider";
import Tooltip from "@/components/common/tooltip";
import { Spinner } from "@/components/common/spinner";
import { cutString } from "@/utils/strings";

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";

interface IProps {}

const AirdropPage: React.FC<IProps> = () => {
  const [getSelectTokenVal, setTokenVal] = useState<TokenModel | null>(null);
  const [getTokenAirdrops, setTokenAirdrops] = useState<any[]>([]);
  const intl = useIntl();

  const navigate = useNavigate();
  const walletStore = useWallet();
  const tokenStore = useTokenStore();
  const airdropStore = useAirdropStore();

  useEffect(() => {
    if (getSelectTokenVal) {
      const arr = airdropStore?.allUserAirdrops.filter((_) => {
        return _.token.toString() === getSelectTokenVal.address.toString();
      });
      setTokenAirdrops(arr);
    }
  }, [airdropStore?.allUserAirdrops, getSelectTokenVal]);

  useEffect(() => {
    if (!walletStore?.isConnected) {
      setTokenVal(null);
      return;
    }
    if (tokenStore.filteredUserTokens?.length) {
      tokenChange(tokenStore.filteredUserTokens[0]);
      return;
    }
    const reqTokens = async () => {
      await tokenStore.loadUserTokens();
      if (!tokenStore.filteredUserTokens) return;
      tokenChange(tokenStore.filteredUserTokens[0]);
    };
    reqTokens();
  }, [walletStore?.isConnected, tokenStore]);

  useEffect(() => {
    if (!walletStore.isConnected || !tokenStore.contractsFactoryAddress) return;
    airdropStore.getAllAidrops();
  }, [
    tokenStore,
    walletStore,
    airdropStore,
    tokenStore.contractsFactoryAddress
  ]);

  useEffect(() => {
    if (!walletStore.isConnected || !tokenStore.contractsFactoryAddress) return;

    const reqFactoryState = () => airdropStore.getAllAidrops();

    const intervalId = setInterval(reqFactoryState, 20000);

    reqFactoryState();

    return () => {
      clearInterval(intervalId);
    };
  }, [
    airdropStore,
    walletStore.isConnected,
    tokenStore.contractsFactoryAddress
  ]);

  const tokenChange = (val: TokenModel | null) => {
    setTokenVal(val);
  };

  return (
    <Container className="w-8/12 pr-4 mt-2 pl-1">
      <div className="breadcrumbs">
        <Link
          className="breadcrumbs__link breadcrumbs__link--active"
          to={ROUTES.airdrop.path}
        >
          {intl.formatMessage({
            id: "breadcrumbs.airdrop",
            defaultMessage: "Airdrop"
          })}
        </Link>
      </div>
      {tokenStore.tokensLoading ? (
        <div className="w-full flex items-center justify-center h-[50vh]">
          <Spinner className="max-w-[240px]" />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <span className="font-poppins font-semibold text-3xl text-white mr-8 mb-2">
              {intl.formatMessage({
                id: "airdrop.title",
                defaultMessage: "Airdrop"
              })}
            </span>
            <Button
              onClick={() =>
                navigate(`${ROUTES.airdrop.path}/${ROUTES.airdrop.create.path}`)
              }
              variant="primary"
              className={styles.title_bar_button}
            >
              {intl.formatMessage({
                id: "airdrop.createAirdrop",
                defaultMessage: "Create new airdrop"
              })}
            </Button>
          </div>
          {getTokenAirdrops?.length === 0 && !tokenStore.getFactoryLoading && (
            <>
              <ul className="pl-4 mb-14 text-secondary-600 list-disc">
                <li className="text-base mb-2">
                  {intl.formatMessage({
                    id: "main.noAirdrop1",
                    defaultMessage:
                      "An airdrop refers to the distribution of a cryptocurrency token, often at no cost, to multiple wallet addresses"
                  })}
                </li>
                <li className="text-base mb-2">
                  {intl.formatMessage({
                    id: "main.noAirdrop2",
                    defaultMessage:
                      "Venomize allows you to create airdrops to multiple addresses simultaneously by simply clicking a few buttons"
                  })}
                </li>
                <li className="text-base mb-2">
                  {intl.formatMessage({
                    id: "main.noAirdrop3",
                    defaultMessage: "First, create an airdrop."
                  })}
                </li>
              </ul>
            </>
          )}

          <div className="flex mt-2">
            <div className="font-poppins font-medium text-xl text-white mt-2">
              {intl.formatMessage({
                id: "aidrop.myAirdrops",
                defaultMessage: "My airdrops"
              })}
            </div>
            <div className="max-w-[180px] w-full flex items-cente ml-4">
              <div className="max-w-[180px]">
                <TokenSelect
                  type="large"
                  addNative
                  list={tokenStore.createdAndFilteredUserTokens}
                  value={getSelectTokenVal}
                  onChange={tokenChange}
                />
              </div>
            </div>
          </div>

          {getTokenAirdrops.length > 0 && (
            <h3 className="font-poppins text-base text-white mt-2 mb-4">
              {intl.formatMessage({
                id: "aidrop.clickOnText",
                defaultMessage:
                  "Click on the airdrop name to go to the airdrop page with a detailed description of it"
              })}
            </h3>
          )}

          {!getTokenAirdrops.length ? (
            <div className="text-base text-white/50 mt-4 mb-8">
              {intl.formatMessage({
                id: "airdrop.table.noAirdrop",
                defaultMessage:
                  "To view the data, you must first create a airdrop"
              })}
            </div>
          ) : (
            <div className="table-bordered--overflow">
              <table className="table-bordered">
                <thead className="text-2xl">
                  <tr>
                    <th>
                      <Tooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "airdrop.nameTooltip",
                              defaultMessage:
                                "The way the creator of the airdrop named it"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "airdrop.table.name",
                              defaultMessage: "NAME"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "airdrop.nameTooltip2",
                              defaultMessage: "The date and time of the airdrop"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "airdrop.table.date",
                              defaultMessage: "DATE"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "airdrop.nameTooltip3",
                              defaultMessage:
                                "The number of addresses that took part in the airdrop"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "airdrop.table.recipients",
                              defaultMessage: "RECIPIENTS"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip
                        config={{ placement: "top-start" }}
                        content={
                          <span className="text-sm text-white">
                            {intl.formatMessage({
                              id: "airdrop.nameTooltip4",
                              defaultMessage:
                                "The number of tokens that are subject to an airdrop"
                            })}
                          </span>
                        }
                      >
                        <div className="flex items-center">
                          <span>
                            {intl.formatMessage({
                              id: "airdrop.table.amount",
                              defaultMessage: "AMOUNT"
                            })}
                          </span>
                          <InfoIcon className="ml-4" />
                        </div>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody className="table-bordered__body table-bordered__body--airdrop">
                  {getTokenAirdrops.length &&
                    getTokenAirdrops.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() =>
                          navigate(`${ROUTES.airdrop.path}/${item.contract}`)
                        }
                      >
                        <td>
                          {item?.name?.length > 10 ? (
                            <Tooltip
                              config={{ placement: "top-start" }}
                              content={item.name}
                            >
                              <span>
                                {item.name?.length > 10
                                  ? cutString(item.name.toString(), 5, 4)
                                  : item.name}
                              </span>
                            </Tooltip>
                          ) : (
                            <span>{item?.name}</span>
                          )}
                        </td>
                        <td>
                          {unixTsToDateStr(item.date, "DD.MM.YYYY HH:mm")}{" "}
                        </td>
                        <td>{item.airdrop_recipients?.length ?? 0}</td>
                        <td>
                          <div className="flex">
                            <span className="mr-1">
                              {BN.formatUnits(
                                item.total_amount,
                                item.decimals
                              ).toString()}
                            </span>
                            {item?.symbol?.length > 10 ? (
                              <div className="text-ellipsis overflow-hidden max-w-[150px]">
                                <Tooltip
                                  config={{ placement: "top-start" }}
                                  content={item?.symbol}
                                >
                                  <span>
                                    {cutString(item?.symbol.toString(), 5, 4)}
                                  </span>
                                </Tooltip>
                              </div>
                            ) : (
                              <span>{item?.symbol}</span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default observer(AirdropPage);
