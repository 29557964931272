export const Drop3RepoAbi = {
  "ABI version": 2,
  version: "2.2",
  header: ["pubkey", "time", "expire"],
  functions: [
    {
      name: "getFactoryAddress",
      inputs: [{ name: "user", type: "address" }],
      outputs: [{ name: "factory", type: "address" }]
    },
    {
      name: "updateFactoryCode",
      inputs: [{ name: "code", type: "cell" }],
      outputs: []
    },
    {
      name: "getDeployAirdropValue",
      inputs: [{ name: "length", type: "uint128" }],
      outputs: [{ name: "value", type: "uint128" }]
    },
    {
      name: "getDeployVestingValue",
      inputs: [],
      outputs: [{ name: "value", type: "uint128" }]
    },
    {
      name: "getDeployVestingPayload",
      inputs: [
        { name: "name", type: "string" },
        { name: "user", type: "address" },
        { name: "token", type: "address" },
        { name: "wever_token", type: "address" },
        { name: "wever_vault", type: "address" },
        { name: "vesting_amount", type: "uint128" },
        { name: "vesting_start", type: "uint32" },
        { name: "vesting_end", type: "uint32" }
      ],
      outputs: [{ name: "value0", type: "cell" }]
    },
    {
      name: "getDeployAirdropPayload",
      inputs: [
        { name: "name", type: "string" },
        { name: "refund_destination", type: "address" },
        { name: "refund_lock_duration", type: "uint256" },
        { name: "token_root_address", type: "address" },
        { name: "recipients", type: "address[]" },
        { name: "amounts", type: "uint128[]" }
      ],
      outputs: [{ name: "value0", type: "cell" }]
    },
    {
      name: "deployDrop3Factory",
      inputs: [],
      outputs: [{ name: "factory", type: "address" }]
    },
    {
      name: "wEverOccur",
      inputs: [
        { name: "amount", type: "uint128" },
        { name: "payload", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "onFactoryDeployed",
      inputs: [
        { name: "owner_", type: "address" },
        { name: "tokenRoot", type: "optional(address)" }
      ],
      outputs: []
    },
    {
      name: "onAcceptTokensTransfer",
      inputs: [
        { name: "tokenRoot", type: "address" },
        { name: "amount", type: "uint128" },
        { name: "sender", type: "address" },
        { name: "value3", type: "address" },
        { name: "remainingGasTo", type: "address" },
        { name: "payload", type: "cell" }
      ],
      outputs: []
    },
    {
      name: "updateFactoryBatch",
      inputs: [
        { name: "addrs", type: "address[]" },
        { name: "send_gas_to", type: "address" }
      ],
      outputs: []
    },
    {
      name: "upgrade",
      inputs: [{ name: "new_code", type: "cell" }],
      outputs: []
    },
    {
      name: "constructor",
      inputs: [],
      outputs: []
    },
    {
      name: "randomNonce",
      inputs: [],
      outputs: [{ name: "randomNonce", type: "uint256" }]
    },
    {
      name: "admin",
      inputs: [],
      outputs: [{ name: "admin", type: "address" }]
    },
    {
      name: "drop3FactoryCode",
      inputs: [],
      outputs: [{ name: "drop3FactoryCode", type: "cell" }]
    },
    {
      name: "linearVestingCode",
      inputs: [],
      outputs: [{ name: "linearVestingCode", type: "cell" }]
    },
    {
      name: "airdropCode",
      inputs: [],
      outputs: [{ name: "airdropCode", type: "cell" }]
    },
    {
      name: "linearVestingVersion",
      inputs: [],
      outputs: [{ name: "linearVestingVersion", type: "uint16" }]
    },
    {
      name: "airdropVersion",
      inputs: [],
      outputs: [{ name: "airdropVersion", type: "uint16" }]
    },
    {
      name: "factoryVersion",
      inputs: [],
      outputs: [{ name: "factoryVersion", type: "uint16" }]
    }
  ],
  data: [
    { key: 1, name: "randomNonce", type: "uint256" },
    { key: 2, name: "admin", type: "address" },
    { key: 3, name: "drop3FactoryCode", type: "cell" },
    { key: 4, name: "linearVestingCode", type: "cell" },
    { key: 5, name: "airdropCode", type: "cell" }
  ],
  events: [
    {
      name: "FactoryCodeUpdate",
      inputs: [{ name: "new_version", type: "uint16" }],
      outputs: []
    }
  ],
  fields: [
    { name: "_pubkey", type: "uint256" },
    { name: "_timestamp", type: "uint64" },
    { name: "_constructorFlag", type: "bool" },
    { name: "randomNonce", type: "uint256" },
    { name: "admin", type: "address" },
    { name: "drop3FactoryCode", type: "cell" },
    { name: "linearVestingCode", type: "cell" },
    { name: "airdropCode", type: "cell" },
    { name: "linearVestingVersion", type: "uint16" },
    { name: "airdropVersion", type: "uint16" },
    { name: "factoryVersion", type: "uint16" },
    { name: "_wallets", type: "map(address,address)" },
    {
      components: [
        { name: "amount", type: "uint128" },
        { name: "params", type: "cell" }
      ],
      name: "_factoryParams",
      type: "map(address,tuple)"
    },
    { name: "_factories", type: "map(address,address)" }
  ]
} as const;
