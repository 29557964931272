import { ProviderRpcClient } from "everscale-inpage-provider";
import { EverscaleStandaloneClient } from "everscale-standalone-client";
import axios from "axios";
import { NETWORK_ID_VENOM } from "@/utils/constants";

let rpc: ProviderRpcClient;

export const everGql =
  "https://mainnet.evercloud.dev/9d5874c34e104157abb9340b9ba9b70a/graphql";
export const venomGql = "https://gql.venom.foundation/graphql";

const endpoint =
  process.env.REACT_APP_VENOM_ACTIVE === "1" ? venomGql : everGql;

export function useStaticRpc(): ProviderRpcClient | null {
  if (typeof window === "undefined") return null;
  if (rpc === undefined) {
    console.debug(
      "%cCreated a new one ProviderRpcClient instance as a static provider to interacts with contracts"
    );
    rpc = new ProviderRpcClient({
      fallback: () =>
        EverscaleStandaloneClient.create({
          connection: {
            id: NETWORK_ID_VENOM,
            group: "venom_testnet",
            type: "graphql",
            data: {
              endpoints: [endpoint]
            }
          }
        }),

      forceUseFallback: true
    });
  }
  return rpc;
}

export async function useTokenRpc(address: string): Promise<any | []> {
  const headers = {
    "content-type": "application/json"
  };
  const graphqlQuery = {
    query: `query {
      ft {
        tokens(rootOwner: "${address}") {
          edges {
            node {
              address
              rootOwner
            }
          }
        }
      }
    }`,
    variables: {}
  };
  try {
    const response = await axios({
      url: endpoint,
      method: "post",
      headers: headers,
      data: graphqlQuery
    }).then((res) => res);
    return response?.data;
  } catch (err) {
    return [];
  }
}
