import style from "./index.module.css";
import classnames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { createPortal } from "react-dom";
import { ReactComponent as Cross } from "@/assets/icons/cross.svg";
import { useEffect, useRef, useState } from "react";

interface IProps {
  size: "sm" | "md" | "lg" | "full";
  isOpened: boolean;
  onClose?: () => void;
  className?: string;
  modalAlign?: "center" | "start" | "end";
  contentClass?: string;
  noPadding?: boolean;
  bounce?: boolean;
  title?: string;
  headerExtraContent?: JSX.Element | JSX.Element[] | string;
  children?: JSX.Element | JSX.Element[] | string;
}

export const modalVariant = {
  initial: {
    opacity: 0,
    scale: 0.8,
    transition: {
      type: "spring",
      damping: 10,
      bounce: 0,
      duration: 0.01
    }
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      damping: 8,
      bounce: 0,
      duration: 0.01
    }
  },
  exit: {
    opacity: 0,
    scale: 0.6,
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3
    }
  }
};

export const modalVariantFade = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
};

const Modal: React.FC<IProps> = ({
  title,
  headerExtraContent,
  children,
  className,
  contentClass,
  size,
  isOpened,
  noPadding,
  modalAlign = "center",
  bounce = false,
  onClose
}) => {
  const portalRef = useRef<Element | null>(null);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window === "undefined") return;
    portalRef.current = document.querySelector<HTMLElement>("#portal");
    setMounted(true);
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!isOpened) return;

    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "unset";
    };
  }, [isOpened]);

  return mounted && portalRef.current ? (
    <>
      {createPortal(
        <AnimatePresence>
          {isOpened && (
            <div className={classnames(style.modal, className)}>
              <div
                className={classnames(
                  style.wrapper,
                  modalAlign === "center"
                    ? "items-center"
                    : modalAlign === "start"
                    ? "items-start"
                    : modalAlign === "end"
                    ? "items-end"
                    : ""
                )}
              >
                <div className={classnames(style.overlay)} onClick={onClose} />

                <motion.div
                  initial={
                    bounce ? modalVariant.initial : modalVariantFade.initial
                  }
                  animate={
                    bounce ? modalVariant.animate : modalVariantFade.animate
                  }
                  exit={bounce ? modalVariant.exit : modalVariantFade.exit}
                  className={classnames(
                    style.content,
                    style[size],
                    contentClass,
                    noPadding && "!p-0",
                    { "w-full mx-2 sm:max-w-[450px]": size === "sm" },
                    { "w-full mx-2 md:max-w-[680px]": size === "md" },
                    { "w-full mx-2 md:max-w-[1024px]": size === "lg" },
                    {
                      "w-[100%] max-w-[100%] h-[100dvh]": size === "full"
                    }
                  )}
                >
                  {title || headerExtraContent ? (
                    <div className={style.modalHeader}>
                      <div className="flex items-center justify-between relative">
                        <h1 className="text-xl font-medium w-full text-body-primary">
                          {title}
                        </h1>
                        <div className={style.close_cnt} onClick={onClose}>
                          <Cross />
                        </div>
                      </div>
                      {headerExtraContent}
                    </div>
                  ) : (
                    <div
                      className={classnames(
                        style.close_cnt,
                        style.close_cnt_fixed
                      )}
                      onClick={onClose}
                    >
                      <Cross />
                    </div>
                  )}
                  <div className={style.contentScroll}>{children}</div>
                </motion.div>
              </div>
            </div>
          )}
        </AnimatePresence>,
        portalRef.current
      )}
    </>
  ) : null;
};

export default Modal;
