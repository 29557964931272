import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import styles from "./index.module.css";
import Button from "@/components/core/button";
import { useFormik } from "formik";
import InputField from "@/components/core/input-field";
import { SpinnerDots } from "@/components/common/spinner";
import {
  useTokenStore,
  useAirdropStore,
  useWallet
} from "@/providers/BaseStoresProvider";
import { TokenModel } from "@/models/TokenModel";
import TokenSelect from "@/components/common/token-select";
import { observer } from "mobx-react-lite";
import Container from "@/components/core/container";
import BreadCrumbs from "@/components/common/breadcrumbs";
import DragnDrop from "@/components/core/dragnDrop";
import ROUTES from "@/routes";
import { NATIVE_SYMBOL } from "@/utils/constants";
import { validateAirdrop as validate } from "@/utils/validate";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { everscaleAddressPattern } from "@/utils/constants";

interface IProps {}

const ManageVestingPage: React.FC<IProps> = () => {
  const [getFormValues, setFormValues] = useState<Record<string, any>>({
    users: [],
    amounts: []
  });
  const [invalidUsers, setInvalidUsers] = useState<number[]>([0]);
  const [invalidAmounts, setInvalidAmounts] = useState<number[]>([]);
  const [enableValidation, setEnableValidation] = useState(false);
  const [getTotalAirdropUsers, setTotalAirdropUsers] = useState<number>(1);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [extraFieldsInvalid, setExtraFieldsInvalid] = useState<boolean>(false);
  const [getSelectTokenVal, setTokenVal] = useState<TokenModel | null>(null);
  const tokenStore = useTokenStore();
  const airdropStore = useAirdropStore();
  const wallet = useWallet();
  const intl = useIntl();
  const navigate = useNavigate();

  const breadLinks = [
    {
      name: intl.formatMessage({
        id: "breadcrumbs.airdrop",
        defaultMessage: "Airdrop"
      }),
      active: false,
      link: ROUTES.airdrop.path
    },
    {
      name: intl.formatMessage({
        id: "breadcrumbs.airdrop",
        defaultMessage: "Create new airdrop"
      }),
      active: true,
      link: `${ROUTES.airdrop.path}/${ROUTES.airdrop.create.path}`
    }
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      token_address: ""
    },
    validateOnBlur: enableValidation,
    validateOnChange: enableValidation,
    validate,
    onSubmit: async (params) => {
      if (invalidUsers.length > 0 || invalidAmounts.length > 0) {
        setExtraFieldsInvalid(true);
        return;
      }

      setLoading(true);

      try {
        const result = await airdropStore.createNewAirdrop({
          name: params.name,
          token_address: params.token_address,
          decimals: getSelectTokenVal?.decimals ?? 0,
          isNative: getSelectTokenVal?.name === NATIVE_SYMBOL ? true : false,
          airdrop_recipients: getFormValues.users,
          airdrop_amounts: getFormValues.amounts
        });
        setLoading(false);
        if (!result) return;
        if (result) navigate(ROUTES.airdrop.path);
      } catch (err) {
        throw err;
      }
    }
  });

  const tokenChange = (val: TokenModel | null) => {
    setTokenVal(val);
    formik.setFieldValue("token_address", val?.address);
  };

  const reqTokens = useCallback(() => {
    const func = async () => {
      await tokenStore.loadUserTokens();
      if (!tokenStore.filteredUserTokens) return;
    };
    func();
  }, [tokenStore]);

  useEffect(() => {
    if (tokenStore.filteredUserTokens?.length) return;
    reqTokens();
  }, [tokenStore, reqTokens]);

  const setCsvData = (data: string) => {
    const arr = data
      .split(/\r\n|\n/)
      .map((_) => _.trim().split(","))
      .filter((_) => _.every((item) => item));

    const addresses: string[] = [];
    const amounts: string[] = [];

    arr.forEach((line: string[]) => {
      return line.forEach((_) => {
        return everscaleAddressPattern.test(_)
          ? addresses.push(_)
          : amounts.push(_);
      });
    });

    addresses.forEach((_, key) => {
      handleUserChange("users", key, _, false);
    });

    setFormValues({
      users: addresses,
      amounts
    });
    setTotalAirdropUsers(() => Math.max(amounts.length, addresses.length));
  };

  const addNewRecipient = () => {
    setTotalAirdropUsers((state) => {
      handleUserChange("amounts", state, "");
      handleUserChange("users", state, "");
      return state + 1;
    });
  };

  const removeRecipient = (index: number) => {
    setFormValues((state) => {
      const obj = { ...state };
      obj.users.splice(index, 1);
      obj.amounts.splice(index, 1);
      return obj;
    });
    setTotalAirdropUsers((state) => {
      return state - 1;
    });

    // validation
    // setInvalidAmounts((s) => {
    //   const arr = [...s];
    //   const indexOf = arr.indexOf(index);

    //   arr.splice(indexOf, 1);
    //   return arr;
    // });
    setInvalidUsers((s) => {
      const arr = [...s];
      const indexOf = arr.indexOf(index);

      arr.splice(indexOf, 1);
      return arr;
    });
  };

  const handleUserChange = (
    type: "users" | "amounts",
    index: number,
    value: string,
    updateValues = true
  ) => {
    const val = value;

    // validation of amount fields
    // const numbAfterDot = type === "amounts" ? decimalCount(value) : 0;

    // if (
    //   type === "amounts" &&
    //   !invalidAmounts.includes(index) &&
    //   getSelectTokenVal &&
    //   numbAfterDot > getSelectTokenVal.decimals
    // ) {
    //   setInvalidAmounts((s) => [...s, index]);
    // }
    // if (type === "amounts" && !val) {
    //   setInvalidAmounts((s) => [...s, index]);
    // }

    // if (
    //   type === "amounts" &&
    //   invalidAmounts.includes(index) &&
    //   getSelectTokenVal &&
    //   numbAfterDot <= getSelectTokenVal.decimals
    // ) {
    //   setInvalidAmounts((s) => {
    //     const arr = [...s];
    //     const indexOf = arr.indexOf(index);

    //     arr.splice(indexOf, 1);
    //     return arr;
    //   });
    // }

    // validation of user fields
    if (
      type === "users" &&
      !everscaleAddressPattern.test(val) &&
      !invalidUsers.includes(index)
    ) {
      setInvalidUsers((s) => [...s, index]);
    }

    if (
      type === "users" &&
      everscaleAddressPattern.test(val) &&
      invalidUsers.includes(index)
    ) {
      setInvalidUsers((s) => {
        const arr = [...s];
        const indexOf = arr.indexOf(index);

        arr.splice(indexOf, 1);
        return arr;
      });
    }

    // check for empty string need to handle empty inputs
    // in case 1st not filled and 2nd input filled
    if (
      !getFormValues[type][index] &&
      getFormValues[type][index] !== "" &&
      updateValues
    ) {
      setFormValues((state) => {
        const obj = { ...state };
        obj[type] = [...obj[type], val];
        return obj;
      });
    }

    if (
      getFormValues[type][index] ||
      (getFormValues[type][index] === "" && updateValues)
    ) {
      setFormValues((state) => {
        const obj = { ...state };
        obj[type][index] = val;
        return obj;
      });
    }
  };

  useEffect(() => {
    if (!enableValidation) return;

    if (
      !getFormValues.users.includes("") ||
      !getFormValues.amounts.includes("")
    ) {
      setExtraFieldsInvalid(false);
    }

    if (invalidAmounts.length === 0 && invalidUsers.length === 0) {
      setExtraFieldsInvalid(false);
    }

    if (invalidAmounts.length > 0 || invalidUsers.length > 0) {
      setExtraFieldsInvalid(true);
    }

    if (
      getFormValues.users.length === 0 ||
      getFormValues.amounts.length === 0 ||
      getFormValues.users.includes("") ||
      getFormValues.amounts.includes("")
    ) {
      setExtraFieldsInvalid(true);
    }
  }, [enableValidation, invalidAmounts, invalidUsers, getFormValues]);

  const handleBack = (e: any) => {
    const event = e.target.type;
    if (event === "submit") return;
    else navigate(ROUTES.airdrop.path);
  };

  const changeCustomAdd = (e: any) => {
    formik.setFieldValue("token_address", e.target?.value);
    setTokenVal(null);
  };

  return (
    <Container className="w-full pr-4 mt-2 pl-1">
      <BreadCrumbs list={breadLinks} />
      <div className="mt-12">
        <h3 className="font-poppins font-semibold text-3xl text-white">
          {intl.formatMessage({
            id: "airdrop.create.title",
            defaultMessage: "Create new airdrop"
          })}
        </h3>
        <p className="font-poppins text-base text-white mb-8 mt-4">
          {intl.formatMessage({
            id: "vesting.manage.title2",
            defaultMessage:
              "You can either advertise your project or incentivize your audience by simply sending your tokens to a list of addresses"
          })}
        </p>
        <h3 className="font-poppins font-medium text-xl text-white mb-6 mt-8">
          {intl.formatMessage({
            id: "airdrop.manage.subtitle",
            defaultMessage: "General information"
          })}
        </h3>
        <p className="font-poppins text-base text-white mb-8 mt-4">
          {intl.formatMessage({
            id: "vesting.manage.subtitle2",
            defaultMessage:
              "All airdrops happen simultaneously. Just hit the button and distribute your tokens to multiple addresses at once!"
          })}
        </p>
        <form
          className="mr-12 w-full"
          onSubmit={(event) => {
            setEnableValidation(true);
            event.preventDefault();
            formik.handleSubmit();
          }}
        >
          <div className="flex flex-wrap justify-between mb-5 md:w-10/12">
            <div className="w-full md:w-6/12">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.airdropName",
                  defaultMessage: "Airdrop name"
                })}
              </h3>
              <InputField
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder={intl.formatMessage({
                  id: "form.name",
                  defaultMessage: "name"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.name ?? null}
              </span>
            </div>
            <p className="text-sm text-white/50 mb-4 mt-2 md:mt-7 md:w-5/12 max-w-[360px]">
              {intl.formatMessage({
                id: "tokenForm.nameExplanation",
                defaultMessage:
                  "This name will not be changed and will represent your tokens to users in the whole Venom blockchain."
              })}
            </p>
          </div>
          <div className="flex flex-wrap justify-between mb-5 md:w-10/12">
            <div className="w-full md:w-6/12">
              <h3 className="text-base text-secondary-600 mb-3">
                {intl.formatMessage({
                  id: "input.tokenAdd",
                  defaultMessage: "Token address"
                })}
              </h3>
              <InputField
                id="tokenAdd"
                name="tokenAdd"
                onChange={changeCustomAdd}
                value={formik.values.token_address}
                placeholder={intl.formatMessage({
                  id: "form.tokenAdd",
                  defaultMessage: "tokenAdd"
                })}
              />
              <span className="text-sm text-danger-600">
                {formik.errors.token_address ?? null}
              </span>
            </div>
            <p className="text-sm text-white/50 mb-4 mt-2 md:mt-7 md:w-5/12 max-w-[360px]">
              {intl.formatMessage({
                id: "tokenForm.tokenAir",
                defaultMessage:
                  "Token address of token, which gonna be used for airdrop"
              })}
            </p>
          </div>
          <div className="flex flex-wrap justify-between mb-5 md:w-10/12">
            <div className="w-full md:w-6/12">
              <div className="mb-5">
                <h3 className="text-base text-secondary-600 mb-3">
                  {intl.formatMessage({
                    id: "input.tokenName",
                    defaultMessage: "Token Name"
                  })}
                </h3>
                <div className="w-full">
                  <TokenSelect
                    addNative
                    list={tokenStore.createdAndFilteredUserTokens}
                    value={getSelectTokenVal}
                    onChange={tokenChange}
                  />
                </div>
                <span className="text-sm text-danger-600">
                  {formik.errors.token_address ?? null}
                </span>
              </div>
            </div>
            <p className="text-sm text-white/50 mb-4 mt-2 md:mt-7 md:w-5/12 max-w-[360px]">
              {intl.formatMessage({
                id: "tokenForm.selectTokenText",
                defaultMessage: "Select the token from your list of tokens"
              })}
            </p>
          </div>
          <div className="page_separator mt-8" />
          <div className="flex justify-between md:w-10/12 mb-6 mt-8">
            <div className="flex items-center gap-4">
              <h3 className="font-poppins font-medium text-xl text-white">
                {intl.formatMessage({
                  id: "vesting.manage.recipients",
                  defaultMessage: "Recipients"
                })}
              </h3>
              {getTotalAirdropUsers > 20 && (
                <Button
                  onClick={() => {
                    const scrollingElement = document.querySelector("footer");
                    if (!scrollingElement) return;
                    scrollingElement.scrollIntoView({ behavior: "smooth" });
                  }}
                  type="button"
                  variant="primary"
                >
                  {intl.formatMessage({
                    id: "main_page.bottom",
                    defaultMessage: "Scroll to bottom"
                  })}
                </Button>
              )}
            </div>

            <Button
              variant="secondary"
              className="ml-1"
              inverse
              type="button"
              onClick={() =>
                window.open(`${process.env.REACT_APP_ORIGIN}/example.csv`)
              }
            >
              {intl.formatMessage({
                id: "btn.downloadCsv",
                defaultMessage: "Download CSV template"
              })}
              <IoCloudDownloadOutline className="w-6 h-6 ml-4" />
            </Button>
          </div>
          <div className="md:w-10/12">
            <DragnDrop onChange={setCsvData} />
          </div>
          <div className="md:w-10/12">
            {Array.from(Array(getTotalAirdropUsers)).map((_, index) => (
              <div className="flex justify-between mb-5 relative" key={index}>
                <div className="w-6/12">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {intl.formatMessage({
                      id: "input.userAddress",
                      defaultMessage: "User Address"
                    })}
                    {" #"}
                    {index + 1}
                  </h3>
                  <InputField
                    onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
                      handleUserChange("users", index, val.target.value)
                    }
                    value={getFormValues.users[index] ?? ""}
                    placeholder={intl.formatMessage({
                      id: "form.address",
                      defaultMessage: "user address"
                    })}
                  />
                  {invalidUsers.includes(index) && enableValidation && (
                    <span className="text-sm text-danger-600">
                      {intl.formatMessage({
                        id: "input.invalidUser",
                        defaultMessage: "Invalid user"
                      })}
                    </span>
                  )}
                </div>
                <div className="w-5/12">
                  <h3 className="text-base text-secondary-600 mb-3">
                    {intl.formatMessage({
                      id: "input.airdropAmount",
                      defaultMessage: "Airdrop amount"
                    })}
                  </h3>
                  <InputField
                    type="number"
                    onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
                      handleUserChange("amounts", index, val.target.value)
                    }
                    value={getFormValues.amounts[index] ?? ""}
                    placeholder={intl.formatMessage({
                      id: "form.amount",
                      defaultMessage: "amount"
                    })}
                  />
                  {invalidAmounts.includes(index) && enableValidation && (
                    <span className="text-sm text-danger-600">
                      {intl.formatMessage({
                        id: "input.invalidAmount",
                        defaultMessage: "Invalid amount"
                      })}
                    </span>
                  )}
                </div>
                {index > 0 && (
                  <div
                    className={styles.actionsUsers}
                    onClick={() => removeRecipient(index)}
                  >
                    <span>
                      {intl.formatMessage({
                        id: "input.delete",
                        defaultMessage: "Delete"
                      })}
                    </span>
                  </div>
                )}
              </div>
            ))}

            <Button
              variant="secondary"
              className="ml-1"
              inverse
              type="button"
              onClick={() => addNewRecipient()}
            >
              {intl.formatMessage({
                id: "btn.addAddress",
                defaultMessage: "+ Add address"
              })}
            </Button>
          </div>
          <div className="page_separator mt-10" />
          {isLoading ? (
            <SpinnerDots className={styles.spinner} />
          ) : (
            <div className="flex items-center justify-end mt-10 md:w-10/12 mb-20">
              <Button
                type="button"
                variant="secondary"
                inverse
                onClick={(e) => handleBack(e)}
                className="w-full max-w-[100px] mr-4"
              >
                {intl.formatMessage({
                  id: "btn.back",
                  defaultMessage: "Back"
                })}
              </Button>
              <Button
                variant="secondary"
                className="w-full max-w-[130px]"
                type="submit"
                disabled={
                  !wallet.isConnected || extraFieldsInvalid || !formik.isValid
                }
                disabledError
              >
                {intl.formatMessage({
                  id: "btn.airdrop",
                  defaultMessage: "Create airdrop"
                })}
              </Button>
            </div>
          )}
        </form>
      </div>
    </Container>
  );
};

export default observer(ManageVestingPage);
