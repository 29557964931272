import dayjs from "dayjs";
import BN from "./BN";

export const unixTsToDateStr = (timestamp: number, format = "DD.MM.YYYY") => {
  return dayjs.unix(timestamp).format(format);
};

export const getUnixTsNow = () => dayjs().unix();

export type TimeRemaining = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const getTimeRemaining = (
  endTimestamp?: number
): TimeRemaining | undefined => {
  if (!endTimestamp) {
    return undefined;
  }

  const dateEnd = dayjs.unix(endTimestamp);
  const dateNow = dayjs();

  const diff = dateEnd.diff(dateNow);

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  return {
    days,
    hours,
    minutes,
    seconds
  };
};

export const countDuration = (aStart: string, bEnd: string) => {
  const diff = new BN(
    dayjs.unix(+bEnd).diff(dayjs.unix(+aStart), "days", false)
  )
    .toDecimalPlaces(2)
    .toString();

  if (diff === "0") {
    const hoursDiff = new BN(
      dayjs.unix(+bEnd).diff(dayjs.unix(+aStart), "hours", false)
    )
      .toDecimalPlaces(2)
      .toString();

    if (+hoursDiff < 1) {
      const minutesDiff = new BN(
        dayjs.unix(+bEnd).diff(dayjs.unix(+aStart), "minutes", false)
      )
        .toDecimalPlaces(2)
        .toString();

      return {
        name: "min",
        val: minutesDiff
      };
    }

    return {
      name: "hours",
      val: hoursDiff
    };
  }

  return {
    name: "days",
    val: diff
  };
};
