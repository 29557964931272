import { makeAutoObservable, runInAction } from "mobx";
import { Id, toast, TypeOptions } from "react-toastify";

const NOTIFICATION_AUTO_CLOSE_SEC = 10;

type SidebarData = {
  show: boolean;
  content: JSX.Element | null;
};

export class LayoutStore {
  constructor() {
    makeAutoObservable(this);
  }

  sidebarData?: SidebarData = {
    show: false,
    content: null
  };

  toggleSidebar(show: boolean, content?: JSX.Element) {
    runInAction(() => {
      this.sidebarData = {
        show: show,
        content: content ?? null
      };
    });
  }

  addProgressNotification(content: JSX.Element | string) {
    return toast(content, {
      closeButton: false,
      closeOnClick: false,
      autoClose: false
    });
  }

  finishProgressNotification({
    id,
    type,
    content
  }: {
    id: Id;
    type: TypeOptions;
    content: JSX.Element | string;
  }) {
    toast.update(id, {
      render: content,
      type: type,
      isLoading: false,
      closeButton: true,
      autoClose: NOTIFICATION_AUTO_CLOSE_SEC * 1000
    });
  }

  removeNotification(id: Id) {
    toast.dismiss(id);
  }
}
