import { useEffect, useState } from "react";
import ROUTES from "@/routes";
import styles from "./index.module.css";
import AccountInfo from "../account-info";
import { ReactComponent as Logo } from "@/assets/logo.svg";
import mobileMenuIcon from "@/assets/icons/mobileMenu.svg";
import closeIcon from "@/assets/icons/close.svg";
import useWindowSize from "@/hooks/useWindowSize";
import { useWallet } from "@/providers/BaseStoresProvider";

import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import Navbar from "../navbar";

const Header = observer(() => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
  const { width } = useWindowSize();
  const wallet = useWallet();

  const toggleMenu = (state: boolean) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.body.classList.toggle("noscroll", state);
    setMobileMenuOpened(state);
  };

  useEffect(() => {
    if (width && width > 1024) {
      setMobileMenuOpened(true);
    }
  }, [width]);

  return (
    <div className={styles.header}>
      <Navbar
        onClose={() => toggleMenu(!mobileMenuOpened)}
        showNavbar={mobileMenuOpened}
      />
      <div className={styles.header_container}>
        <Link to={ROUTES.index.path} className={styles.logo}>
          <Logo />
        </Link>

        <div className="flex justify-between items-center relative">
          <AccountInfo hideAccount />
          {wallet.isConnected && (
            <img
              onClick={() => toggleMenu(!mobileMenuOpened)}
              className={classNames(
                "ml-6 hover:scale-110 cursor-pointer transition-transform lg:hidden",
                mobileMenuOpened ? "w-5" : "w-7"
              )}
              src={mobileMenuOpened ? closeIcon : mobileMenuIcon}
              alt="menuControl"
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default Header;
