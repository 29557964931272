const ROUTES = {
  index: { path: "/" },
  marketing: { path: "/marketing" },
  tokens: {
    path: "/tokens",
    create: {
      path: "create"
    },
    mint: { path: "mint" },
    burn: { path: "burn" },
    transfer: { path: "transfer" }
  },
  vesting: {
    path: "/vesting",
    create: { path: "create" },
    claim: { path: "claim" }
  },
  airdrop: {
    path: "/airdrop",
    create: { path: "create" }
  }
};

export default ROUTES;
