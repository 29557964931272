import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import ROUTES from "@/routes";
import styles from "./index.module.css";
import { useTokenStore, useVestingStore } from "@/providers/BaseStoresProvider";

import { convertAmount } from "@/utils/BN";
import { unixTsToDateStr } from "@/utils/dates";
import { cutString } from "@/utils/strings";

import Container from "@/components/core/container";
import { useWallet } from "@/providers/BaseStoresProvider";
import BreadCrumbs from "@/components/common/breadcrumbs";
import { Spinner } from "@/components/common/spinner";
import Tooltip from "@/components/common/tooltip";

import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";
import { VENOM_SCAN } from "@/utils/constants";

interface IProps {}

const VestingDetailedPage: React.FC<IProps> = () => {
  const [getVesting, setCurrentVesting] = useState<any | null>(null);
  const { contractId } = useParams();
  const [isLoading, setLoading] = useState<boolean>(true);
  const intl = useIntl();

  const walletStore = useWallet();
  const tokenStore = useTokenStore();
  const vestingStore = useVestingStore();

  const breadLinks = [
    {
      name: intl.formatMessage({
        id: "breadcrumbs.vesting",
        defaultMessage: "Vesting"
      }),
      active: false,
      link: ROUTES.vesting.path
    },
    {
      name: intl.formatMessage({
        id: "breadcrumbs.create",
        defaultMessage: "Create new vesting"
      }),
      active: true,
      link: `${ROUTES.vesting.path}/${contractId}`
    }
  ];

  useEffect(() => {
    const findVesting = () => {
      const curVesting = vestingStore.allUserVestings.find(
        (item) => item.vesting.toString() === contractId
      );
      setLoading(false);
      setCurrentVesting(curVesting);
    };
    if (vestingStore.allUserVestings?.length > 0) findVesting();
    const reqFactoryState = async () => {
      if (tokenStore.contractsFactoryAddress) {
        await vestingStore.getAllVestings();
        findVesting();
      }
    };
    reqFactoryState();
  }, [
    tokenStore,
    tokenStore.contractsFactoryAddress,
    vestingStore,
    walletStore.isConnected,
    contractId
  ]);

  return (
    <Container className="w-8/12 pr-4 mt-2 pl-1">
      <BreadCrumbs list={breadLinks} />
      {getVesting?.name && (
        <div className={styles.header}>
          <span className="font-poppins font-semibold text-3xl text-white mr-8">
            {getVesting?.name}
          </span>
        </div>
      )}
      {isLoading ? (
        <div className="w-full flex items-center justify-center mt-44">
          <Spinner className={styles.spinner} />
        </div>
      ) : (
        <>
          <div className="my-8 lg:w-4/12">
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "vesting.details.token",
                  defaultMessage: "Token"
                })}
              </span>
              <span className="flex items-center font-sm text-white mr-5">
                <img
                  alt="logo"
                  src="/venom_logo.svg"
                  className="mr-2 w-5 h-5"
                />
                {getVesting?.symbol?.length > 10 ? (
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={getVesting?.symbol}
                  >
                    <span>
                      {cutString(getVesting?.symbol.toString(), 5, 4)}
                    </span>
                  </Tooltip>
                ) : (
                  getVesting?.symbol
                )}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "vesting.details.amount",
                  defaultMessage: "Amount"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {convertAmount(getVesting?.amount, getVesting?.decimals)}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "vesting.details.tokenAddress",
                  defaultMessage: "Token address"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {cutString(getVesting?.token?.toString(), 5, 4)}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "vesting.details.start",
                  defaultMessage: "Start"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {unixTsToDateStr(getVesting?.start, "DD.MM.YYYY HH:mm")}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "vesting.details.end",
                  defaultMessage: "End"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {unixTsToDateStr(getVesting?.end, "DD.MM.YYYY HH:mm")}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "vesting.details.periodic",
                  defaultMessage: "Periodic"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {intl.formatMessage({
                  id: "vesting.details.no",
                  defaultMessage: "no"
                })}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="font-sm text-white/50 mr-12">
                {intl.formatMessage({
                  id: "vesting.details.linear",
                  defaultMessage: "Linear"
                })}
              </span>
              <span className="font-sm text-white mr-5">
                {intl.formatMessage({
                  id: "vesting.details.yes",
                  defaultMessage: "Yes"
                })}
              </span>
            </div>
          </div>
          <div className="font-poppins font-medium text-xl text-white mt-2 mb-4">
            {intl.formatMessage({
              id: "aidrop.recipients",
              defaultMessage: "Recipients"
            })}
          </div>

          <table className="table-bordered table-bordered--nohover">
            <thead className="text-2xl">
              <tr>
                <th>
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={
                      <span className="text-sm text-white">
                        {intl.formatMessage({
                          id: "airdrop.detailed.nameTooltip1",
                          defaultMessage: "Recipients address"
                        })}
                      </span>
                    }
                  >
                    <div className="flex items-center">
                      <span>
                        {intl.formatMessage({
                          id: "airdrop.table.address",
                          defaultMessage: "ADDRESS"
                        })}
                      </span>
                      <InfoIcon className="ml-4" />
                    </div>
                  </Tooltip>
                </th>
                <th>
                  <Tooltip
                    config={{ placement: "top-start" }}
                    content={
                      <span className="text-sm text-white">
                        {intl.formatMessage({
                          id: "airdrop.detailed.nameTooltip2",
                          defaultMessage:
                            "The number of tokens that are subject to an airdrop"
                        })}
                      </span>
                    }
                  >
                    <div className="flex items-center">
                      <span>
                        {intl.formatMessage({
                          id: "airdrop.table.tokens",
                          defaultMessage: "TOKENS"
                        })}
                      </span>
                      <InfoIcon className="ml-4" />
                    </div>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody className="table-bordered__body">
              {getVesting && (
                <tr>
                  <td>
                    <a
                      className="text-green-100 underline hover:no-underline"
                      href={`${VENOM_SCAN}/accounts/${getVesting.user.toString()}/holders`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getVesting.user?.toString()}
                    </a>
                  </td>
                  <td>
                    <div className="flex">
                      <span className="mr-1">
                        {convertAmount(getVesting.amount, getVesting.decimals)}{" "}
                      </span>

                      {getVesting?.symbol?.length > 10 ? (
                        <div className="text-ellipsis overflow-hidden max-w-[150px]">
                          <Tooltip
                            config={{ placement: "top-start" }}
                            content={getVesting?.symbol}
                          >
                            <span>
                              {cutString(getVesting?.symbol.toString(), 5, 4)}
                            </span>
                          </Tooltip>
                        </div>
                      ) : (
                        <span>{getVesting?.symbol}</span>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </Container>
  );
};

export default observer(VestingDetailedPage);
