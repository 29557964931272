import { makeAutoObservable, runInAction } from "mobx";
import { WalletStore } from "@/stores/WalletStore";
import { TokenStore } from "@/stores/TokenStore";
import { NotificationStore } from "@/stores/NotificationStore";
import { Drop3FactoryAbi } from "@/abi/drop3-factory";
import { Address } from "everscale-inpage-provider";
import { LinearVestAbi } from "@/abi/linear-vesting";
import { Drop3RepoAbi } from "@/abi/drop3-repo";
import {
  toCoin,
  CONTRACTS_REPO_ADDRESS,
  WEVER_ADDRESS,
  WEVER_VAULT
} from "@/utils/constants";
import BN, { decimalsZeros, decimalCount } from "@/utils/BN";
import { useStaticRpc } from "@/hooks/useStaticRpc";

export class VestingStore {
  constructor(
    private wallet: WalletStore,
    private tokenStore: TokenStore,
    private intl: any,
    private notifications: NotificationStore
  ) {
    makeAutoObservable(this);
  }

  // todo: types
  public allClaimContractsData: any[] = [];
  public allUserVestings: any[] = [];

  resetStore() {
    this.allUserVestings = [];
    this.allClaimContractsData = [];
  }

  async getAllVestings(): Promise<boolean> {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot getAllVestings. Wallet is not connected");
    }
    if (!this.tokenStore.contractsFactoryAddress) return false;
    const drop3FactoryContract = new this.wallet.provider.Contract(
      Drop3FactoryAbi,
      new Address(this.tokenStore.contractsFactoryAddress)
    );
    try {
      const pastEvents = await drop3FactoryContract.getPastEvents({});
      const eventsVestings = pastEvents.events.filter((item) => {
        const isContainKey = Object.keys(item.data).find(
          (key) => key === "vesting"
        );
        return isContainKey ? item : false;
      });

      const vestings = await Promise.all(
        eventsVestings.map(async (_: any) => {
          if (!this.wallet.provider) return;
          const linearVestingContract = new this.wallet.provider.Contract(
            LinearVestAbi,
            _?.data?.vesting
          );
          const vestingCreated = await linearVestingContract.methods
            .createdAt({})
            .call();
          const vestingName = await linearVestingContract.methods
            .name({})
            .call();
          const tokenData = await this.tokenStore.getTokenData(
            _?.data?.token.toString(),
            false
          );
          if (!tokenData) {
            return {
              ..._?.data,
              createdAt: vestingCreated.createdAt,
              name: vestingName.name,
              symbol: "",
              decimals: 0,
              isTokensFilled: false
            };
          }
          const tokenBalance = (
            await this.wallet.getTokenBalance(tokenData, _?.data?.vesting)
          ).toString();

          const vestingData = {
            ..._?.data,
            createdAt: vestingCreated.createdAt,
            name: vestingName.name,
            tokenName: tokenData.name,
            symbol: tokenData?.symbol,
            decimals: tokenData?.decimals,
            isTokensFilled: tokenBalance !== "0" ? true : false
          };

          return vestingData;
        })
      );

      runInAction(() => {
        this.allUserVestings = vestings;
      });

      return true;
    } catch (err) {
      console.log(err, "getAllVestings");
      return false;
    }
  }

  async createNewVesting(params: {
    name: string;
    address: string;
    decimals: number;
    token_address: string;
    vesting_amount: number;
    vesting_start: number;
    vesting_end: number;
    isNative: boolean;
  }): Promise<boolean> {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot getVestingFactory. Wallet is not connected");
    }
    if (!WEVER_ADDRESS || !WEVER_VAULT) return false;
    const drop3RepoContract = new this.wallet.provider.Contract(
      Drop3RepoAbi,
      CONTRACTS_REPO_ADDRESS
    );

    const numbAfterDot = decimalCount(params?.vesting_amount.toString());

    if (numbAfterDot > params.decimals) {
      this.notifications.notify("Too many numbers after dot", {
        type: "error",
        title: `Token decimals maximum is ${params.decimals}`,
        duration: 3
      });
      return false;
    }

    try {
      const deployPrice = await drop3RepoContract.methods
        .getDeployVestingValue({})
        .call();

      const amount = new BN(params.vesting_amount)
        .times(decimalsZeros(params.decimals))
        .toNumber();

      const payload = await drop3RepoContract.methods
        .getDeployVestingPayload({
          name: params.name,
          wever_token: new Address(WEVER_ADDRESS),
          wever_vault: new Address(WEVER_VAULT),
          user: new Address(params.address),
          token: new Address(params.token_address),
          vesting_amount: amount,
          vesting_start: params.vesting_start,
          vesting_end: params.vesting_end
        })
        .call();

      let resp = null;
      const flooredVal = Math.ceil(+BN.formatUnits(+deployPrice.value, 9));

      if (!params.isNative) {
        resp = await this.tokenStore.transferTokensToContract({
          contract: CONTRACTS_REPO_ADDRESS,
          token: new Address(params.token_address),
          amount: amount,
          payload: payload.value0,
          deployWalletValue: 0.5,
          coins: BN.formatUnits(deployPrice.value, 9).toNumber()
        });
      } else {
        resp = await drop3RepoContract.methods
          .wEverOccur({
            amount: amount,
            payload: payload.value0
          })
          .send({
            from: this.wallet.account.address,
            amount: new BN(flooredVal)
              .times(decimalsZeros(params.decimals))
              .plus(amount)
              .toString(),
            bounce: true
          });
      }

      if (resp) {
        this.notifications.notify("Now you can check all vestings page", {
          type: "success",
          title: "New vesting created!",
          duration: 5
        });
        return true;
      } else return false;
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      return false;
    }
  }

  async claimVesting(contractAdd: Address) {
    try {
      if (!this.wallet.provider || !this.wallet.account) {
        throw new Error("Cannot claimVesting. Wallet is not connected");
      }
      const linearVestingContract = new this.wallet.provider.Contract(
        LinearVestAbi,
        contractAdd
      );

      await linearVestingContract.methods.claim({}).send({
        from: this.wallet.account.address,
        amount: toCoin(1),
        bounce: true
      });
      this.notifications.notify("Successfully claimed", {
        type: "success",
        title: "Vesting claimed!",
        duration: 5
      });
      return true;
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      return false;
    }
  }

  async getClaimContracts() {
    try {
      const staticRpc = useStaticRpc();
      if (!this.wallet.provider || !this.wallet.account) {
        throw new Error("Cannot getVestingFactory. Wallet is not connected");
      }
      const drop3Contract = new this.wallet.provider.Contract(
        Drop3RepoAbi,
        CONTRACTS_REPO_ADDRESS
      );
      const codeResult = await drop3Contract.methods
        .linearVestingCode({})
        .call();

      if (!staticRpc) return;
      const saltData = await staticRpc.packIntoCell({
        structure: [{ name: "data", type: "address" } as const] as const,
        data: {
          data: this.wallet.account.address
        }
      });

      const saltTx = await staticRpc.setCodeSalt({
        code: codeResult?.linearVestingCode,
        salt: saltData.boc
      });

      const bocHash = await staticRpc.getBocHash(saltTx.code);

      const accountsData = await staticRpc.getAccountsByCodeHash({
        codeHash: bocHash
      });

      const claimContractsData = await Promise.all(
        accountsData.accounts.map(async (_: Address) => {
          if (!this.wallet.provider) return _;
          const linearVestingContract = new this.wallet.provider.Contract(
            LinearVestAbi,
            _
          );
          const pendingVested = await linearVestingContract.methods
            .pendingVested({})
            .call();
          const details = await linearVestingContract.methods
            .getDetails({})
            .call();
          const tokenData = await this.tokenStore.getTokenData(
            details?._token.toString(),
            false
          );
          return {
            ...details,
            contract: _,
            pendingVest: pendingVested.tokens_to_claim,
            symbol: tokenData?.symbol,
            decimals: tokenData?.decimals
          };
        })
      );
      runInAction(() => {
        this.allClaimContractsData = claimContractsData.sort(
          (a: any, b: any) => +b?._vestingStart - a?._vestingStart
        );
      });
    } catch (err) {
      // this.notifications.notify("Smth went wrong.", {
      //   type: "error",
      //   title: "error",
      //   duration: 5
      // });
      throw err;
    }
  }

  async checkForVestingBalance(vesting: string): Promise<boolean> {
    if (!this.wallet.provider || !this.wallet.account) return false;
    return true;
  }
}
