import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import ROUTES from "@/routes";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";

import {
  useVestingStore,
  useNotifyStore,
  useWallet
} from "@/providers/BaseStoresProvider";

import { cutString } from "@/utils/strings";
import { unixTsToDateStr } from "@/utils/dates";
import BN, { convertBigAmount } from "@/utils/BN";

import Button from "@/components/core/button";
import { Spinner, SpinnerDots } from "@/components/common/spinner";
import BreadCrumbs from "@/components/common/breadcrumbs";
import Container from "@/components/core/container";
import Tooltip from "@/components/common/tooltip";

import { ReactComponent as CopyIcon } from "@/assets/icons/copy.svg";
import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";
import { VENOM_SCAN } from "@/utils/constants";

interface IProps {}

const ClaimPage: React.FC<IProps> = () => {
  const [isLoading, setLoading] = useState<string | undefined>(undefined);
  // contract returning pendingVest still exist after claiming
  // for such case we disabling btn for at least 20s
  const [disabledArr, setDisabled] = useState<string[]>([]);
  const [claimsLoading, setClaimsLoading] = useState<boolean>(true);
  const intl = useIntl();
  const vestingStore = useVestingStore();
  const wallet = useWallet();
  const noficiations = useNotifyStore();

  const breadLinks = [
    {
      name: intl.formatMessage({
        id: "breadcrumbs.vesting",
        defaultMessage: "Vesting"
      }),
      active: false,
      link: ROUTES.vesting.path
    },
    {
      name: intl.formatMessage({
        id: "breadcrumbs.claim",
        defaultMessage: "Claim vesting"
      }),
      active: true,
      link: `${ROUTES.vesting.path}/${ROUTES.vesting.claim.path}`
    }
  ];

  useEffect(() => {
    if (!wallet.isConnected) {
      setClaimsLoading(false);
      return;
    }
    if (vestingStore.allClaimContractsData.length > 0) {
      setClaimsLoading(false);
      return;
    }

    const reqFactoryState = async () => {
      setClaimsLoading(true);
      await vestingStore.getClaimContracts();
      setClaimsLoading(false);
    };

    const intervalId = setInterval(reqFactoryState, 20000);

    reqFactoryState();

    return () => {
      clearInterval(intervalId);
    };
  }, [vestingStore, wallet.isConnected]);

  const countToClaim = (
    lastClaim: string,
    vestingStart: string,
    vestingEnd: string,
    vested: boolean,
    tokenBalance: string,
    amount: string,
    pendingVest: string,
    decimals: number
  ) => {
    // if claim time not started yet
    if (dayjs().unix() < +vestingStart && pendingVest === "0") return 0;
    // if claim already finished, and still not FULLY claimed, return contract balance
    if (dayjs().unix() > +vestingEnd && pendingVest !== "0" && !vested) {
      return convertBigAmount(pendingVest, decimals);
    }

    const passed = dayjs().unix() - +lastClaim;
    const period = +vestingEnd - +lastClaim;

    const val = new BN(amount).times(passed).div(period);

    // console.log(+val, amount, "VAL AMOUNT");
    if (+val > +amount) return convertBigAmount(amount, decimals);
    return convertBigAmount(val.toString(), decimals);
  };

  const handleCopyAddress = (address: string) => {
    address && copy(address);
    noficiations.notify("", {
      type: "success",
      title: "Address was copied"
    });
  };

  return (
    <Container className="w-8/12 pr-4 mt-2 pl-1">
      <BreadCrumbs list={breadLinks} />

      {claimsLoading && vestingStore.allClaimContractsData.length === 0 ? (
        <div className="w-full flex items-center justify-center h-[50vh]">
          <Spinner className="max-w-[240px]" />
        </div>
      ) : (
        <div className="mt-12">
          <span className="font-poppins font-semibold text-3xl text-white">
            {intl.formatMessage({
              id: "vesting.titleClaim",
              defaultMessage: "Claim"
            })}
          </span>
          <h3 className="font-poppins font-medium text-xl text-white mt-8 mb-4">
            {intl.formatMessage({
              id: "vesting.availableToClaim",
              defaultMessage: "Available to claim"
            })}
          </h3>
          {vestingStore?.allClaimContractsData?.length === 0 ? (
            <div className="text-base text-white/50 mt-4 mb-12">
              {intl.formatMessage({
                id: "vesting.table.noClaims",
                defaultMessage:
                  "At the moment, there are no vested tokens available for you to claim"
              })}
            </div>
          ) : (
            <table className="table-bordered table-bordered--nohover">
              <thead className="text-2xl">
                <tr>
                  <th>
                    {intl.formatMessage({
                      id: "vesting.table.tokenAddress",
                      defaultMessage: "TOKEN"
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: "vesting.table.start",
                      defaultMessage: "START DATE"
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: "vesting.table.end",
                      defaultMessage: "END DATE"
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: "vesting.table.tokens",
                      defaultMessage: "TOTALLY TOKENS"
                    })}
                  </th>
                  <th>
                    {intl.formatMessage({
                      id: "vesting.table.cliff",
                      defaultMessage: "TO CLAIM"
                    })}
                  </th>
                  <th>
                    <Tooltip
                      config={{ placement: "top-start" }}
                      content={
                        <span className="text-sm text-white">
                          {intl.formatMessage({
                            id: "claim.basicCost",
                            defaultMessage:
                              "Basic cost is 1 native coin, rest of it, gonna be returned on tx complete"
                          })}
                        </span>
                      }
                    >
                      <div className="flex relative">
                        {intl.formatMessage({
                          id: "vesting.table.action",
                          defaultMessage: "ACTION"
                        })}
                        <InfoIcon className="absolute -top-2 -right-4" />
                      </div>
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody className="table-bordered__body">
                {vestingStore?.allClaimContractsData.length &&
                  vestingStore.allClaimContractsData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span className="flex items-center">
                          <a
                            className="text-green-100 underline hover:no-underline"
                            href={`${VENOM_SCAN}/accounts/${item._token.toString()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {cutString(item._token.toString(), 5, 6)}
                          </a>
                          <CopyIcon
                            className="copy"
                            onClick={() => handleCopyAddress(item._token)}
                          />
                        </span>
                      </td>
                      <td>
                        {unixTsToDateStr(
                          item._vestingStart,
                          "DD.MM.YYYY HH:mm"
                        )}
                      </td>
                      <td>
                        {unixTsToDateStr(item._vestingEnd, "DD.MM.YYYY HH:mm")}
                      </td>
                      <td>
                        <div className="flex">
                          <span className="mr-1">
                            {convertBigAmount(
                              item._vestingAmount,
                              item.decimals
                            )}
                          </span>

                          {item?.symbol?.length > 10 ? (
                            <div className="text-ellipsis overflow-hidden max-w-[150px]">
                              <Tooltip
                                config={{ placement: "top-start" }}
                                content={item?.symbol}
                              >
                                <span>
                                  {cutString(item?.symbol.toString(), 5, 4)}
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            <span>{item?.symbol}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="flex">
                          <span className="mr-1">
                            {item._vested
                              ? 0
                              : countToClaim(
                                  item._lastClaimTime,
                                  item._vestingStart,
                                  item._vestingEnd,
                                  item._vested,
                                  item._tokenBalance,
                                  item._vestingAmount,
                                  item.pendingVest,
                                  item.decimals
                                )}
                          </span>

                          {item?.symbol?.length > 10 ? (
                            <div className="text-ellipsis overflow-hidden max-w-[150px]">
                              <Tooltip
                                config={{ placement: "top-start" }}
                                content={item?.symbol}
                              >
                                <span>
                                  {cutString(item?.symbol.toString(), 5, 4)}
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            <span>{item?.symbol}</span>
                          )}
                        </div>
                      </td>
                      <td>
                        {isLoading === item.contract ? (
                          <SpinnerDots className="flex mx-auto w-12 h-12 max-w-[40px]" />
                        ) : (
                          <Button
                            onClick={async () => {
                              setLoading(item.contract);
                              setDisabled((s) => {
                                const arr = s;
                                arr.push(item.contract?.toString());
                                return arr;
                              });
                              await vestingStore.claimVesting(item.contract);
                              await vestingStore.getClaimContracts();

                              // bug with pendingVest, just a workaround on front
                              setTimeout(() => {
                                setDisabled((s) => {
                                  const arr = [...s];
                                  const indexOf = disabledArr.indexOf(
                                    item.contract?.toString()
                                  );

                                  arr.splice(indexOf, 1);
                                  return arr;
                                });
                              }, 20000);
                            }}
                            // todo: pendingVest works only for native token
                            disabled={
                              disabledArr.includes(item.contract?.toString()) ||
                              item._vested ||
                              (item.symbol === "TWVENOM" &&
                                item.pendingVest === "0")
                            }
                            variant="secondary"
                          >
                            {intl.formatMessage({
                              id: "vesting.claim",
                              defaultMessage: "Claim"
                            })}
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </Container>
  );
};

export default observer(ClaimPage);
