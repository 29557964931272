import { makeAutoObservable, runInAction } from "mobx";
import { WalletStore } from "@/stores/WalletStore";
import { TokenStore } from "@/stores/TokenStore";
import { NotificationStore } from "@/stores/NotificationStore";
import { Address } from "everscale-inpage-provider";
import { Drop3AirdropAbi } from "@/abi/drop3-airdrop";
import { Drop3RepoAbi } from "@/abi/drop3-repo";
import {
  toCoin,
  CONTRACTS_REPO_ADDRESS,
  WEVER_ADDRESS,
  WEVER_VAULT
} from "@/utils/constants";
import BN, { decimalsZeros, decimalCount } from "@/utils/BN";
import { useStaticRpc } from "@/hooks/useStaticRpc";

export class AirdropStore {
  constructor(
    private wallet: WalletStore,
    private tokenStore: TokenStore,
    private intl: any,
    private notifications: NotificationStore
  ) {
    makeAutoObservable(this);
  }

  // todo: types
  public allUserAirdrops: any[] = [];

  resetStore() {
    this.allUserAirdrops = [];
  }

  async startAirdrop(contract: Address) {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot startAirdrop. Wallet is not connected");
    }
    const drop3AirdropContract = new this.wallet.provider.Contract(
      Drop3AirdropAbi,
      contract
    );

    try {
      await drop3AirdropContract.methods.distribute({}).send({
        from: this.wallet.account.address,
        amount: toCoin(2),
        bounce: true
      });

      this.notifications.notify(
        "You can already notify recipients of airdrop",
        {
          type: "success",
          title: "Airdrop distributed!",
          duration: 5
        }
      );
      return true;
    } catch (err: any) {
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      return false;
    }
  }

  async getAllAidrops() {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot getAllAidrops. Wallet is not connected");
    }
    const staticRpc = useStaticRpc();

    const drop3FactoryContract = new this.wallet.provider.Contract(
      Drop3RepoAbi,
      CONTRACTS_REPO_ADDRESS
    );

    try {
      const codeResult = await drop3FactoryContract.methods
        .airdropCode({})
        .call();

      if (!staticRpc) return;

      const saltData = await staticRpc.packIntoCell({
        structure: [{ name: "data", type: "address" } as const] as const,
        data: {
          data: this.wallet.account.address
        }
      });

      const saltTx = await staticRpc.setCodeSalt({
        code: codeResult?.airdropCode,
        salt: saltData.boc
      });

      const bocHash = await staticRpc.getBocHash(saltTx.code);
      const accountsData = await staticRpc.getAccountsByCodeHash({
        codeHash: bocHash
      });

      const airdrops = await Promise.all(
        accountsData.accounts.map(async (_: Address) => {
          if (!this.wallet.provider) return _;
          const airdropContract = new this.wallet.provider.Contract(
            Drop3AirdropAbi,
            _
          );
          const nameData = await airdropContract.methods.name({}).call();
          const date = await airdropContract.methods.creationDate({}).call();
          const amountsData = await airdropContract.methods.amounts({}).call();
          const depositData = await airdropContract.methods.deposit({}).call();
          const addressesData = await airdropContract.methods
            .addresses({})
            .call();
          const refund_end = await airdropContract.methods
            .refund_lock_duration_end({})
            .call();
          const token = await airdropContract.methods
            .tokenRootAddress({})
            .call();

          const tokenData = await this.tokenStore.getTokenData(
            token.tokenRootAddress.toString(),
            false
          );

          if (!tokenData) return null;

          const tokenBalance = (
            await this.wallet.getTokenBalance(tokenData, _)
          ).toString();
          return {
            contract: _,
            tokenName: tokenData.name,
            name: nameData.name,
            airdrop_amounts: amountsData.amounts,
            total_amount: amountsData.amounts.reduce((a, b) => {
              return Number(a) + Number(b);
            }, 0),
            airdrop_recipients: addressesData.addresses,
            date: date.creationDate,
            token: token.tokenRootAddress,
            deposit: depositData.deposit,
            isTokensFilled: tokenBalance !== "0" ? true : false,
            refund_end: refund_end.refund_lock_duration_end,
            symbol: tokenData?.symbol,
            decimals: tokenData?.decimals
          };
        })
      );
      runInAction(() => {
        this.allUserAirdrops = airdrops;
      });
    } catch (err) {
      throw err;
    }
  }

  async createNewAirdrop(params: {
    name: string;
    decimals: number;
    token_address: string;
    airdrop_recipients: string[];
    airdrop_amounts: string[];
    isNative: boolean;
  }): Promise<boolean> {
    if (!this.wallet.provider || !this.wallet.account) {
      throw new Error("Cannot createNewAirdrop. Wallet is not connected");
    }
    if (!WEVER_ADDRESS || !WEVER_VAULT) return false;
    const drop3RepoContract = new this.wallet.provider.Contract(
      Drop3RepoAbi,
      CONTRACTS_REPO_ADDRESS
    );

    const recipients = params.airdrop_recipients.map((_) => new Address(_));

    if (
      params.airdrop_recipients?.length < params.airdrop_amounts?.length ||
      params.airdrop_recipients?.length === 0
    ) {
      this.notifications.notify("Please fill all address fields", {
        type: "error",
        title: "Address field is empty",
        duration: 3
      });
      return false;
    }

    if (
      params.airdrop_recipients?.length > params.airdrop_amounts?.length ||
      params.airdrop_recipients?.length === 0
    ) {
      this.notifications.notify("Please fill all amounts fields", {
        type: "error",
        title: "Amount field is empty",
        duration: 3
      });
      return false;
    }

    let isChecked = true;

    params.airdrop_amounts.forEach((_) => {
      const numbAfterDot = decimalCount(_.toString());

      if (numbAfterDot > params.decimals) {
        this.notifications.notify("Too many numbers after dot", {
          type: "error",
          title: `Token decimals maximum is ${params.decimals} for amount ${_}`,
          duration: 3
        });
        isChecked = false;
      }
    });

    if (!isChecked) return false;

    try {
      console.log(drop3RepoContract, "DEPLOYPRICE");
      const deployPrice = await drop3RepoContract.methods
        .getDeployAirdropValue({ length: recipients.length })
        .call();

      const totalAmount = params.airdrop_amounts
        .map((_) => new BN(_).times(decimalsZeros(params.decimals)).toFixed())
        .reduce((prev, cur) => {
          return new BN(prev).plus(new BN(cur));
        }, BN.ZERO);

      console.log(
        {
          name: params.name,
          refund_destination: this.wallet.account.address,
          refund_lock_duration: 120,
          token_root_address: new Address(params.token_address),
          recipients,
          amounts: params.airdrop_amounts.map((_) =>
            new BN(_).times(decimalsZeros(params.decimals)).toFixed()
          )
        },
        "__PARAMS"
      );

      const payload = await drop3RepoContract.methods
        .getDeployAirdropPayload({
          name: params.name,
          refund_destination: this.wallet.account.address,
          refund_lock_duration: 120,
          token_root_address: new Address(params.token_address),
          recipients,
          amounts: params.airdrop_amounts.map((_) =>
            new BN(_).times(decimalsZeros(params.decimals)).toFixed()
          )
        })
        .call();

      let resp = null;

      if (!params.isNative) {
        resp = await this.tokenStore.transferTokensToContract({
          contract: CONTRACTS_REPO_ADDRESS,
          token: new Address(params.token_address),
          amount: totalAmount.toFixed(),
          payload: payload.value0,
          deployWalletValue: 0.5,
          coins: BN.formatUnits(deployPrice.value, 9).toNumber()
        });
      } else {
        const deployPriceWithAmount = new BN(deployPrice.value)
          .plus(totalAmount)
          .toNumber();
        resp = await drop3RepoContract.methods
          .wEverOccur({
            amount: totalAmount.toNumber(),
            payload: payload.value0
          })
          .send({
            from: this.wallet.account.address,
            amount: toCoin(BN.formatUnits(deployPriceWithAmount, 9).toNumber()),
            bounce: true
          });
      }

      if (resp) {
        this.notifications.notify("Now you can check all airdrops page", {
          type: "success",
          title: "New airdrop created!",
          duration: 5
        });
        return true;
      } else return false;
    } catch (err: any) {
      console.log(err, "__err");
      this.notifications.notify(err?.message ?? "Smth wrong", {
        type: "error",
        title: this.intl.formatMessage({
          id: "notify.error",
          defaultMessage: "Error"
        }),
        duration: 5
      });
      return false;
    }
  }
}
