import { useEffect } from "react";
import { LanguageStore } from "../stores/LanguageStore";
import { useLocalObservable } from "mobx-react-lite";
import { IntlProvider } from "react-intl";

export const LanguageStoreProvider = ({ children }: any) => {
  const languageStore = useLocalObservable(() => new LanguageStore());
  useEffect(() => {
    const init = async () => {
      languageStore.init();
    };

    init();
  }, [languageStore]);

  return (
    <IntlProvider
      defaultLocale={languageStore.language.id}
      locale={languageStore.language.id}
      messages={languageStore.messages}
    >
      {children}
    </IntlProvider>
  );
};
