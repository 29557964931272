import styles from "./index.module.css";
import Select, {
  components,
  GroupBase,
  OptionProps,
  SingleValueProps,
  StylesConfig
} from "react-select";
import { useMemo } from "react";

const SingleSelectOptionComponent = (props: OptionProps<SingleSelectValue>) => {
  return (
    <components.Option {...props}>
      <div className={styles.option}>
        {props.data.icon && (
          <img className={styles.option_icon} src={props.data.icon} alt="" />
        )}
        <span className={styles.option_label}>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

const SingleSelectValueComponent = (
  props: SingleValueProps<SingleSelectValue>
) => {
  return (
    <components.SingleValue {...props}>
      <div className={styles.value}>
        {props.data.icon && (
          <img className={styles.value_icon} src={props.data.icon} alt="" />
        )}
        <span className={styles.single_value_label}>{props.data.label}</span>
      </div>
    </components.SingleValue>
  );
};

export type SingleSelectValue = {
  label: string;
  value: string;
  icon?: string;
};

type SingleSelectProps = {
  className?: string;
  type?: "large" | "medium";
  placeholderText?: string;
  noOptionsText?: string;
  options?: SingleSelectValue[];
  value?: SingleSelectValue | null;
  isSearchable?: boolean;
  onChange: (newVal: SingleSelectValue | null) => void;
};

const SingleSelect = (props: SingleSelectProps) => {
  const styles: StylesConfig<
    SingleSelectValue,
    false,
    GroupBase<SingleSelectValue>
  > = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: props.type === "large" ? "22px" : "16px",
        fontWeight: props.type === "large" ? 600 : 400,
        fontFamily:
          props.type === "large" ? "Poppins, serif" : "Open sans, sans-serif",
        textTransform: props.type === "large" ? "uppercase" : "none",
        background: "var(--color-select-background)",
        color: "var(--color-select-text)",
        border:
          props.type === "large"
            ? "1px solid transparent"
            : "1px solid rgb(var(--white), .15)",
        borderRadius: "12px",
        boxShadow: "none",
        height: "38px",
        cursor: "pointer",
        "&:hover": {
          border: "1px solid rgb(var(--green-100), 1)",
          boxShadow: "4px 4px 0px rgba(var(--green-100), 0.4)"
        }
      }),
      input: (provided) => ({
        ...provided,
        color: "var(--color-select-text)",
        overflow: "hidden"
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "var(--color-select-text-placeholder)",
        paddingLeft: "8px"
      }),
      menu: (provided) => ({
        ...provided,
        background: "var(--color-select-background)",
        color: "var(--color-select-text)",
        border: "1px solid var(--color-select-menu-border)",
        borderRadius: "8px",
        boxShadow: "var(--box-shadow-primary)",
        zIndex: "100"
      }),
      menuList: (provided) => ({
        ...provided,
        "::-webkit-scrollbar": {
          width: "var(--scrollbar-width)"
        },
        "::-webkit-scrollbar-thumb": {
          background: "var(--scrollbar-color)"
        },
        scrollbarWidth: "thin"
      }),
      option: () => ({}),
      IndicatorSeparator: () => null,
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "var(--color-select-text-placeholder)",
        cursor: "pointer",
        "&:hover": { color: "var(--white)" }
      })
    }),
    [props.type]
  );

  return (
    <Select
      className={`w-full ${props.className ?? ""}`}
      styles={styles}
      placeholder={props.placeholderText}
      noOptionsMessage={
        props.noOptionsText ? () => props.noOptionsText : undefined
      }
      options={props.options}
      value={props.value ?? null}
      onChange={(newVal) => {
        if (newVal === undefined) props.onChange?.(null);
        props.onChange?.(newVal);
      }}
      components={{
        Option: SingleSelectOptionComponent,
        SingleValue: SingleSelectValueComponent,
        IndicatorSeparator: () => null
      }}
      // menuIsOpen
      isSearchable={props.isSearchable ?? false}
      isClearable={false}
    />
  );
};

export default SingleSelect;
