import { Fragment } from "react";
import { Link } from "react-router-dom";
import { IoChevronForward } from "react-icons/io5";
import classNames from "classnames";

type TLink = {
  link: string;
  name: string;
  active: boolean;
};

type Props = {
  className?: string;
  list: TLink[];
};

const BreadCrumbs = ({ className, list }: Props) => {
  return (
    <div className={classNames("breadcrumbs", className)}>
      {list.map((item, index) => (
        <Fragment key={index}>
          <Link
            className={classNames(
              "breadcrumbs__link",
              item.active ? "breadcrumbs__link--active" : null
            )}
            to={item.link}
          >
            {item.name}
          </Link>
          {index !== list.length - 1 ? (
            <IoChevronForward className="breadcrumbs___icon" />
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

export default BreadCrumbs;
