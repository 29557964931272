import "./css/base.css";
import "./css/main.css";

import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import Venomize from "./img/venomize.svg";
import PicMin from "./img/pic-min.png";
import PicMinMob from "./img/pic-mob-min.png";
import GreenArrow from "./img/green-arrow.svg";
import Footer from "@/components/layout/footer";

import Icn1 from "./img/icn-1.svg";
import Icn2 from "./img/icn-2.svg";
import Icn3 from "./img/icn-3.svg";
import Icn4 from "./img/icn-4.svg";

const LandingPage = () => {
  const intl = useIntl();

  return (
    <div className="wrapper">
      <header className="header">
        <div className="header__logo">
          <img src={Venomize} alt="Venomize" />
        </div>
        <nav className="header-nav">
          <ul>
            <li>
              <Link to="/tokens/create">
                {intl.formatMessage({
                  id: "land.create",
                  defaultMessage: "Create"
                })}
              </Link>
            </li>
            <li>
              <Link to="/tokens">
                {intl.formatMessage({
                  id: "land.manage",
                  defaultMessage: "Manage"
                })}
              </Link>
            </li>
            <li>
              <Link to="/vesting">
                {intl.formatMessage({
                  id: "land.vesting",
                  defaultMessage: "Vesting"
                })}
              </Link>
            </li>
            <li>
              <Link to="/airdrop">
                {intl.formatMessage({
                  id: "land.airdrop",
                  defaultMessage: "Airdrop"
                })}
              </Link>
            </li>
            <li>
              <Link to="/marketing">
                {intl.formatMessage({
                  id: "land.marketing",
                  defaultMessage: "Marketing"
                })}
              </Link>
            </li>
            <li>
              <a
                href="https://venomize.gitbook.io/"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({
                  id: "land.faq",
                  defaultMessage: "faq"
                })}
              </a>
            </li>
          </ul>
        </nav>
        <a href="/tokens" className="btn btn--border header__app">
          {intl.formatMessage({
            id: "land.go",
            defaultMessage: "Go to app"
          })}
        </a>
      </header>
      <section className="hero">
        <div className="container">
          <div className="hero__wrap">
            <h1 className="hero__title">
              {intl.formatMessage({
                id: "land.equip",
                defaultMessage:
                  "Equip your business with a range of tools to enter the Web3 era"
              })}
            </h1>
            <p className="hero__txt">
              {intl.formatMessage({
                id: "land.createVest",
                defaultMessage:
                  "Create, Vest, Airdrop, Manage and Advertise tokens on Venom blockchain with smart contracts already set up for you"
              })}
            </p>
            <div className="bar">
              <Link className="btn btn--primery" to="/tokens">
                {intl.formatMessage({
                  id: "land.start",
                  defaultMessage: "Start building"
                })}
              </Link>
              <a className="btn btn--secondary" href="#why-us">
                {intl.formatMessage({
                  id: "land.why",
                  defaultMessage: "Why us?"
                })}
              </a>
              <a
                className="btn btn--secondary"
                href="https://venomize.gitbook.io/"
                target="_blank"
                rel="noreferrer"
              >
                {intl.formatMessage({
                  id: "land.manual",
                  defaultMessage: "Manual"
                })}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sec relative" id="why-us">
        <a
          href="https://venom.foundation/"
          target="_blank"
          className="absolute w-full text-green-100 text-center text-semibold left-[50%] translate-x-[-50%] -top-4 text-2xl hover:underline"
          rel="noreferrer"
        >
          {intl.formatMessage({
            id: "land.powered",
            defaultMessage: "Made on Venom blockchain"
          })}
        </a>
        <div className="container">
          <h2 className="sec-title">
            Tokenize your project seamlessly with a comprehensive ecosystem
            approach. All in one dApp.
          </h2>
          <div className="main-sec__wrap">
            <div className="main-sec__pic">
              <img src={PicMin} alt="" />
              <img src={PicMinMob} alt="" />
            </div>
            <div className="main-sec__content">
              <div className="main-sec-item">
                <div className="main-sec-item__header">
                  <a href="/tokens/create" className="main-sec-item__link">
                    <span>Create a token</span>
                    <img src={GreenArrow} alt="" />
                  </a>
                </div>
                <ul>
                  <li>Set up in just a few clicks</li>
                  <li>Intuitive interface makes token creation a breeze</li>
                  <li>Customisable tokens with optional features</li>
                </ul>
              </div>
              <div className="main-sec-item">
                <div className="main-sec-item__header">
                  <a href="/vesting" className="main-sec-item__link">
                    <span>Vest</span>
                    <img src={GreenArrow} alt="" />
                  </a>
                </div>
                <ul>
                  <li>
                    Configure a cliff and vesting period for each unique
                    recipient
                  </li>
                  <li>
                    Access colourful dashboard and vesting history to track data
                  </li>
                  <li>
                    Enable investors to claim their vested tokens and track
                    performance statistics on a dedicated page
                  </li>
                </ul>
              </div>
              <div className="main-sec-item">
                <div className="main-sec-item__header">
                  <a href="/airdrop" className="main-sec-item__link">
                    <span>Airdrop</span>
                    <img src={GreenArrow} alt="" />
                  </a>
                </div>
                <ul>
                  <li>
                    Airdrop any token right here, right now in just a few
                    minutes
                  </li>
                  <li>
                    Upload a list of addresses to distribute tokens to a large
                    group of recipients
                  </li>
                  <li>Send all the tokens at once</li>
                </ul>
              </div>
              <div className="main-sec-item">
                <div className="main-sec-item__header">
                  <a href="/marketing" className="main-sec-item__link">
                    <span>Market</span>
                    <img src={GreenArrow} alt="" />
                  </a>
                </div>
                <ul>
                  <li>
                    Submit a request for a marketing campaign from our team of
                    experienced crypto marketers
                  </li>
                  <li>
                    We offer a variety of advertising campaigns that are ready
                    to be executed
                  </li>
                  <li>
                    Share your information with us and receive a prompt response
                  </li>
                </ul>
              </div>
              <div className="main-sec-item">
                <div className="main-sec-item__header">
                  <a href="/tokens" className="main-sec-item__link">
                    <span>Manage</span>
                    <img src={GreenArrow} alt="" />
                  </a>
                </div>
                <ul>
                  <li>
                    All the important data is gathered and displayed in one
                    place
                  </li>
                  <li>
                    Access the ability to mint tokens, burn tokens, or transfer
                    token ownership from the management screen
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="advantages">
        <div className="container">
          <h2 className="sec-title">Advantages</h2>
          <div className="advantages__row">
            <div className="advantage">
              <div className="advantage__icn">
                <img src={Icn1} alt="" />
              </div>
              <h3 className="advantage__title">Time & Cost saving</h3>
              <p className="advantage__txt">
                Enhance your business with Web3 capabilities through our
                pre-built smart contracts and efficient Venom blockchain
                infrastructure
              </p>
            </div>
            <div className="advantage">
              <div className="advantage__icn">
                <img src={Icn2} alt="" />
              </div>
              <h3 className="advantage__title">Easy for non-devs</h3>
              <p className="advantage__txt">
                Our intuitive interface and helpful tips will guide you through
                your entire journey.
              </p>
            </div>
            <div className="advantage">
              <div className="advantage__icn">
                <img src={Icn3} alt="" />
              </div>
              <h3 className="advantage__title">Secure</h3>
              <p className="advantage__txt">
                With the power of smart contracts, our Venomize app ensures that
                all actions and data are transparent and performed consistently.
              </p>
            </div>
            <div className="advantage">
              <div className="advantage__icn">
                <img src={Icn4} alt="" />
              </div>
              <h3 className="advantage__title">Multifunctional</h3>
              <p className="advantage__txt">
                Our family of subproducts allows you to perform all necessary
                token operations in a single, centralized location
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="action">
        <div className="container">
          <div className="action__wrap">
            <h2 className="sec-title">
              Don't pass up this opportunity to be on the cutting edge of
              technology
            </h2>
            <div className="bar">
              <Link className="btn btn--primery" to="/tokens">
                Start building
              </Link>
              <a
                target="_blank"
                href="https://venomize.gitbook.io/"
                className="btn btn--secondary"
                rel="noreferrer"
              >
                Manual
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LandingPage;
