import styles from "./index.module.css";
import { ChangeEvent } from "react";
import { BsCheck } from "react-icons/bs";
import classNames from "classnames";

type Props = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (val: boolean) => void;
  className?: string;
};
const Checkbox = ({ checked, disabled, className, onChange }: Props) => {
  const toggleChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    onChange?.(isChecked);
  };

  return (
    <label className={classNames("block", disabled ? styles.disabled : null)}>
      <input
        className={classNames(styles.input, className)}
        type="checkbox"
        checked={checked}
        onChange={toggleChecked}
      />
      <div className={styles.checkbox}>
        <BsCheck className="shrink-0 text-2xl" />
      </div>
    </label>
  );
};

export default Checkbox;
