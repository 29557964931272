import BigNumber from "bignumber.js";
import { Address } from "everscale-inpage-provider";
import { TokenModel, EchainId } from "@/models/TokenModel";

export const everscaleAddressPattern = /^0:+[\dA-Za-z]{64}$/;
export const REPOSITORY_REFETCH_INTERVAL = 5;

export const NETWORK_ID = 42;
export const NETWORK_NAME = "Everscale Mainnet";
export const NETWORK_ID_VENOM = 1;
export const NETWORK_NAME_VENOM = "Venom Mainnet";
export const COIN_SYMBOL = "EVER";
export const COIN_DECIMALS = 9;

export const DEPLOY_WALLET_VALUE = toCoin(0.2);
export const ACCEPT_SELL_AMOUNT = toCoin(5);
export const FACTORY_AMOUNT = toCoin(5);
export const CHANGE_MANAGER_VALUE = toCoin(5);
export const CHANGE_MANAGER_AMOUNT = toCoin(6);
export const CLOSE_SELL_COIN_AMOUNT = toCoin(2);
export const CLOSE_OFFER_COIN_AMOUNT = toCoin(2);

export function toCoin(amount: number) {
  return new BigNumber(amount).shiftedBy(COIN_DECIMALS).toFixed();
}

export const zeroAddress =
  "0:0000000000000000000000000000000000000000000000000000000000000000";

export const IS_VENOM =
  process.env.REACT_APP_VENOM_ACTIVE === "1" ? true : false;

export const NATIVE_SYMBOL =
  process.env.REACT_APP_VENOM_ACTIVE === "1" ? "VENOM" : "EVER";

export const WRAPPED_NATIVE =
  process.env.REACT_APP_VENOM_ACTIVE === "1"
    ? "0:2c3a2ff6443af741ce653ae4ef2c85c2d52a9df84944bbe14d702c3131da3f14"
    : "0:a49cd4e158a9a15555e624759e2e4e766d22600b7800d891e46f9291f044a93d";

export const TOKEN_FACTORY_ADDRESS =
  process.env.REACT_APP_VENOM_ACTIVE === "1"
    ? new Address(process.env.REACT_APP_VENOM_TOKEN_FACTORY as string)
    : new Address(process.env.REACT_APP_TOKEN_FACTORY as string);

export const CONTRACTS_REPO_ADDRESS =
  process.env.REACT_APP_VENOM_ACTIVE === "1"
    ? new Address(process.env.REACT_APP_VENOM_CONTRACTS_FACTORY as string)
    : new Address(process.env.REACT_APP_EVER_CONTRACTS_FACTORY as string);

export const WEVER_ADDRESS =
  process.env.REACT_APP_VENOM_ACTIVE === "1"
    ? process.env.REACT_APP_CONTRACTS_VENOM_WEVER
    : process.env.REACT_APP_CONTRACTS_EVER_WEVER;

export const WEVER_VAULT =
  process.env.REACT_APP_VENOM_ACTIVE === "1"
    ? process.env.REACT_APP_CONTRACTS_VENOM_VAULT
    : process.env.REACT_APP_CONTRACTS_EVER_VAULT;

export const VENOM_MODEL: TokenModel = {
  name: NATIVE_SYMBOL,
  symbol: NATIVE_SYMBOL,
  chainId: EchainId.first,
  decimals: 9,
  address: WRAPPED_NATIVE,
  logoURI: "",
  verified: true,
  supply: "0"
};

export enum WalletId {
  Ever = "everwallet",
  Venom = "venomwallet"
}

export enum NetworkId {
  EverMainNet = "42",
  VenomTestNet = "1000"
}

export const VENOM_SCAN = "https://venomscan.com";
